export const loginFormValidation = {
    email: {
        required: { value: true, message: "Email field is empty" },
        pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Please Enter Valid Email" }
    },
    confirmPassword: {
        required: { value: true, message: "Confirm password is empty" },
        pattern: { value: /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,16})$/, message: "Please Enter valid Password" }
    },
    name: {
        required: { value: true, message: "Name can't be Empty" },
    },
    password: {
        required: { value: true, message: "Password field is empty" },
        minLength: { value: 8, message: "Password must be at least 8 characters long" },
        pattern: { value: /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16})$/, message: "Password must contain atleast one uppercase, lowercase and numbers" }
    }
};