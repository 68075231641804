import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { useForm } from "react-hook-form";
import { IWarehouseAddress } from "../../../services/interfaces/warehouse.interface";
import LayoutHeader from "components/LayoutHeader";
import { Istate } from "common/constant";
import InputLabelinputWarehouse from "components/InputLabelWithDownInput";
import InputLabelDownWithDropDown from "components/InputLabelWithDownDropdown";
import ActionButton from "components/ActionButton";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { PIMService } from "services/pimapi.service";
import { AddressValidation } from "./validator";
import { pincodeAPIInterface } from "../../../services/interfaces/warehouse.interface";
import "./styles.scss";

type WarehouseFormprops = {
    title?: string
    open: boolean
    handleClose: () => void;
    cancelClose: () => void;
    onSelected: (data: IWarehouseAddress) => void;
    editData?: IWarehouseAddress | null
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export const AddressForm: React.FC<WarehouseFormprops> = ({ open, cancelClose, onSelected: onLocationSelected, editData }) => {

    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm<IWarehouseAddress>({ mode: "onChange" });
    const pimApi = new PIMService();
    const [pinCode, setPinCode] = useState<string>(editData ? editData.pincode : "");
    const [cityList, setCityList] = useState<any>();
    const [areaList, setAreaList] = useState<any>();

    const onSubmit = async (data: IWarehouseAddress) => {
        onLocationSelected(data);
        cancelClose();
    };


    const handleOnNumber = () => {
        const pinCodeSearch = watch("pincode");
        setPinCode(pinCodeSearch);
    };

    const handleOnPinCode = async (pincode: string) => {
        try {
            if (pincode.length === 6) {
                const getPincodeDetails = await pimApi.getPinCodeDetails(pincode);

                if (getPincodeDetails) {
                    const cityLists = getPincodeDetails.data.map((val: pincodeAPIInterface) => val.district);
                    const filteredState = cityLists.filter((item: any,
                        index: any) => cityLists.indexOf(item) === index);
                    const areaLists = getPincodeDetails.data.map((val: pincodeAPIInterface) => val.area);
                    const filteredArea = areaLists.filter((item: any,
                        index: any) => areaLists.indexOf(item) === index);
                    setAreaList(filteredArea);
                    setCityList(filteredState);

                    if (filteredArea?.length) {
                        setValue("state", getPincodeDetails?.data[0]?.state);
                        setValue("address3", editData?.address3 as string);
                        setValue("city", editData?.city as string);
                    }
                }
            }
            else {
                setValue("city", "");
                setValue("address3", "");
                setAreaList([]);
                setCityList([]);
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        handleOnPinCode(pinCode);
    }, [pinCode]);


    return (
        <Dialog open={open} TransitionComponent={Transition} className="addware-popup">
            <LayoutHeader title="Communication Address" />
            <form className="AddressEdit-form" onSubmit={handleSubmit(onSubmit)}>
                <InputLabelinputWarehouse
                    label="Pincode *"
                    name="pincode"
                    type="number"
                    register={register("pincode", AddressValidation.pincode)}
                    error={errors.pincode?.type !== undefined}
                    errorText={(errors.pincode ? errors.pincode.message : "") || "fdsfsdfs"}
                    onChange={(event) => event.target.value = event.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")}
                    onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                    onKeyUp={() => handleOnNumber()}
                    defaultValue={editData?.pincode ? editData?.pincode : ""}
                />
                <InputLabelinputWarehouse
                    label="Address 1*"
                    name="address1"
                    maxLength={60}
                    register={register("address1", AddressValidation.address1)}
                    error={errors.address1?.type !== undefined}
                    errorText={errors.address1 ? errors.address1.message : ""}
                    defaultValue={editData?.address1 ? editData?.address1 : ""}
                />
                <InputLabelinputWarehouse
                    label="Address 2"
                    name="address2"
                    maxLength={60}
                    register={register("address2")}
                    defaultValue={editData?.address2 ? editData.address2 : ""}
                />
                <InputLabelDownWithDropDown
                    text="Area"
                    dropdown={areaList ? areaList : []}
                    name="address3"
                    register={register("address3", AddressValidation.address3)}
                    error={errors.address3?.type !== undefined}
                    errorText={errors.address3 ? errors.address3.message : ""}
                    defaultValue={editData?.address3 ? editData?.address3 : ""}
                />
                <InputLabelDownWithDropDown
                    text="City"
                    dropdown={cityList ? cityList : []}
                    name="city"
                    register={register("city", AddressValidation.city)}
                    error={errors.city?.type !== undefined}
                    errorText={errors.city ? errors.city.message : ""}
                    defaultValue={editData?.city ? editData?.city : ""}
                />
                <InputLabelDownWithDropDown
                    text="State"
                    dropdown={Istate}
                    name="state"
                    register={register("state", AddressValidation.state)}
                    error={errors.state?.type !== undefined}
                    errorText={errors.state ? errors.state.message : ""}
                    defaultValue={editData?.state ? editData.state : ""}
                />
                <InputLabelinputWarehouse
                    label="Country *"
                    name="country"
                    register={register("country", AddressValidation.country)}
                    error={errors.country?.type !== undefined}
                    errorText={errors.country ? errors.country.message : ""}
                    defaultValue={editData?.country ? editData?.country : "India"}
                />
                <div className="address-form-btn-wrapper">
                    <ActionButton label="Cancel" type="button" onClick={cancelClose} color="outlined" />
                    <ActionButton label="Submit" type="submit" />
                </div>
            </form>
        </Dialog>
    );
};