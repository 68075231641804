import React from "react";
import { useForm } from "react-hook-form";
import UserIcon from "assets/icons/user.png";
import LockIcon from "assets/icons/lock.png";
import InputFieldWithoutLabel from "components/InputFieldWithoutLabel";
import ActionButton from "components/ActionButton";
import { useNavigate } from "react-router-dom";
import { AuthService } from "services/auth.service";
import { setAuthData } from "redux/Actions/actions";
import { useDispatch } from "react-redux";
import "./styles.scss";

type formFields = {
    email: string
    password: string
}

const loginFormValidation = {
    email: {
        required: { value: true, message: "Email field is empty" },
        pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Please Enter Valid Email" }
    },
    password: {
        required: { value: true, message: "Password field is empty" },
        minLength: { value: 6, message: "Password must be at least 6 characters long" }
    }
};

type Tprops = {
    ssoinitialized: boolean,
    keycloaklogin: () => void;
}

const LoginForm: React.FC<Tprops> = ({ ssoinitialized, keycloaklogin }) => {

    const { register, handleSubmit, formState: { errors } } = useForm<formFields>({ mode: "onChange" });
    const navigate = useNavigate();
    const Authentication = new AuthService();
    const dispatch = useDispatch();

    const onSubmit = async (data: formFields) => {
        const LoginData: formFields = {
            email: data.email,
            password: data.password
        };
        const loginResponse = await Authentication.Login(LoginData);

        if (loginResponse) {
            dispatch(setAuthData(loginResponse.data));
            console.log("loginResponse", loginResponse.data.token);
            localStorage.setItem("email", loginResponse.data.email);
            localStorage.setItem("accessToken", loginResponse.data.token);
            navigate("/central-inventory/inventory");
        }
    };

    return (
        <div className="login-form">
            <p className="sign-in-content">Sign in</p>
            <p className="access-acc-content">To access your account</p>
            <form className="login-form-wrap" onSubmit={handleSubmit(onSubmit)} >
                <div className="form-body">
                    <InputFieldWithoutLabel
                        register={register("email", loginFormValidation.email)}
                        text="Email"
                        error={errors.email?.type !== undefined}
                        errorText={errors.email ? errors.email.message : ""}
                        icon={UserIcon}
                    />
                    <InputFieldWithoutLabel
                        register={register("password", loginFormValidation.password)}
                        error={errors.password?.type !== undefined}
                        errorText={errors.password ? errors.password.message : ""}
                        text="Password"
                        type="password"
                        icon={LockIcon}
                    />
                </div>
                <p className="conditions-content">Upon login, I agree to Poorvika <span className="blue-content">Privacy Policy & Terms.</span> </p>
                <div className="form-btn-wrap">
                    <ActionButton type="submit" label="LOGIN" />
                    <p className="forgot-password" onClick={() => navigate("/forgot-password")}>Forgot password?</p>
                </div>
            </form>

            {/* <p><span className="blue-content" onClick={() => navigate('/signup')}>Register</span> for new account</p> */}
            {/* <p><span className="blue-content">Click here</span> to Generate the new activation link</p> */}
            {ssoinitialized && <div className="soo-login-btn">
                <ActionButton type="button" label="SSO Login" onClick={() => keycloaklogin()} />
            </div>}
        </div>
    );
};

export default LoginForm;