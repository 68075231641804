import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { TabHeader } from "layouts/TabHeader";
import { ProductInventory } from "./productByInventory";
import "./styles.scss";

export const ProductSerialPage: React.FC = () => {
    const profile = ["warehouse", "drop_ship", "shop", "seller"];

    return (
        <div className='product-number-list-page'>
            <TabHeader profile={profile}/>
            <div>
                <Routes>
                    <Route path="/" element={<Navigate replace to="warehouse" />} />
                    <Route path='/:profile/*' element={<ProductInventory />} />
                </Routes>
            </div>
        </div>
    );
};