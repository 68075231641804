import ActionButton from "components/ActionButton";
import InputFieldWithoutLabel from "components/InputFieldWithoutLabel";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthService } from "services/auth.service";
import { loginFormValidation } from "./validators";
import "./styles.scss";
import AlertBox from "components/AlertBox";
import { Loader } from "components/Loader";

type formFields = {
    email: string
    password: string
    name: string,
    code: string
    confirmPassword: string
}

const RegisterForm: React.FC = () => {

    const { register, handleSubmit, formState: { errors }, watch } = useForm<formFields>({ mode: "onChange" });
    const navigate = useNavigate();
    const Authentication = new AuthService();
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [alertMsg, setAlertMsg] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("");

    const handleOnAlert = (open: boolean, message: string, statusMsg: string) => {
        setOpenAlert(open);
        setAlertMsg(message);
        setStatus(statusMsg);
    };

    const onSubmit = async (data: formFields) => {
        setLoader(true);
        const signUp: any = {
            email: data.email,
            password: data.password,
            name: data.name,
            code: "user" + "-" + data.name + "-" + Math.random().toString(36).substring(2, 7),
            confirmPassword: data.confirmPassword
        };
        const registerUser: any = await Authentication.Signup(signUp);
        console.log("registerUser", registerUser);
        
        if (registerUser.status === "error") {
            handleOnAlert(true, registerUser.message, registerUser.status);
            setLoader(false);
        }
        else {
            setLoader(false);
            handleOnAlert(true, registerUser.message, registerUser.status);
        }
    };

    return (
        <div className="register-form-wrap">
            <p className="sign-in-content">Sign Up</p>
            <p className="access-acc-content">Create your account here</p>
            <form className="login-form-wrap" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-body">
                    <InputFieldWithoutLabel
                        register={register("name", loginFormValidation.name)}
                        text="Name"
                        error={errors.name?.type !== undefined}
                        errorText={errors.name ? errors.name.message : ""}
                    // icon={UserIcon}
                    />
                    <InputFieldWithoutLabel
                        register={register("email", loginFormValidation.email)}
                        text="Email"
                        error={errors.email?.type !== undefined}
                        errorText={errors.email ? errors.email.message : ""}
                    // icon={UserIcon}
                    />
                    <InputFieldWithoutLabel
                        register={register("password", loginFormValidation.password)}
                        error={errors.password?.type !== undefined}
                        errorText={errors.password ? errors.password.message : ""}
                        text="Password"
                        type="password"
                    // icon={LockIcon}
                    />
                    <InputFieldWithoutLabel
                        register={register("confirmPassword", {
                            required: { value: true, message: "Confirm Password field is empty" },
                            validate: (v) => v === (watch("password")) ||
                                "Must match with password"
                        })}
                        error={errors.confirmPassword?.type !== undefined}
                        errorText={errors.confirmPassword ? errors.confirmPassword.message : ""}
                        text="Confirm Password"
                        type="password"
                    // icon={LockIcon}
                    />
                </div>
                <p className="conditions-content">Upon login, I agree to Poorvika <span className="blue-content">Privacy Policy & Terms.</span> </p>
                <div className="form-btn-wrap">
                    <ActionButton type="submit" label="Sign Up" />
                </div>
            </form>
            {openAlert && <AlertBox severity={status} open={openAlert} message={alertMsg} btnName="OK" onClick={status === "success" ? () => navigate("/login") : () => setOpenAlert(false)} title={status === "success" ? "Success" : "Error"} />}
            {loader && <Loader open={loader} />}
            <p>Already have a account ? then<span className="blue-content" onClick={() => navigate("/login")}> Login</span></p>
        </div>
    );
};

export default RegisterForm;