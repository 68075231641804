import { referenceData } from "common/constant";
import { Routes, Route, Navigate, NavLink } from "react-router-dom";
import { ReferenceChannel } from "./ReferenceChannel";
import "./styles.scss";



export const ReferenceDataPage = () => {


    return (
        <div className="Reference-management-page">
            <div className="Reference-table-layout">
                <div className="Reference-table-header">
                    {referenceData.map((profile_name) => (
                        <div className="listed-products-selector" key={profile_name}>
                            <NavLink key={profile_name} className="inventorys-selector" to={profile_name}>
                                <div className="header-btn">
                                <div className="header-label">{profile_name.replaceAll("_", "")}</div>
                                </div>
                            </NavLink>
                        </div>
                    ))}
                </div>
                <div className="pd-container">
                    <Routes>
                        <Route path="/" element={<Navigate replace to="/central-inventory/admin/reference/Bajaj/Seller_ID" />} />
                        <Route element={<ReferenceChannel />} path="/:referenceData/*" />
                    </Routes>
                </div>
            </div>
        </div>
    );
};