
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
//import StorefrontIcon from "@mui/icons-material/Storefront";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import "./styles.scss";

type Tprops = {
    onClick: (profile: string, name: string) => void,
    productName: string,
    imgUrl: string
    drop_ship: number,
    warehouse: number,
    seller: number,
    shop: number,
}

export const SearchProductCard: React.FC<Tprops> = ({ imgUrl, productName, drop_ship, warehouse, seller, shop, onClick }) => {
    const total = drop_ship+warehouse+seller+shop;

    return (
        <div className='productDetails-cards'>

            <div className="image">
                <img src={imgUrl} alt={productName ? productName : "Image not found"} draggable="false" />
            </div>


            <p className="count-description  product-name-wrapper">{productName} <strong>Stocks: {total}</strong></p>

            <div className="count-wrapper">

                <div className={`count-card brown ${warehouse ? "" : "not-clickable"}`} onClick={() => { warehouse && onClick("warehouse", productName); }}>
                    <div className="count-icon">
                        <div className="circle-layer">
                            <PhoneIphoneIcon className="dashboard-card-icon" />
                        </div>
                    </div>
                    <div className="count-area">
                        <p className="count-description">warehouse</p>
                        <h1 className="count">{warehouse}</h1>
                    </div>
                </div>

                <div className={`count-card green ${drop_ship ? "" : "not-clickable"}`} onClick={() => { drop_ship && onClick("drop_ship", productName); }}>
                    <div className="count-icon">
                        <div className="circle-layer">
                            <LocalShippingIcon className="dashboard-card-icon" />
                        </div>
                    </div>
                    <div className="count-area">
                        <p className="count-description">Drop Ship</p>
                        <h1 className="count">{drop_ship}</h1>
                    </div>
                </div>

                {/* <div className={`count-card blue ${shop ? "" : "not-clickable"}`} onClick={() => { shop && onClick("shop", productName); }}>
                    <div className="count-icon">
                        <div className="circle-layer">
                            <StorefrontIcon className="dashboard-card-icon" />
                        </div>
                    </div>
                    <div className="count-area">
                        <p className="count-description">Shop</p>
                        <h1 className="count">{shop}</h1>
                    </div>
                </div > */}

                <div className={`count-card green ${seller ? "" : "not-clickable"}`} onClick={() => { seller && onClick("seller", productName); }}>
                    <div className="count-icon">
                        <div className="circle-layer">
                            <AssignmentIndIcon className="dashboard-card-icon" />
                        </div>
                    </div>
                    <div className="count-area">
                        <p className="count-description">Seller</p>
                        <h1 className="count">{seller}</h1>
                    </div>
                </div>

                <div className={`count-card blue ${shop ? "" : "not-clickable"}`} onClick={() => { shop && onClick("shop", productName); }}>
                    <div className="count-icon">
                        <div className="circle-layer">
                            <AddBusinessIcon className="dashboard-card-icon" />
                        </div>
                    </div>
                    <div className="count-area">
                        <p className="count-description">APX/Shop</p>
                        <h1 className="count">{shop}</h1>
                    </div>
                </div>

            </div>


        </div >

    );
};