import React, { useEffect } from "react";
import { Dialog, DialogTitle } from "@mui/material";
import ActionButton from "components/ActionButton";
import DropDown from "components/DropDown";
import InputFieldWithoutLabel from "components/InputFieldWithoutLabel";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { AuthService } from "services/auth.service";
import { showNotification, STATUS } from "common/constant";

type Props = {
    open: boolean
    setOpen: (value: boolean) => void
    fetchUsers: () => void
    formState: any
}
type formFields = {
    email: string
    name: string,
    role: string
}
export const AddSupplier: React.FC<Props> = ({ open, setOpen, fetchUsers, formState }) => {

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<formFields>({ mode: "onChange" });
    const authService = new AuthService();

    const onSubmit = async (data: formFields) => {
        try {
            const userData = {
                email: data.email,
                role: data.role,
                name: data.name
            };

            if (formState) {
                const addUser = await authService.updateSuppliersByAdmin(userData);

                if (addUser.status === "success") {
                    setOpen(false);
                    showNotification("success", addUser.message);
                    fetchUsers();
                }
            }
            else {
                const addUser = await authService.addSuppliersByAdmin(userData);

                if (addUser.status === "success") {
                    setOpen(false);
                    showNotification("success", addUser.message);
                    fetchUsers();
                }
            }
        }
        catch (error) {
            showNotification(STATUS.FAILURE, "Something went wrong");
        }
    };

    useEffect(() => {
        if (formState) {
            setValue("role", formState.role);
            setValue("email", formState.email);
        }
    }, []);

    const userFormValidation = {
        email: {
            required: { value: true, message: "Email field is empty" },
            pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Please Enter Valid Email" }
        },
        name: {
            required: { value: true, message: "Name can't be Empty" },
        },
        role: {
            required: { value: true, message: "Role can't be Empty" }
        }
    };
    console.log("formState", formState);

    return (
        <Dialog open={open} >
            <div className="adduser-form-wrap">
                <div className="header-wrap">
                    <DialogTitle className="title-content">{formState ? "Update Role" : "Add User"}</DialogTitle>
                    <CloseIcon onClick={() => setOpen(false)} style={{ cursor: "pointer" }} />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-body">
                        {!formState && <>
                            <InputFieldWithoutLabel
                                register={register("name", userFormValidation.name)}
                                text="Name"
                                error={errors.name?.type !== undefined}
                                errorText={errors.name ? errors.name.message : ""}
                            />
                            <InputFieldWithoutLabel
                                register={register("email", userFormValidation.email)}
                                text="Email"
                                error={errors.email?.type !== undefined}
                                errorText={errors.email ? errors.email.message : ""}
                            /></>}
                        <div className="roles-dropdown">
                            <DropDown
                                text="Role"
                                register={register("role", userFormValidation.role)}
                                dropdown={["admin", "seller"]}
                                error={errors.role?.type !== undefined}
                                errorText={errors.role ? errors.role.message : ""}
                            />
                        </div>
                    </div>
                    <div className="form-btn-wrap">
                        <ActionButton type="submit" label="Submit" />
                    </div>
                </form>
            </div>
        </Dialog>
    );
};