import { ProductCard } from "components/Card";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IProductList } from "services/interfaces/productserial.interface";
import { ProductSerialNumberService } from "services/productserial.service";
import { useDispatch } from "react-redux";
import "./styles.scss";
import { setProductSerialActions } from "redux/Actions/actions";
import { showNotification, STATUS } from "common/constant";



export const ProductList:React.FC = () => {
    const params: any = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [productList, setProductList] = useState<any>();
    const productSerialService = new ProductSerialNumberService();

    console.log("params", params);

    const getProductList = async () => {
        try {
            const productData: IProductList = {
                profile: params.profile,
                inv_loc_code: params.inv_loc_code
            };
            const product_details = await productSerialService.getProductsByInventory(productData);
            setProductList(product_details);
        }
        catch (error) {
            showNotification(STATUS.FAILURE, "Something went wrong");
            console.log(error);

        }
    };
    useEffect(() => {
        getProductList();
    }, [params]);


    const handleOnView = (prod_id:string,prod_name:string) =>{
        navigate(`${prod_id}`);
        dispatch(setProductSerialActions(prod_name));
    };


    return (
        <div>

            <div className="product_Card">
                {productList?.productList.map((product: any) =>
                    <ProductCard imgUrl={product?.img_url} productName={product?.product_name} onClick={() =>handleOnView(product?.erp_prod_id,product?.product_name)} />
                )}
            </div>
        </div>
    );
};