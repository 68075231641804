import { Pagination } from "components/Pagination";
import { showNotification, STATUS } from "common/constant";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BajajService } from "services/bajaj.service";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ActionButton from "components/ActionButton";
import { SortingIcons } from "components/SortingArrows";
import moment from "moment";
import BajajSellerIDDialogBox from "components/BajajSellerIDDialogBox";
import "./styles.scss";

type TProps = {
    search: string,
}

export const BajajSkuPage: React.FC<TProps> = ({ search }) => {

    const [page, setPageNo] = useState<number>(1);
    const [itemList, setItemList] = useState<Array<any>>([]);

    const [totalPage, setTotalPage] = useState<any>();
    const bajajService = new BajajService();
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const uri = useParams();
    const [bajajData, setBajajData] = useState<any>();
    const [sort, setSort] = useState<string>("ASC");
    const [sortBy, setSortBy] = useState<string>("sku");

    const getDetails = async () => {
        const response = await bajajService.getSkuDetails("Bajaj_Sku",(page === 1 ? 0 : ((page-1)*10)),search,sort,sortBy);

        if (response.status === STATUS.SUCCESS) {
            console.log(response?.data?.totalRecords);
            setTotalPage(Math.ceil(response?.data?.totalRecords[0].count / 10));
            setItemList(response?.data?.list?.map((e: any) => {
                e.mop = parseFloat(e.mop).toFixed(2);
                e.mrp = parseFloat(e.mrp).toFixed(2);

                return e;
            }
            ));
        }
    };

    const deletePIMSku = async (sku: string) => {
        const updatePimSku = await bajajService.deletePimSku({ sku_id: sku });

        if (updatePimSku.status === STATUS.SUCCESS) {
            showNotification(STATUS.SUCCESS, "Deleted Successfully");
            getDetails();
        }
        else {
            showNotification(STATUS.FAILURE, "Something went Wrong");
        }
    };

    const handleClose = () => {
        getDetails();
        setShowAdd(false);
    };

    useEffect(() => {
        getDetails();
        document.querySelector(".Sub-inventory-manaagement-table-body")?.scrollTo(0, 0);
    }, [search, page,sort,sortBy]);

    useEffect(() => {
        setItemList([]);
        getDetails();
        setPageNo(1);
    }, [uri]);

    return (
        <div className="bajaj-sku-table">
            <table className="bajaj-sku-manaagement-table">
                <thead className="bajaj-sku-manaagement-table-head">
                    <tr>

                        {/* <th className="bajaj-sku-table-header-sku" onClick={() => {
                            sortBy = "name";
                            SortObject(itemList, sortBy, sort);
                        }}>
                            <p className="bajaj-sku-table-header">
                                Name
                                <SortingIcons
                                    setSort={(e) => sort = e}
                                    fieldName="name"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th> */}
                        <th className="bajaj-sku-table-header-sku" onClick={() => {
                            setSortBy("sku");
                        }}>
                            <p className="bajaj-sku-table-header">
                                Sku
                                <SortingIcons
                                    setSort={(e) => setSort(e)}
                                    fieldName="Sku"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-sku-table-header-category" onClick={() => {
                            setSortBy("category");
                        }}>
                            <p className="bajaj-sku-table-header">
                                Category
                                <SortingIcons
                                    setSort={(e) =>setSort(e)}
                                    fieldName="Category"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-sku-table-header-mop" onClick={() => {
                            setSortBy("mop");
                        }}>
                            <p className="bajaj-sku-table-header">
                                Mop
                                <SortingIcons
                                    setSort={(e) => setSort(e)}
                                    fieldName="Mop"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-sku-table-header-mrp" onClick={() => {
                            setSortBy( "mrp");
                        }}>
                            <p className="bajaj-sku-table-header">
                                Mrp
                                <SortingIcons
                                    setSort={(e) => setSort(e)}
                                    fieldName="Mrp"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-sku-table-header-last-update" onClick={() => {
                            setSortBy("row_updated_dttm");
                        }}>
                            <p className="bajaj-sku-table-header">
                                Last Updated
                                <SortingIcons
                                    setSort={(e) => setSort(e)}
                                    fieldName="Last Updated"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-sku-table-header-action">
                            <p className="bajaj-sku-table-header">Action</p>
                        </th>
                    </tr>

                </thead>
                {itemList ?
                    <tbody className="Sub-inventory-manaagement-table-body">
                        {itemList.map((element: any, index: number) => {
                            return (
                                <tr key={index} className="inventory-manaagement-table-body-row" >
                                    {/* <td className="bajaj-sku-table-body-sku">
                                        <p className="table-data-item">
                                            {element["name"]}
                                        </p>
                                    </td> */}
                                    <td className="bajaj-sku-table-body-sku">
                                        <p className="table-data-item">
                                            {element["sku"]}
                                        </p>
                                    </td>
                                    <td className="bajaj-sku-table-body-category">
                                        <p className="table-data-item">
                                            {element["category"]}
                                        </p>
                                    </td>
                                    <td className="bajaj-sku-table-body-mop">
                                        <p className="table-data-item">
                                            {element["mop"]}
                                        </p>
                                    </td>
                                    <td className="bajaj-sku-table-body-mrp">
                                        <p className="table-data-item">
                                            {element["mrp"]}
                                        </p>
                                    </td>
                                    <td className="bajaj-sku-table-body-last-update">
                                        <p className="table-data-item">
                                            {moment(new Date(element["row_updated_dttm"])).format("DD-MMM-YYYY hh:mm A")}
                                        </p>
                                    </td>
                                    <td className="bajaj-sku-table-body-action">
                                        {element?.pim_sku ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                            <p>{element?.pim_sku.name}:{element?.pim_sku.erp_item_code}</p>
                                            <IconButton>
                                                <CancelIcon onClick={() => deletePIMSku(element.sku)} />
                                            </IconButton>
                                        </div> :
                                            <div className="action-btn">
                                                <ActionButton label="Update SKU" onClick={() => {
                                                    setBajajData(element);
                                                    setShowAdd(true);
                                                }} />
                                            </div>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                    : <tbody >
                        <tr>
                            <td className="empty-data" colSpan={12}>
                                No data to display
                            </td>
                        </tr>
                    </tbody>}

            </table>
            <div className="all-products-pagination">
                <Pagination
                    count={totalPage}
                    page={page}
                    pageChange={(e: any, page: number) => setPageNo(page)}
                />
            </div>
            {showAdd && <BajajSellerIDDialogBox slide="left" open={showAdd} onClose={handleClose} profile={uri["*"] || ""} title={uri["*"] || ""} element={bajajData} />}
        </div>
    );

};