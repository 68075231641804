export const InventoryFormValidation = {
    inv_loc_code: {
        required: { value: true, message: "Inventory Local code field is empty" },
    },
    erp_prod_id: {
        required: { value: true, message: "ERP Product ID field is empty" }
    },
    profile_stock_inhand: {
        required: { value: true, message: "Profile Stock In Hand field is empty", }
    },
    allowed_channels: {
        required: { value: true, message: "Alloed Channels field is empty" },
    },
    profile_stock_res: {
        required: { value: false, message: "Profile Stock Reserveration field is empty" },
    },
    channel_stock_inhand: {
        required: { value: true, message: "Channel Stock In-hand field is empty" },
    },
    channel_stock_res: {
        required: { value: false, message: "Channel Stock In-hand field is empty" }
    },
    product_name: {
        required: { value: true, message: "Product can't be empty" }
    },
    img_url: {
        required: { value: true }
    },
    product_details: {
        required: { value: true }
    }
};