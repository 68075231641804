import { Pagination } from "components/Pagination";
import { showNotification, STATUS } from "common/constant";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BajajService } from "services/bajaj.service";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ActionButton from "components/ActionButton";
import { SortingIcons } from "components/SortingArrows";
import BajajSellerIDDialogBox from "components/BajajSellerIDDialogBox";
import "./styles.scss";

type TProps = {
    search: string,
}

export const BajajSellerIDPage: React.FC<TProps> = ({ search }) => {

    const [page, setPageNo] = useState<number>(1);
    const [itemList, setItemList] = useState<Array<any>>([]);

    const [totalPage, setTotalPage] = useState<any>();
    const bajajService = new BajajService();
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const uri = useParams();
    const [bajajData, setBajajData] = useState<any>();
    const [sort, setSort] = useState<string>("ASC");
    const [sortBy, setSortBy] = useState<string>("city_id");

    const getDetails = async () => {
        const response = await bajajService.getSellerIdDetails("Bajaj_SellerID",(page === 1 ? 0 : ((page-1)*10)),search,sort,sortBy);

        if (response.status === STATUS.SUCCESS) {
            setTotalPage(Math.ceil(response?.data?.totalRecords[0].count / 10));
            setItemList(response?.data?.list);
        }
    };

    const deleteSellerID = async (data: any) => {
        const updatePimSku = await bajajService.deleteSellerID({ details: data });

        if (updatePimSku.status === STATUS.SUCCESS) {
            showNotification(STATUS.SUCCESS, "Deleted Successfully");
            getDetails();
        }
        else {
            showNotification(STATUS.FAILURE, "Something went Wrong");
        }
    };


    const handleClose = () => {
        getDetails();
        setShowAdd(false);
    };

    useEffect(() => {
        getDetails();
        document.querySelector(".Sub-inventory-manaagement-table-body")?.scrollTo(0, 0);
    }, [sort,sortBy, search, page]);

    useEffect(() => {
        setItemList([]);
        getDetails();
        setPageNo(1);
    }, [uri]);

    return (
        <div className="bajaj-seller-table">
            <table className="bajaj-seller-manaagement-table">
                <thead className="bajaj-seller-manaagement-table-head">
                    <tr>
                        <th className="bajaj-seller-table-header-city-id" onClick={() => { setSortBy( "city_id"); }}>
                            <p className="table-data-header">
                                City Id
                                <SortingIcons
                                    setSort={(e) => setSort(e)}
                                    fieldName="City Id"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-seller-table-header-supplier-id" onClick={() => { setSortBy( "supplier_id"); }}>
                            <p className="table-data-header">
                                Supplier Id
                                <SortingIcons
                                    setSort={(e) => setSort(e)}
                                    fieldName="Supplier Id"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-seller-table-header-supplier-desc" onClick={() => { setSortBy("supplier_desc"); }}>
                            <p className="table-data-header">
                                Supplier Name
                                <SortingIcons
                                    setSort={(e) => setSort(e)}
                                    fieldName="Supplier Name"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-seller-table-header-address" onClick={() => { setSortBy( "address"); }}>
                            <p className="table-data-header">
                                Address
                                <SortingIcons
                                    setSort={(e) => setSort(e)}
                                    fieldName="Address"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-seller-table-header-action">
                            <p className="table-data-header">Action</p>
                        </th>
                    </tr>

                </thead>
                {itemList ?
                    <tbody className="Sub-inventory-manaagement-table-body">
                        {itemList.map((element: any, index: number) => {
                            return (
                                <tr key={index} className="inventory-manaagement-table-body-row" >
                                                                        <td className="bajaj-seller-table-body-city-id">
                                        <p className="table-data-item">
                                            {element["city_id"]}
                                        </p>
                                    </td>
                                    <td className="bajaj-seller-table-body-supplier-id">
                                        <p className="table-data-item">
                                            {element["supplier_id"]}
                                        </p>
                                    </td>
                                    <td className="bajaj-seller-table-body-supplier-desc">
                                        <p className="table-data-item">
                                            {element["supplier_desc"]}
                                        </p>
                                    </td>
                                    <td className="bajaj-seller-table-body-address">
                                        <p className="table-data-item">
                                            {element["address"]}
                                        </p>
                                    </td>
                                    <td className="bajaj-seller-table-body-action">
                                        {element?.inv_loc_code ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                                            <p>{element?.inv_loc_code}</p>
                                            <IconButton>
                                                <CancelIcon onClick={() => deleteSellerID(element)} />
                                            </IconButton>
                                        </div> :
                                            <div className="action-btn">
                                                <ActionButton label="Update Supplier ID" onClick={() => {
                                                    setBajajData(element);
                                                    setShowAdd(true);
                                                }} />
                                            </div>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                    : <tbody >
                        <tr>
                            <td className="empty-data" colSpan={12}>
                                No data to display
                            </td>
                        </tr>
                    </tbody>}

            </table>
            <div className="all-products-pagination">
                <Pagination
                    count={totalPage}
                    page={page}
                    pageChange={(e: any, page: number) => setPageNo(page)}
                />
            </div>
            {showAdd && <BajajSellerIDDialogBox slide="left" open={showAdd} onClose={handleClose} profile={uri["*"] || ""} title={uri["*"] || ""} element={bajajData} />}
        </div>
    );

};