/* eslint-disable @typescript-eslint/no-explicit-any */
import ActionButton from "components/ActionButton";
import React, { useEffect, useState } from "react";
import { InventoryData } from "services/interfaces/inventory.interface";
import { InventoryService } from "services/inventory.service";
import DialogBox from "components/DialogBox";
import { useDispatch, useSelector } from "react-redux";
import { setInventoryActions, setSearchActions } from "redux/Actions/actions";
import { ProductSerialNumberService } from "services/productserial.service";
import "./styles.scss";
import { useParams, useNavigate } from "react-router-dom";
import { SearchBox } from "components/SearchBox";
import { SortingIcons } from "components/SortingArrows";
import { Pagination } from "components/Pagination";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { EVENTS, showNotification, STATUS } from "common/constant";
import { RootState } from "redux/Reducer";
import { Loader } from "components/Loader";
import TuneIcon from "@mui/icons-material/Tune";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { setProductSerialActions } from "../../redux/Actions/actions";
import moment from "moment";
import { Menu, MenuItem } from "@mui/material";
import { useSocket } from "../../context/socket.context";

const choicelist = [
  { name: "Inventory Local Code", value: "inv_loc_code" },
  { name: "Product Image", value: "img_url" },
  { name: "Pim Product Id", value: "pim_prod_id" },
  { name: "Poorvika Serial Number", value: "psn" },
  { name: "External Product Id", value: "ext_prod_id" },
  { name: "ERP Product Id", value: "erp_prod_id" },
  { name: "SKU Code", value: "sku" },
  { name: "Profile stock inHand", value: "profile_stock_inhand" },
  { name: "Profile Fullfilment Method", value: "profile_fullfillment_method" },
  { name: "Channels", value: "allowed_channels" },
  { name: "Last Updated", value: "row_updated_dttm" },
];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const InventoryTable: React.FC = () => {
  const [tableList, setTabelList] = useState<any[]>([]);
  const [showLoader, setshowLoader] = useState<boolean>(false);
  const [inventoryList, setInventoryList] = useState<InventoryData[] | []>([]);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const inventoryService = new InventoryService();
  const productSerialNumberService = new ProductSerialNumberService();
  const [recordId, setRecordId] = useState<any>();
  const [productSerialData, setProductSerialData] = useState<any>();
  const [sortField, setSortField] = useState<string>("product_name");
  const [sort, setSort] = useState<string>("ASC");
  const deleteMsg = "Are you sure you want to delete the record ?";
  const dispatch = useDispatch();
  const url = useParams();
  const params: any = url?.profile;
  const [pageNo, setPageNo] = useState(1);
  const [searchText, setSearchText] = useState<string>("");
  const [totalPage, setTotalPage] = useState<any>();
  const [hideButton, setHideButton] = useState<boolean>(true);
  const [hideButton2, setHideButton2] = useState<boolean>(true);

  const navigate = useNavigate();
  const { socket } = useSocket();
  const user: any = useSelector<RootState>(
    (state) => state?.authData?.authData
  );

  socket.on(`${EVENTS.SERVER.APX_STOCK_PROGRESS}`, (data: any) => {
    setHideButton(!data.status);
  });
  socket.on(`${EVENTS.SERVER.DROP_SHIP_STOCK_PROGRESS}`, (data: any) => {
    setHideButton2(!data.status);
  });

  const searchdata: any = useSelector<RootState>(
    (state) => state.searchDate.search,
  );

  const userEmail = useSelector<RootState>((state) => state.authData.authData.email);

  const getinventoryList = async (params: any) => {
    try {
      dispatch(setProductSerialActions(""));
      const sortingData = {
        profile: params,
        field: sortField,
        order: sort,
        productSearch: searchdata || searchText,
        limit: 10,
        page: pageNo,
      };
      const inventoryResponse: any = await inventoryService.getInventory(
        sortingData,
      );
      setInventoryList(inventoryResponse.data.inventoryData);
      setTotalPage(inventoryResponse?.data.totalRecords);
      dispatch(setInventoryActions(inventoryResponse.data.inventoryData));
    } catch (error) {
      showNotification(STATUS.FAILURE, "Something Went Wrong");

    }
  };

  const fetchPsnData = async (erp_prod_id: string) => {
    const getPsnData = await productSerialNumberService.getProductSerialNumberById(erp_prod_id);
    setProductSerialData(getPsnData.data.data[0]);
  };

  const deleteInventory = async (data: { inv_loc_code: string, erp_prod_id: string, name: string }) => {
    try {
      const deleteData: any = {
        id: data,
        profile: params,
        row_added_by: data.name
      };
      const deleteInventoryResponse = await inventoryService.deletewarehouse(
        deleteData,
      );

      if (deleteInventoryResponse) {
        getinventoryList(params);
        showNotification(
          deleteInventoryResponse.status,
          deleteInventoryResponse.message,
        );
        setDeletePopup(false);
      }
    } catch (error) {
      showNotification(STATUS.FAILURE, "Something Went Wrong");
    }
  };

  const handleOnSearch = (searchText: string) => {
    setSearchText(searchText);
  };

  const handleOnDelete = (data: { inv_loc_code: string, erp_prod_id: string, name: string } | undefined) => {
    setRecordId(data);
    setDeletePopup(true);
  };

  const handleOnEdit = (inventoryData: any) => {
    // setIsEditData(inventoryData);
    // setshowInventory(true);
    fetchPsnData(inventoryData?.erp_prod_id);
    dispatch(setProductSerialActions({ productSerialData, inventoryData }));
    navigate(`${inventoryData?.erp_prod_id}`);
  };

  const onFileChange = async (event: any) => {
    setshowLoader(true);
    const files = event.target.files[0];

    if (files) {
      const uploadfile = new FormData();
      uploadfile.append("uploadfile", files, files.name);

      try {
        const uploadResult: any = await inventoryService.bulkUpload(uploadfile);

        if (uploadResult.status === "success") {
          setshowLoader(false);
          showNotification(uploadResult.status, uploadResult.message);
        } else {
          setshowLoader(false);
          showNotification(uploadResult.status, uploadResult.message);
        }
      } catch (error) {
        setshowLoader(false);
        showNotification(STATUS.FAILURE, "Unable to upload the document");
      }
    }
  };

  const getInventoryColumns = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const data = await inventoryService.getInventoryColumn(userEmail as string) as any;

      if (data?.data) {
        setTabelList(data.data);
      }
    } catch (error) {
      setTabelList([]);

      return error;
    }
  };

  useEffect(() => {
    getInventoryColumns();
  }, []);

  useEffect(() => {
    setPageNo(1);
    setSearchText("");
  }, [params]);

  useEffect(() => {
    dispatch(setProductSerialActions(""));

    if (searchdata.length > 0) {
      getinventoryList(params);
      setSearchText(searchdata);
      dispatch(setSearchActions(""));
    } else {
      getinventoryList(params);
    }
  }, [params, sort, sortField, searchText, pageNo]);


  /* Dynamic Column Functionalities */
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const getCheckedStatus = (columnName: string) => {
    const isExists = tableList?.findIndex((column) => column.name === columnName);

    return (isExists === -1 || isExists === undefined) ? icon : checkedIcon;

  };

  /* Dynamical column selection handler functionality */
  const dynamicColumnSelectionHandler = async (columnName: string): Promise<void> => {
    const existingIndex = tableList.findIndex((column) => column.name === columnName);
    const [selectedColumn] = choicelist.filter((x) => x.name === columnName);
    let newColumns;

    /* If column already checked we need to uncheck on click action else we need to check the column */

    if (existingIndex >= 0) {
      const existingData = [...tableList];
      existingData.splice(existingIndex, 1);
      newColumns = [...new Set(existingData)];
      await inventoryService.setInventoryColumn({ email: userEmail as string, data: JSON.stringify(newColumns) as any });

    } else {
      newColumns = [...tableList, selectedColumn];
      await inventoryService.setInventoryColumn({ email: userEmail as string, data: JSON.stringify(newColumns) as any });

    }
    getInventoryColumns();
  };


  const handleUpdateStock = async () => {

    if (params === "shop") {
      console.log("APX_STOCK_UPDATE", user.email);
      socket.emit("APX_STOCK_UPDATE", { userName: user.email });
    }
    else if (params === "drop_ship") {
      console.log("DROP_SHIP_STOCK_UPDATE");
      socket.emit("DROP_SHIP_STOCK_UPDATE", { userName: user.email });
    }

    // setshowLoader(true);

    // try {
    //   // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const data = await inventoryService.updateStocks(params as string) as any;

    //   if (data.status === STATUS.SUCCESS) {
    //     showNotification(data.status, data.message);
    //     setshowLoader(false);
    //   }
    //   else {
    //     showNotification(data.status, data.message);
    //     setshowLoader(false);
    //   }
    // } catch (error) {

    //   return error;
    // }
  };

  return (
    <div className="inventory-table">
      <Loader open={showLoader} />
      <div className="table-wrapper">
        <div className="header-wrapper">
          <div className="left-content">
            <SearchBox
              onChange={(e) => handleOnSearch(e.target.value)}
              placeholder="Search by Product Name"
            />

            <div className="bulk-upload">
              <label className="custom-file-upload">
                <input
                  type="file"
                  multiple={false}
                  onChange={(e) => onFileChange(e)}
                  onClick={(event: any) => {
                    event.target.value = null;
                  }}
                />
                <div className="upload-btn">
                  <CloudUploadIcon className="upload-icon" />
                  <span className="txt">Bulk Upload</span>
                </div>
              </label>
            </div>
          </div>

          <div className="btn-wrapper">
            <div className="top-btn">
              <div className="filter-icon" onClick={handleClick}>
                <TuneIcon />
              </div>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {choicelist && choicelist?.map((choice: { name: string, value: string }) => {
                  return <MenuItem className="column-name" onClick={() => dynamicColumnSelectionHandler(choice.name)}>{getCheckedStatus(choice.name)} {choice.name}</MenuItem>;
                })}
              </Menu>
              <ActionButton
                type="button"
                label={`+ Add to ${params === "shop" ? "APX/Shop" : params.replaceAll("_", " ")} Inventory`}
                onClick={() => {
                  navigate("add");
                }}
              />
            </div>
            {(params === "drop_ship") && hideButton2 && <div>
              <ActionButton
                type="button"
                label="Update Stock"
                onClick={() => {
                  handleUpdateStock();
                }}
              />
            </div>}
            {(params === "shop") && hideButton && <div>
              <ActionButton
                type="button"
                label="Update Stock"
                onClick={() => {
                  handleUpdateStock();
                }}
              />
            </div>}
          </div>
        </div>
        <div className="table-container">

          <table className="inventory-list-table">
            <thead>
              <tr>
                <th>
                  <span>
                    Product Name
                    {" "}
                    <SortingIcons
                      setSort={setSort}
                      fieldName="product_name"
                      setFieldName={setSortField}
                    />

                  </span>
                </th>
                {tableList?.length > 0
                  ? tableList?.map(({ name, value }) => (name === "Product Image" ? (

                    <th>
                      <span>{name}</span>
                    </th>
                  ) : (
                    <th>
                      <span>
                        {name}
                        <SortingIcons
                          setSort={setSort}
                          fieldName={value}
                          setFieldName={setSortField}
                        />
                      </span>
                    </th>
                  )))
                  : (
                    <>
                    </>
                  )}

                <th>Action</th>
              </tr>
            </thead>
            {inventoryList.length > 0 ? (
              <tbody>

                {inventoryList?.map((inventoryData: any, index: number) => (
                  <tr className="inventory-list-tbody" key={index}>
                    <td>{inventoryData?.product_name}</td>
                    {tableList.length > 0
                      ? tableList?.map(({ name, value }) => (
                        name == "Product Image"
                          ? (
                            <td>
                              <img
                                src={inventoryData.img_url}
                                alt="Image Unavailable"
                                width="50px"
                                height="50px"
                              />
                            </td>
                          )
                          : <td>{value === "profile_fullfillment_method" || value === "allowed_channels" ? JSON.parse(inventoryData[value])?.map((x: any, index: React.Key) => <p key={index}>{x}</p>) :
                            value === "row_updated_dttm" ? moment(new Date(inventoryData.row_updated_dttm)).format("DD-MMM-YYYY hh:mm A") :
                              inventoryData[value]}</td>
                      )) : <></>}

                    <td>
                      <div className="action-btn-wrap">
                        <ActionButton
                          label="Edit"
                          color="outlined"
                          onClick={() => handleOnEdit(inventoryData)}
                        />
                        <ActionButton
                          label="Delete"
                          onClick={() => handleOnDelete({ inv_loc_code: inventoryData.inv_loc_code, erp_prod_id: inventoryData.erp_prod_id, name: user.name })}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td className="empty-data" colSpan={12}>
                    No products available
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
      <Pagination
        count={Math.ceil(totalPage / 10)}
        page={pageNo}
        pageChange={(e, page: number) => setPageNo(page)}
      />
      {
        deletePopup && (
          <DialogBox
            open={deletePopup}
            onClose={setDeletePopup}
            message={deleteMsg}
            title="Delete"
            slide="down"
            onClick={() => deleteInventory(recordId)}
          />
        )
      }
    </div>
  );
};
