import React, { useEffect, useState } from "react";
import { KeyboardArrowRightOutlined } from "@mui/icons-material";
import NavlinkCardcontent from "components/NavLinkCardContent";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Locations } from "./LocationPage";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Grid } from "@mui/material";
import { AddLocationPage } from "./AddEditLocationPage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/Reducer";
import { setWarehouseActions } from "redux/Actions/actions";
import { InventoryManagement } from "./InventoryManagement";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "./styles.scss";
import { showNotification } from "common/constant";
import { WarehouseService } from "services/warehouse.service";
import { STATUS } from "../../common/constant";
import { Loader } from "components/Loader";
import BulkDialogBox from "components/BulkDialogBox";

export const InventoryLocationPage: React.FC = () => {
    const dispatch = useDispatch();
    const [showDialog, setshowDialog] = useState<boolean>(false);
    const [showLoader, setshowLoader] = useState<boolean>(false);
    const [response, setResopnse] = useState<any>();
    const [linkHeader, setLinkHeader] = useState<string>("Active Locations");
    const isEditWarehouse = useSelector<RootState>((state) => state.warehouseData.warehouseData);
    const warehouseService = new WarehouseService();
    const navigate = useNavigate();
    const uri = useParams();

    const breadcrumbs = [
        <div className='bread-crumb-navlink' key="1" >Location Details </div>,
        <div className='bread-crumb-navlink' key="2" > {linkHeader} </div>,
    ];
    const handleOnAddLocations = (e: any) => {
        setLinkHeader(e);
        dispatch(setWarehouseActions(null));
    };

    // const handleOnInventory = (e: any) => {
    //     setLinkHeader(e);
    //     dispatch(setWarehouseActions(null));
    // };

    const onFileChange = async (event: any) => {
        setshowLoader(true);
        const files = event.target.files[0];

        if (files) {
            const uploadfile = new FormData();
            uploadfile.append("uploadfile", files, files.name);

            try {
                const uploadResult: any = await warehouseService.bulkUpload(uploadfile);

                if (uploadResult.status === "success") {
                    setResopnse({ data: uploadResult.data, CsvHeader: "Warehouse" });
                    setshowLoader(false);
                    setshowDialog(true);
                } else {
                    setshowLoader(false);
                    showNotification(uploadResult.status, uploadResult.message);
                }
            } catch (error) {
                setshowLoader(false);
                showNotification(STATUS.FAILURE, "Unable to upload the document");
            }
        }
    };

    useEffect(() => {
        !showDialog && navigate("/central-inventory/inventory-location/active-locations");
    }, [showDialog]);

    return (
        <div className="location-inventory-page">
            <Loader open={showLoader} />
            <Grid item md={11} xs={12} className='bread-crumb-layout'>
                <Breadcrumbs className="mt-2" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" >
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            {!isEditWarehouse && Object.values(uri)[0] === "add-location" && <div className="bulk-upload">
                <label className="custom-file-upload">
                    <input type="file" multiple={false}
                        onChange={(e) => onFileChange(e)}
                        onClick={(event: any) => {
                            event.target.value = null;
                        }}
                    />
                    <div className="upload-btn">
                        <CloudUploadIcon className="upload-icon" />
                        <span className="txt">Bulk Upload</span>
                    </div>
                </label>
            </div>}
            <div className="profile-details-layout">
                <div className="pd-sidemenu">
                    <div className="layout-Header">
                        <p>Locations & Inventory</p>
                        <KeyboardArrowRightOutlined />
                    </div>
                    <div className="layout-card-content">
                        <>
                            <NavlinkCardcontent onSelected={(e) => setLinkHeader(e)} label="Active Locations" to="active-locations" />
                            <NavlinkCardcontent onSelected={(e) => setLinkHeader(e)} label="Inactive Locations" to="inactive-locations" />
                            <NavlinkCardcontent onSelected={(e) => handleOnAddLocations(e)} label="Add Location" to="add-location" state="" />
                            {isEditWarehouse && <NavlinkCardcontent onSelected={(e) => setLinkHeader(e)} label="Edit Location" to="edit-location" state={isEditWarehouse} />}
                            {/* <NavlinkCardcontent onSelected={(e) => handleOnInventory(e)} label="Inventory Management" to="inventory-management" state="" /> */}
                        </>
                    </div>
                </div>
                <div className="pd-container">
                    <Routes>
                        <Route path="/" element={<Navigate replace to="/central-inventory/inventory-location/active-locations" />} />
                        <Route element={<Locations />} path="/:location" />
                        <Route element={<AddLocationPage isEdit={false} />} path="/add-location" />
                        <Route element={<AddLocationPage isEdit={true} />} path="edit-location" />
                        <Route element={<InventoryManagement />} path="/inventory-management/*" />
                    </Routes>

                </div>
            </div>
            {showDialog && <BulkDialogBox open={showDialog} result={response} onClose={setshowDialog} title="Bulk Upload Summary" />}
        </div>
    );
};