import { AxiosError } from "axios";
import { IErrorResponse } from "services/interfaces/ApiResponse.interface";
import * as toaster from "toastr";
// import socketIOClient from "socket.io-client";

// export const BASE_URL = "http://localhost:3001/";
// export const SOCKET_BASE_URL = "http://localhost:3001/";

/* 
Dev url (Linode)
*/
// export const BASE_URL = "https://dev-centralinventory.poorvika.com/api/";
// export const SOCKET_BASE_URL = "https://dev-centralinventory.poorvika.com";

/* 
Staging url (GCP)
*/

export const BASE_URL = "https://central-inventory-n7fvs5sroa-el.a.run.app";
export const SOCKET_BASE_URL = "https://central-inventory-n7fvs5sroa-el.a.run.app";

export const PIM_API = "https://pimapi-d2jn4z2hbq-el.a.run.app/";
export const PINCODE_API = "https://poorvika.org/api/oc3/";
export const GST_API = "https://dev-seller.poorvika.com/api/accounts/";
export const STOCK_INFO = "https://apx.poorvikamobiles.net/api/apxapi/";


export const STATUS = {
    SUCCESS: "success",
    FAILURE: "failure"
};

export const EVENTS = {
    connection: "connection",
    CLIENT: {
        APX_STOCK_UPDATE: "APX_STOCK_UPDATE",
        DROP_SHIP_STOCK_UPDATE: "DROP_SHIP_STOCK_UPDATE",
    },
    SERVER: {
        APX_STOCK_PROGRESS: "APX_STOCK_PROGRESS",
        DROP_SHIP_STOCK_PROGRESS: "DROP_SHIP_STOCK_PROGRESS",
        BULK_PRODUCT_UPLOAD: "BULK_PRODUCT_UPLOAD",
    },
};

export const showNotification = (type: string, message: string): void => {
    const toasterOptions = { positionClass: "toast-top-center" };
    toaster.remove();
    // eslint-disable-next-line no-param-reassign
    message = message || "Something Went Wrong";

    if (type === STATUS.SUCCESS) {
        toaster.success(message, "", toasterOptions);
    } else if (type === STATUS.FAILURE) {
        toaster.error(message, "", toasterOptions);
    }
};

export const parseAxiosError = (error: AxiosError): IErrorResponse => {
    if (error.isAxiosError && error.response) {

        showNotification(STATUS.FAILURE, error.response.data.message);

        return { status: STATUS.FAILURE, message: error.response.data.message, error };
    } else {
        showNotification(STATUS.FAILURE, error.message);

        return { status: STATUS.FAILURE, message: error.message, error };
    }
};


export const fullfillmentMethod = ["NORMAL", "HYPER_LOCAL", "SAME_DAY", "NEXT_DAY", "WEEK", "TWO_HOURS"];

export const allowedChannels = ["online", "amazon", "flipkart", "tatacliq"];


export const Istate = [
    "Andhra Pradesh",
    "Andaman and Nicobar Islands",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadar and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"
];

export const Icountry = ["INDIA"];

export const profile = ["warehouse", "drop_ship", "shop", "seller"];

export const referenceData = ["Bajaj"];

export const referenceData2: any = { "Bajaj": ["Seller_ID", "SKU", "History"] };

export const BajajHeaders: any = {
    Bajaj_SellerID: ["supplier_id", "supplier_desc", "city_id", "address"],
    Bajaj_SKU: ["Name", "category", "sku", "mop", "mrp", "row_updated_dttm"],
    Bajaj_Sync_Info: ["Name", "bajaj_sku", "pim_sku", "status", "mop", "mrp", "row_updated_dttm"]
};

export const IDays = [
    "Sunday",
    "Monday",
    "Tuesday",
];
export const WEEK_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
export const ALL_DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const ALL_COURIER_DAYS = [
    { name: "Regular", defaultStartTime: "12:00:00", defaultEndTime: "23:59:00" },
    { name: "2 HRS", defaultStartTime: "10:00:00", defaultEndTime: "18:00:00" },
    { name: "Hyper Local", defaultStartTime: "06:01:00", defaultEndTime: "24:00:00" },
    { name: "Same Day", defaultStartTime: "12:01:00", defaultEndTime: "21:59:00" },
    { name: "Next Day", defaultStartTime: "6:01:00", defaultEndTime: "12:00:00" },
    // { name: "3 Days", defaultStartTime: `12:00:00`, defaultEndTime: `23:59:00` },
    // { name: "5 Days", defaultStartTime: `12:00:00`, defaultEndTime: `23:59:00` },
    { name: "Pickup at Store", defaultStartTime: "12:00:00", defaultEndTime: "23:59:00" }
];

export const InventoryStatus = [
    {
        name: "All",
        path: "/central-inventory/inventory-location/inventory-management/all"
    },
    {
        name: "Active",
        path: "/central-inventory/inventory-location/inventory-management/ActiveInventory"
    },
    {
        name: "InActive",
        path: "/central-inventory/inventory-location/inventory-management/InActiveInventory"
    }
];
export const API_ENDPOINTS = {
    WAREHOUSE: "https://seller-d2jn4z2hbq-el.a.run.app/",
    DOWNLOAD_INVENTORY: "inventory/list/template/",
    BULK_UPLOAD_INVENTORY: "gcs/inventory/stock",
    UPLOAD_INENTORY_HISTORY: "gcs/bulkupdate/history",

};

/* Change Detection Algo - Need to be streamlined baed on client inputs */
export const detectChanges = (currentValue: any, operation: string, previousValue?: any) => {
    let currentKeys: string[] = [];
    
    if (operation === "CREATE") {
        currentKeys = Object.keys(currentValue);

        return currentKeys;
    }
    else if (operation === "DELETE") {
        currentKeys = Object.keys(previousValue);

        return currentKeys;
    }
    else {
        currentKeys = Object.keys(currentValue);
        const modifiedKeys = currentKeys?.map((key: string) => {
            if (JSON.stringify(previousValue[key]) !== JSON.stringify(currentValue[key])) {
                return key;
            }
        });

        return modifiedKeys;
    }
};
