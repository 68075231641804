export const ProductSerialNumberValidations = {
    erp_prod_id: {
        required: { value: false, message: "ERP Prodcut Id field is empty" }
    },
    inv_loc_code: {
        required: { value: false, message: "Inventory Local code field is empty" }
    },
    ext_prod_id: {
        required: { value: false, message: "External Product Id field is empty" }
    },
    sku: {
        required: { value: false, message: "SKU can't be empty" }
    },
    prod_id: {
        required: { value: true, message: "IMEI number can't be empty" },
    },
    status: {
        required: { value: true, message: "Product status can't be empty" }
    },
    invoice_no: {
        required: { value: false, message: "Purchase Invoice can't be empty" }
    },
    sale_invoice_no: {
        required: { value: false, message: "Sales Invoice Number can't be empty" }
    },
    invoice_date:{
        required:{value: false, message:"Please select Purchase Invoice Date"}
    },
    sales_invoice_date:{
        required:{value:false, message:"Please select Sales Invoice Date"}
    },
};