import React, { useEffect, useState } from "react";
import { Pagination } from "components/Pagination";
import { detectChanges, showNotification, STATUS } from "common/constant";
import { Dialog } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { HistoryService } from "services/history.service";

import "./styles.scss";
import { IHistory } from "services/interfaces/history.interface";
import moment from "moment";
import { SearchBox } from "components/SearchBox";
import { SortingIcons } from "components/SortingArrows";

type TProp = {
    erp_prod_id?: string
    inv_loc_code?: string
    module?: string
}

export const HistoryPage: React.FC<TProp> = ({ erp_prod_id, inv_loc_code, module }) => {

    /* State Variables  */
    const [previousValue, setPreviousValue] = useState<any>({});
    const [currentValue, setCurrentValue] = useState<any>({});
    const [operations, setOperations] = useState<string>("");
    const [modifiedFields, setModifiedFields] = useState<string[]>([]);
    const [detailsDialogVisibility, setDetailsDialogVisibility] = useState<boolean>(false);
    const [historyData, setHistoryData] = useState<IHistory[]>([]);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [serach, setSerach] = useState<string>("");
    const [sort, setSort] = useState<string>("DESC");
    const [sortField, setSortField] = useState<string>("createdAt");
    const [page, setPage] = useState<number>(1);


    /* Services */
    const historyService = new HistoryService();

    /* Initial history fetching and reusable upon pagination action */
    const getHistory = async (page?: number, pageLimit?: number, sortField?: string, sort?: string, erp_prod_id?: string, inv_loc_code?: string): Promise<void> => {

        try {
            const getHistoryResponse = await historyService.getHistory(page, pageLimit, sortField, sort, serach, inv_loc_code, module, erp_prod_id);

            if (getHistoryResponse.status === "success") {
                setHistoryData(getHistoryResponse?.data?.history);
                setTotalPage(Math.ceil(getHistoryResponse?.data?.totalCount[0]?.count / 10));
            } else {
                setHistoryData([]);
                setTotalPage(1);
                showNotification(STATUS.SUCCESS, "Unable to get History List");
            }
        } catch (error) {
            showNotification(STATUS.SUCCESS, "Unable to get History List");
        }
    };

    /* View changes dialog & previous data and current data logical functionalities handler */
    const viewDetailsHandler = async (history: IHistory): Promise<void> => {

        const prevValue = history?.previousValue && JSON.parse(history?.previousValue);
        const curValue = history?.currentValue && JSON.parse(history?.currentValue);
        console.log(prevValue, curValue);
        setPreviousValue(prevValue);
        setCurrentValue(curValue);
        setOperations(history?.operationType);

        if (history?.createdBy === "CRON JOB") {
            setModifiedFields(["product_name", "erp_prod_id", "inv_loc_code", ...detectChanges(curValue, history?.operationType, prevValue) as string[]]);
        }
        else {
            setModifiedFields(detectChanges(curValue, history?.operationType, prevValue) as string[]);
        }

        setDetailsDialogVisibility(true);

    };

    useEffect(() => {
        getHistory(page, 10, sortField, sort, erp_prod_id, inv_loc_code);
    }, [serach, sort, sortField, page]);


    return <div className="history-page">
        <div className="header-component">
            <h3 className="page-title">History</h3>
            <SearchBox onChange={(e) => setSerach(e.target.value.toLowerCase())}
                placeholder="Search" />
        </div>
        <table className="history-table">
            <thead>
                <tr>
                    <th>
                        <div className="header-label">
                            Date & Time
                            <SortingIcons
                                setSort={(e) => setSort(e)}
                                fieldName="createdAt"
                                setFieldName={(e) => { setSortField(e); }}
                            />
                        </div>
                    </th>
                    <th>
                        <div className="header-label">
                            Module
                            <SortingIcons
                                setSort={(e) => setSort(e)}
                                fieldName="moduleType"
                                setFieldName={(e) => { setSortField(e); }}
                            />
                        </div>
                    </th>
                    <th>
                        <div className="header-label">
                            Operation Type
                            <SortingIcons
                                setSort={(e) => setSort(e)}
                                fieldName="operationType"
                                setFieldName={(e) => { setSortField(e); }}
                            />
                        </div>
                    </th>
                    <th>
                        <div className="header-label">
                            Name
                            <SortingIcons
                                setSort={(e) => setSort(e)}
                                fieldName="createdBy"
                                setFieldName={(e) => { setSortField(e); }}
                            />
                        </div>
                    </th>
                    <th>Details</th>

                </tr>
            </thead>
            <tbody>
                {historyData?.map((history: IHistory, index: number) => {
                    return <tr key={index}>
                        <td>{moment(history?.createdAt).format("DD-MMM-YYYY hh:mm A")}</td>
                        <td>{history?.moduleType}</td>
                        <td>{history?.operationType}</td>
                        <td>{history?.createdBy}</td>
                        <td>
                            <button className="view-text" onClick={() => viewDetailsHandler(history)}>View Changes</button>
                        </td>
                    </tr>;
                })}
            </tbody>
        </table>
        <Pagination count={totalPage} page={page} pageChange={(e, page) => setPage(page)} />
        <Dialog
            fullWidth
            maxWidth="md"
            open={detailsDialogVisibility} className="details-dialog">
            <div className="close-button-container" onClick={() => setDetailsDialogVisibility(false)}>
                <CloseRoundedIcon className="close-button" />
            </div>

            <div className="changes-details-wrapper">
                <div className="table-wrapper">
                    <table className="detailed-history-table">
                        <thead>
                            <tr>
                                <th>Value</th>
                                {operations === "CREATE" ? "" : <th>Previous Value</th>}
                                {operations === "DELETE" ? "" : <th>Current Value</th>}


                            </tr>
                        </thead>
                        <tbody>
                            {modifiedFields && modifiedFields?.map((modifiedKey, index: number) => {
                                if (modifiedKey) {
                                    if (modifiedKey === "channels" || modifiedKey === "pim_sku" || modifiedKey === "active_hours_and_days" || modifiedKey === "attributes" || modifiedKey === "channels") {
                                        return <tr key={index}>
                                            <td>{modifiedKey}</td>
                                            {operations === "CREATE" ? "" : <td>{previousValue ? JSON.stringify(previousValue[modifiedKey]) : ""}</td>}
                                            {operations === "DELETE" ? "" : <td>{currentValue ? JSON.stringify(currentValue[modifiedKey]) : ""}</td>}
                                        </tr>;
                                    }
                                    else {
                                        return <tr key={index}>
                                            <td>{modifiedKey}</td>
                                            {operations === "CREATE" ? "" : <td>{previousValue[modifiedKey]?.toString() || ""}</td>}
                                            {operations === "DELETE" ? "" : <td>{currentValue[modifiedKey]?.toString() || ""}</td>}
                                        </tr>;
                                    }
                                }

                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        </Dialog>
    </div>;
};
