import React, { useState, useEffect } from "react";
import User from "assets/icons/userSvg.svg";
import { IconButton, Popover } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { setAuthData } from "redux/Actions/actions";
import SandClock from "../../assets/icons/sandClock.gif";
import { RootState } from "redux/Reducer";
import { useSocket } from "context/socket.context";
import { EVENTS, showNotification, STATUS } from "common/constant";
import ProgressBar from "components/ProgressBar";
import BulkDialogBox from "components/BulkDialogBox";
import SandClockStatic from "../../assets/icons/sandClockStatic.png";

export const HeaderLayout: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(null);
    const [data, setData] = useState<any>({});
    const [data2, setData2] = useState<any>({});
    const [data3, setData3] = useState<any>({});
    const [data4, setData4] = useState<any>({});
    const [result, setResult] = useState<any>(false);
    const user: any = useSelector<RootState>(
        (state) => state?.authData?.authData
    );
    const { socket } = useSocket();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    useEffect(() => {
        if (data?.statusCode === "Completed" && data2?.statusCode === "Completed" && data3?.statusCode === "Completed" && data4?.statusCode === "Completed") {
            setAnchorEl2(null);
        }

    }, [data, data2, data3, data4]);

    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const id = open ? "simple-popover" : undefined;
    const id2 = open2 ? "simple-popover" : undefined;
    const handleOnLogout = () => {
        localStorage.clear();
        dispatch(setAuthData({}));
        navigate("/login");

        if (keycloak?.authenticated) {
            keycloak.logout();
        }
    };

    const handleResult = () => {
        setResult(true);
        showNotification(STATUS.SUCCESS, "Inventory items added Successfully");
    };


    socket.on("progress", (data: any) => {
        setData(data);
    });
    socket.on(`${EVENTS.SERVER.APX_STOCK_PROGRESS}_${user.email}`, (data: any) => {
        setData2(data);
        data.status === false && data?.statusCode === "Completed" && showNotification(STATUS.SUCCESS, "Apx stock updated Successfully");
    });
    socket.on(`${EVENTS.SERVER.DROP_SHIP_STOCK_PROGRESS}_${user.email}`, (data: any) => {
        setData3(data);
        data.status === false && data?.statusCode === "Completed" && showNotification(STATUS.SUCCESS, "Drop Ship stock updated Successfully");
    });
    socket.on(`${EVENTS.SERVER.BULK_PRODUCT_UPLOAD}_${user.email}`, (data: any) => {
        setData4(data);
        data.status === false && data?.statusCode === "Completed" && showNotification(STATUS.SUCCESS, "Drop Ship stock updated Successfully");
    });

    return (
        <div className="headers">
            <IconButton className="progress-status" onClick={handleClick2}>
                {(data.status || data2.status || data3.status || data4.status) ? <img src={SandClock} style={{ cursor: "pointer" }} /> : data4?.statusCode === "Completed" ?<img src={SandClockStatic} style={{ cursor: "pointer" }} />:""}
            </IconButton>
            <IconButton className="profile" onClick={handleClick}>
                <img src={User} alt="Poorvika" width="24px" />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <div className="btn-container" style={{ padding: "10px", cursor: "pointer" }}>
                    <span onClick={() => handleOnLogout()}>Logout</span>
                </div>
            </Popover>
            <Popover
                id={id2}
                open={open2}
                anchorEl={anchorEl2}
                onClose={handleClose2}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                style={{ cursor: "default" }}
            >
                <div className="btn-container" style={{ width: "500px", padding: "20px 0px" }}>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        padding: "0px 8px",
                    }}>
                        {data?.status ? <div style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            border: "1px solid #ff8e3445",
                            background: "#ff8e3445",
                            padding: "10px 20px",
                            borderRadius: "8px"
                        }}>
                            <p style={{ width: "100%", fontSize: "18px", fontWeight: "700", textAlign: "center", margin: "0" }}>Add To All</p>
                            <div style={{ width: "100%", paddingBottom: "20px" }}>
                                <ProgressBar progress={100 - data.percentage} />
                            </div>
                        </div> : ""}
                        {data2?.status ? <div style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            border: "1px solid #ff8e3445",
                            background: "#ff8e3445",
                            padding: "10px 20px",
                            borderRadius: "8px"
                        }}>
                            <p style={{ width: "100%", fontSize: "18px", fontWeight: "700", textAlign: "center", margin: "0" }}>APX Stock Update</p>
                            <div style={{ width: "100%", paddingBottom: "20px" }}>
                                <ProgressBar progress={100 - data2.percentage} />
                            </div>
                        </div> : ""}
                        {data3?.status ? < div style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            border: "1px solid #ff8e3445",
                            background: "#ff8e3445",
                            padding: "10px 20px",
                            borderRadius: "8px"
                        }}>
                            <p style={{ width: "100%", fontSize: "18px", fontWeight: "700", textAlign: "center", margin: "0" }}>Drop Ship Stock Update</p>
                            <div style={{ width: "100%", paddingBottom: "20px" }}>
                                <ProgressBar progress={100 - data3.percentage} />
                            </div>
                        </div> : ""}
                        {data4?.status ? <div style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            border: "1px solid #ff8e3445",
                            background: "#ff8e3445",
                            padding: "10px 20px",
                            borderRadius: "8px"
                        }}>
                            <p style={{ width: "100%", fontSize: "18px", fontWeight: "700", textAlign: "center", margin: "0" }}>Bulk Upload</p>
                            <div style={{ width: "100%", paddingBottom: "20px" }}>
                                <ProgressBar progress={100 - data4.percentage} />
                            </div>
                        </div> :
                            data4?.statusCode === "Completed" ?
                                <div style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    border: "1px solid #ff8e3445",
                                    background: "#ff8e3445",
                                    padding: "10px 20px",
                                    borderRadius: "8px"
                                }}>
                                    <p style={{ width: "100%", fontSize: "18px", fontWeight: "700", textAlign: "center", margin: "0" }}>Bulk Upload</p>
                                    <p className="progress-result" onClick={() => {
                                        handleResult();
                                    }}>Click To View Result</p>
                                </div> : ""}
                    </div>
                </div>
            </Popover >
            {result && <BulkDialogBox open={result} onClose={(e) => {
                setResult(e);
                setData4({});
            }} title="Add to All" result={data4.result} />}
        </div >
    );
};

