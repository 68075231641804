import React from "react";
import { useForm } from "react-hook-form";
import UserIcon from "assets/icons/user.png";
import InputFieldWithoutLabel from "components/InputFieldWithoutLabel";
import ActionButton from "components/ActionButton";
import { useNavigate } from "react-router-dom";
import { AuthService } from "services/auth.service";
import "./styles.scss";
import { showNotification } from "../../../common/constant";

type formFields = {
    email: string
}

const loginFormValidation = {
    email: {
        required: { value: true, message: "Email field is empty" },
        pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Please Enter Valid Email" }
    }
};


const ForgotPasswordForm: React.FC = () => {

    const { register, handleSubmit, formState: { errors }, setValue } = useForm<formFields>({ mode: "onChange" });
    const navigate = useNavigate();
    const Authentication = new AuthService();



    const onSubmit = async (data: formFields) => {
        const apiData: formFields = {
            email: data.email,
        };
        const forgotPassword = await Authentication.ForgotPassword(apiData);
        console.log("forgotPassword", forgotPassword);

        if (forgotPassword) {
            if (forgotPassword.status === "success") {
                showNotification(forgotPassword.status, forgotPassword.message);
                setValue("email", "");
                setTimeout(() => {
                    navigate("/login");
                }, 2000);
            }
            
            if (forgotPassword?.status === "error") {
                showNotification(forgotPassword?.status, forgotPassword.message);
            }
        }
    };

    return (
        <div className="forgot-password-form">
            <p className="sign-in-content">Forgot Password</p>
            <p className="access-acc-content">Please enter your registered email Id</p>
            <form className="login-form-wrap" onSubmit={handleSubmit(onSubmit)} >
                <div className="form-body">
                    <InputFieldWithoutLabel
                        register={register("email", loginFormValidation.email)}
                        text="Email"
                        error={errors.email?.type !== undefined}
                        errorText={errors.email ? errors.email.message : ""}
                        icon={UserIcon}
                    />
                </div>
                {/* <p className="conditions-content">Password reset link will be sent to your registered Email-id. </p> */}
                <div className="form-btn-wrap">
                    <ActionButton type="submit" label="Reset" />
                </div>
            </form>
            <p>Back to <span className="blue-content" onClick={() => navigate("/login")}> Login</span></p>
        </div>
    );
};

export default ForgotPasswordForm;