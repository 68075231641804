import { SearchBox } from "components/SearchBox";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSearchActions } from "redux/Actions/actions";
import { InventoryService } from "services/inventory.service";
import "./styles.scss";
import { SearchProductCard } from "components/SearchCard";
import { showNotification, STATUS } from "common/constant";



export const DashboardPage: React.FC = () => {
  const inventoryService = new InventoryService();
  const [inventoryList, setinventoryList] = useState<any>("");
  const [show, setShow] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const handleDetails = (profile: string, name: string) => {
      dispatch(setSearchActions(name));
      navigate(`/central-inventory/inventory/${profile}`);
  };

  const getinventoryList = async (search: string) => {

    if (search.length < 3) {
      setShow(false);
      setinventoryList("");

      return;
    }

    try {
      setShow(true);
      const inventoryResponse = await inventoryService.getSearchInventory(search);
      setinventoryList(inventoryResponse.data.products);
    } catch (error) {
      showNotification(STATUS.FAILURE, "Something went wrong");
    }
  };
  useEffect(() => {
    setShow(false);
    setinventoryList("");
    dispatch(setSearchActions(""));
  }, []);

  return (
    <div className="dashboard-main">
      <div className="search">
        <SearchBox
          onChange={(e) => getinventoryList(e.target.value.toLowerCase())}
          placeholder="Search by Product Name or SUK code or ERP id"
        />
      </div>
      <div className="product" >
        {show ? inventoryList.length ? (
          <div className="product_Card">
            {inventoryList?.map((product: any) =>
              <SearchProductCard imgUrl={product?.product_img} productName={product?.product_name} drop_ship={product.count.drop_ship} warehouse={product.count.warehouse} seller={product.count.seller} shop={product.count.shop} onClick={(profile, name) => handleDetails(profile, name)} />
            )}
          </div>
        ) :

          <div className="no-product"> Search Result Empty</div> : <></>}
      </div>
    </div>
  );
};