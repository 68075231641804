import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm } from "react-hook-form";
import InputFieldWithLabels from "components/InputFieldWithLabels";
import ActionButton from "components/ActionButton";
import { InventoryService } from "services/inventory.service";
import { PIMService } from "services/pimapi.service";
import {
    Autocomplete, Checkbox, Dialog, FormControlLabel, IconButton, TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { InventoryData } from "services/interfaces/inventory.interface";
import CancelIcon from "@mui/icons-material/Cancel";
import { fullfillmentMethod, showNotification, STATUS } from "common/constant";

import { InventoryFormValidation } from "./validator";
import ProductSerialAddEditForm from "../../../containers/forms/ProductSerialAddEditForm";

import "./styles.scss";
import AlertBox from "components/AlertBox";
import { Loader } from "components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/Reducer";
import { useNavigate, useParams } from "react-router-dom";
import { setProductSerialActions } from "redux/Actions/actions";
import { ChannelService } from "services/channel.service";
import { HistoryPage } from "pages/RootAdmin/HistoryPage";
// import ProgressBar from "components/ProgressBar";
// import AddToAll from "components/AddToAll";
import HistoryIcon from "../../../assets/icons/history.png";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const InventoryAddEditForm: React.FC = () => {
    const propsDatas: any = useSelector<RootState>(
        (state) => state.productSerialData.productSerialData,
    );
    const { inventoryData } = propsDatas;
    const {
        register, handleSubmit, watch, setValue, formState: { errors }, getValues,
    } = useForm<any>({ mode: "onChange" });
    const [selectedFullFillments, setSelectedFullFillments] = useState<string[]>(inventoryData?.profile_fullfillment_method ? JSON.parse(inventoryData?.profile_fullfillment_method) : []);
    const [channels, setChannels] = useState<number[]>([]);
    const inventoryService = new InventoryService();
    const [psnData, setPsnData] = useState<any>();
    const pimService = new PIMService();
    const [errMsg, setErrMsg] = useState<string>("");
    const [products, setProducts] = useState<any>();
    const [warehouseCode, setWarehouseCode] = useState<any>([]);
    const [defaultForInventoryCode, setdefaultInventoryCode] = useState<any>(null);
    const [channelStock, setChannelStock] = useState<number>(0);
    const [openPsn, setOpenPsn] = useState<boolean>(false);
    const [alert, setAlert] = useState<boolean>(false);
    const [openLoader, setOpenLoader] = useState<boolean>(false);
    const allFields = watch();
    const user: any = useSelector<RootState>((state) => state?.authData?.authData);
    const [extCode, setExtCode] = useState<any>("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [stockData, setStockData] = useState<string>("");
    const url = useParams();
    const navigate = useNavigate();
    const profile = url.profile === "all" ? inventoryData?.profile ? inventoryData.profile : url.profile : url.profile;
    const dispatch = useDispatch();
    const [allowedChannels, setAllowedChannels] = useState<any>();
    const channelservice = new ChannelService();
    const APXchannels = ["NORMAL", "NEXT_DAY", "SAME_DAY", "TWO_HOURS"];
    // const [showProgress,setshowProgress]= useState<boolean>(false);
    // const [progress, setProgress] = useState<number>(0);
    // const [addToAllDialog,setAddToAllDialog]=useState<boolean>(false);
    // const [apiResponse, setapiResponse] = useState<any>({});
    const [detailsDialogVisibility, setDetailsDialogVisibility] = useState<boolean>(false);


    const fetchAllowedChannels = async () => {
        const { data } = await channelservice.getChannels();
        setAllowedChannels(data?.channels);
    };

    useEffect(() => {
        fetchAllowedChannels();
    }, []);

    const handleOnChannelStock = () => {
        const channelStockMap = channels?.map((number: any) => Number(watch(`channel_stock_inhand-${number}`)));
        const stockNum = channelStockMap.reduce((a, b) => a + b, 0);
        setChannelStock(stockNum);

        return stockNum;
    };
    useEffect(() => {
        setPsnData(propsDatas.productSerialData);
    }, [propsDatas.productSerialData]);

    const getProductSearch = async (search: any) => {
        try {
            if (search.length > 2) {
                const productSearchResponse = await pimService.getPim(search);

                if (productSearchResponse) {
                    setProducts(productSearchResponse.data);

                    return productSearchResponse.data;
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getStockApi = async (extCode: { ext_warehouse_code: string }, erp_prod_id: string) => {
        try {
            const values = {
                ext_code: extCode?.ext_warehouse_code,
                erp_prod_id,
            };
            const stockApi: any = await pimService.getStock(values);

            if (stockApi.Data.length) {
                setValue("profile_stock_inhand", Math.round(stockApi.Data[0].SALEABLE_STOCK));
            } else {
                setValue("profile_stock_inhand", "0");
            }
            setStockData(JSON.stringify(stockApi.Data));
        } catch (error: any) {
            showNotification("error", error.message);
        }
    };


    const updateDropship = async (code: string, warehouse: any) => {

        const data = (await inventoryService.updateDropship(code));

        const products = data?.msg?.productDetails;

        if (products && products?.length) {
            const findWarehouse = products.find((x: { warehouseName: string; }) => x.warehouseName === warehouse.name);

            if (findWarehouse) {
                setValue("profile_stock_inhand", findWarehouse.qty);
            } else {
                setValue("profile_stock_inhand", "0");
            }
        } else {
            setValue("profile_stock_inhand", "0");
        }


        // const name = data.data.msg.productDetails?.[0]?.warehouseName;
        // const qty = data.data.msg.productDetails?.[0]?.qty;
        // inv_code ? (inv_code.toLowerCase() === name.toLowerCase() ? (setValue("profile_stock_inhand", qty), console.log("this")) : setValue("profile_stock_inhand", 0), console.log("not-this")) : "";
    };

    const productSelection = async (product: any) => {
        setValue("product_details", JSON.stringify(product));
        setValue("product_name", product?.name);
        setValue("sku", product?.sku);
        setValue("erp_prod_id", product?.erp_item_code);
        setValue("img_url", product?.image.all.url);
    };

    const warehouseSelection = async (value: any) => {
        setExtCode(value);
        setValue("inv_loc_code", value.code);
        setValue("branch_code", value.ext_warehouse_code);
        console.log(value);
    };

    const getWarehouseCode = async () => {
        try {
            const warehouseCodeResponse = await inventoryService.getWarehouseCode();

            if (warehouseCodeResponse) {
                if (inventoryData?.inv_loc_code) setdefaultInventoryCode(JSON.stringify(warehouseCodeResponse?.data?.warehousecode.find((x: { code: any; }) => x.code === inventoryData?.inv_loc_code)));
                else setdefaultInventoryCode(null);
                setWarehouseCode(warehouseCodeResponse?.data?.warehousecode);
                setValue("inv_loc_code", inventoryData?.inv_loc_code);
            }
        } catch (error) {
            console.log(error);
        }
    };
    console.log(inventoryData);

    const fullfillmentMethodSelection = (selected: boolean, selectedFullfillement: string) => {
        if (selected) {
            setSelectedFullFillments([...selectedFullFillments, selectedFullfillement]);
        } else {
            const selectedindex = selectedFullFillments.indexOf(selectedFullfillement);

            if (selectedindex >= 0) {
                setSelectedFullFillments(selectedFullFillments?.filter((val) => val !== selectedFullfillement));
            }
        }
    };

    const handleOnAlert = (alert: boolean, message: string) => {
        setAlert(alert);
        setErrMsg(message);
    };


    const onSubmit = async (data: any) => {
        if (Number(data.profile_stock_inhand) < channels?.map((channel) => Number(data[`channel_stock_inhand-${channel}`])).reduce((sum, x) => sum + x, 0)) {
            setErrMsg("Profile In-hand stock should be greater than Total channel In-hand stock");

            return;
        }

        const channelsJson: any = {};
        let price: any = [];
        let element: any = {};
        console.log("data", data);
        channels?.forEach((channel) => {
            const keyChannel = data[`allowed_channels-${channel}`];
            fullfillmentMethod.forEach((method) => {
                if (data[`shipment_price-${channel}-${method}`]) {
                    element = { [`${method}`]: data[`shipment_price-${channel}-${method}`] };
                    price.push(element);
                }
            });
            Object.assign(
                channelsJson,
                {
                    [keyChannel]: {
                        in_hand: data[`channel_stock_inhand-${channel}`],
                        reserved: data[`channel_stock_res-${channel}`],
                        fullfillment: { allowed: data[`fullfillment-${channel}`], price },
                    },
                },
            );
            price = [];
        });
        console.log("channelsJson", channelsJson);

        try {
            data?.bulk !== true && setOpenLoader(true);
            const inventoryValues: InventoryData = {

                inv_loc_code: data.inv_loc_code as string,
                profile,
                pim_prod_id: data.pim_prod_id as string,
                psn: data.psn as string,
                ext_prod_id: data.ext_prod_id as string,
                erp_prod_id: data.erp_prod_id as string,
                product_name: data.product_name,
                sku: data.sku as string,
                img_url: data.img_url || "https://bitsofco.de/content/images/2018/12/Screenshot-2018-12-16-at-21.06.29.png",
                prod_id: psnData?.prod_id ? psnData?.prod_id : "",
                status: psnData?.status ? psnData.status : "NEW",
                invoice_no: psnData?.invoice_no ? psnData?.invoice_no : "",
                sale_invoice_no: psnData?.sale_invoice_no ? psnData?.sale_invoice_no : "",
                product_details: data.product_details,
                allowed_channels: JSON.stringify(Object.keys(channelsJson)) as string,
                profile_stock_inhand: data.profile_stock_inhand as number,
                profile_stock_res: 0,
                branch_code: data.branch_code,
                profile_fullfillment_method: JSON.stringify(selectedFullFillments),
                channels: JSON.stringify(channelsJson),
                channel_priority: JSON.stringify(channels?.map((priority) => data[`channel_priority-${priority}`])?.map((channel) => data[`allowed_channels-${channel}`])),
                row_added_by: user?.name,
                row_updated_by: user?.name,
            };

            if (inventoryData?.erp_prod_id) {
                inventoryValues.branch_code = JSON.parse(defaultForInventoryCode).ext_warehouse_code;
                inventoryValues.id = inventoryData?.id;
                inventoryValues.erp_prod_id = inventoryData.erp_prod_id;
                const updateInventoryResponse: any = await inventoryService.updateInventory(inventoryValues);

                if (updateInventoryResponse.status === STATUS.SUCCESS) {
                    setOpenLoader(false);

                    navigate(`/central-inventory/inventory/${inventoryData?.profile ? inventoryData.profile : url.profile}`);
                } else if (updateInventoryResponse.status === STATUS.FAILURE) {
                    handleOnAlert(true, updateInventoryResponse.message);
                    setOpenLoader(false);
                } else {
                    setOpenLoader(false);
                }
                console.log(updateInventoryResponse);
                showNotification(updateInventoryResponse.status, updateInventoryResponse.message);

                return { apistatus: updateInventoryResponse.status, status: true };
            } else {
                const addInventoryResponse: any = await inventoryService.addInventory(inventoryValues);

                if (addInventoryResponse.status === STATUS.SUCCESS) {
                    setOpenLoader(false);

                    if (data?.bulk !== true) {
                        navigate(`/central-inventory/inventory/${inventoryData?.profile ? inventoryData.profile : url.profile}`);
                    }

                } else if (addInventoryResponse.status === STATUS.FAILURE) {
                    handleOnAlert(true, addInventoryResponse.message);
                    setOpenLoader(false);
                } else {
                    setOpenLoader(false);
                }
                data?.bulk !== true && showNotification(addInventoryResponse.status === "error" ? STATUS.FAILURE : addInventoryResponse.status, addInventoryResponse.message);

                return { apistatus: addInventoryResponse.status, status: true };
            }
        } catch (error) {
            console.log(error);
            setOpenLoader(false);
        }
    };

    // const onSubmit2 = async (data: any) => {
    //     if(data.inv_loc_code=== "Add To All"){
    //         let successcount=0;
    //         let failurecount=0;
    //         const report: { Warehouse: string; Product: string; Reason: string; }[]=[];

    //         try {
    //         setshowProgress(true);

    //         for(let i =0; i < warehouseCode.length; i++ ) {
    //             setTimeout( async() => {       
    //              const item=data;
    //             item.inv_loc_code=warehouseCode[i].code;
    //             item.branch_code=warehouseCode[i].ext_warehouse_code;
    //             item.bulk=true;
    //             const warehouseResponse= await addToWarehouse(item);

    //             while (warehouseResponse?.status !== true){
    //                 //CHECKS FOR VALUE
    //              }

    //              if(warehouseResponse.apistatus === STATUS.SUCCESS){
    //                 successcount+=1;

    //              }
    //              else{
    //                  const value={Warehouse:warehouseCode[i].code,Product:item.product_name,Reason:"Already in the Warehouse"};
    //                  report.push(value);
    //                 failurecount+=1;
    //              }

    //             setProgress(Math.round((i+1)*100/warehouseCode.length));

    //             if(i===warehouseCode.length-1){
    //                 setshowProgress(false);
    //                 setapiResponse({success:successcount,failure:failurecount,failureReport:report});
    //                 setAddToAllDialog(true);
    //             }
    //             }, 100 * i);
    //           }}
    //           catch (error) {
    //             console.log(error);

    //         }

    //         return;
    //     }
    //      addToWarehouse(data);
    // };

    // const handleDialogBoxClose=()=>{
    //      setAddToAllDialog(false);
    //     navigate(`/central-inventory/inventory/${inventoryData?.profile ? inventoryData.profile : url.profile}`);
    // };

    useEffect(() => {
        if (url.erp_prod_id !== inventoryData?.erp_prod_id || url.erp_prod_id !== "add") {
            getWarehouseCode();

            if (inventoryData?.erp_prod_id) {
                setValue("sku", inventoryData?.sku);
                setValue("erp_prod_id", inventoryData?.erp_prod_id);
                let id = 1;
                const editChannels = JSON.parse(inventoryData.channels);
                const channel_priority = JSON.parse(inventoryData?.channel_priority);
                const arr: number[] = [];

                Object.keys(editChannels).forEach((channel,) => {
                    setValue(`allowed_channels-${id}`, channel);
                    setValue(`channel_stock_inhand-${id}`, editChannels[channel]?.in_hand);
                    setValue(`channel_stock_res-${id}`, editChannels[channel]?.reserved);
                    setValue(`fullfillment-${id}`, editChannels[channel]?.fullfillment?.allowed);
                    setValue(`channel_priority-${id}`, channel_priority ? channel_priority.indexOf(channel) + 1 : "");
                    editChannels[channel]?.fullfillment?.price?.forEach((method: any) => {
                        setValue(`shipment_price-${id}-${Object.keys(method)}`, method[`${Object.keys(method)}`]);
                    });
                    arr.push(id);
                    id++;
                });
                setChannels(arr);
                setValue("img_url", inventoryData.img_url);
                setValue("product_name", inventoryData.product_name);
                setValue("product_details", inventoryData.product_details);
            } else {
                setChannels([1]);
            }
        } else {
            navigate(`/central-inventory/inventory/${inventoryData?.profile ? url.profile === "all" ? url.profile : inventoryData.profile : url.profile}`);
        }
    }, [profile]);

    useEffect(() => {
        const stck = handleOnChannelStock();
        setChannelStock(stck);
    }, [channelStock]);


    useEffect(() => {
        if (profile === "shop" && allFields?.erp_prod_id && extCode) {
            getStockApi(extCode, String(allFields?.erp_prod_id));
        } else if (profile == "drop_ship" && allFields?.erp_prod_id && extCode) {
            updateDropship(String(allFields?.erp_prod_id), extCode);
        }
    }, [extCode, allFields?.erp_prod_id, profile]);

    useEffect(() => {

        if (url[Object.keys(url)[0]] === "inventory/shop/add") {
            let id = 1;
            const channels: { [key: string]: any } = {
                Shop: { fullfillment: { allowed: ["NORMAL", "NEXT_DAY", "SAME_DAY", "TWO_HOURS"], price: [{ NORMAL: 0 }, { SAME_DAY: 0 }, { NEXT_DAY: 0 }, { TWO_HOURS: 0 }] }, in_hand: 0 },
                Online: { fullfillment: { allowed: ["NORMAL", "NEXT_DAY", "SAME_DAY", "TWO_HOURS"], price: [{ NORMAL: 0 }, { SAME_DAY: 0 }, { NEXT_DAY: 0 }, { TWO_HOURS: 0 }] }, in_hand: 0 }
            };
            const channel_priority = ["Shop", "Online"];
            const arr: number[] = [];
            Object.keys(channels).forEach((channel: string) => {
                setValue(`allowed_channels-${id}`, channel);
                setValue(`channel_stock_inhand-${id}`, channels[channel]?.in_hand);
                setValue(`channel_stock_res-${id}`, channels[channel]?.reserved);
                setValue(`fullfillment-${id}`, channels[channel]?.fullfillment.allowed);
                setValue(`channel_priority-${id}`, channel_priority ? channel_priority.indexOf(channel) + 1 : "");
                channels[channel].fullfillment.price.forEach((method: any) => {
                    setValue(`shipment_price-${id}-${Object.keys(method)}`, method[`${Object.keys(method)}`]);
                });
                arr.push(id);
                id++;
            });
            setChannels(arr);
        }
    }, [url]);

    const handleBack = () => {
        dispatch(setProductSerialActions(""));
        navigate(`/central-inventory/inventory/${inventoryData?.profile ? inventoryData.profile : url.profile}`);
    };

    return (
        <div className="inventory-add-form">
            <div className="Modal Show">
                <div className="handle-close-ic">
                    <ArrowBackIcon className="close-ic" onClick={() => handleBack()} />
                    {defaultForInventoryCode && <img src={HistoryIcon} className="history-ic" onClick={() => setDetailsDialogVisibility(true)} />}
                </div>
                <p className="title-content">Inventory</p>
                <form onSubmit={handleSubmit(onSubmit)} className="inventory-form">
                    <div className="form-top-content">
                        <div className="left-content">
                            <div className="input-with-dropdown">
                                <div className="label-filed-wrap">
                                    <label className="txt-label">Inventory Local Code  :</label>
                                    <div className="autocomplete-list">
                                        {warehouseCode && defaultForInventoryCode
                                            ? (
                                                <Autocomplete
                                                    id="inv-loc-code"
                                                    options={warehouseCode || []}
                                                    // getOptionLabel={(option: any) => option?.name}
                                                    disabled
                                                    getOptionLabel={(option: any) => (option?.name ? `${option.name} (${option.code})` : "")}
                                                    freeSolo
                                                    defaultValue={defaultForInventoryCode ? JSON.parse(defaultForInventoryCode) : {}}
                                                    {...register("inv_loc_code", InventoryFormValidation.inv_loc_code)}
                                                    onChange={(e, val) => warehouseSelection(val)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            variant="filled"
                                                            {...params}
                                                            size="small"
                                                            placeholder="Please select inv_loc_code"
                                                        />
                                                    )}
                                                />
                                            )
                                            : <></>}

                                        {defaultForInventoryCode === null
                                            ? (
                                                <Autocomplete
                                                    id="inv-loc-code"
                                                    options={[{ code: "Add To All", name: "All", ext_warehouse_code: "Add To All" }, ...warehouseCode] || []}
                                                    // getOptionLabel={(option: any) => option?.name}
                                                    getOptionLabel={(option: any) => (option?.name ? `${option.name} (${option.code})` : "")}
                                                    freeSolo
                                                    {...register("inv_loc_code", InventoryFormValidation.inv_loc_code)}
                                                    onChange={(e, val) => warehouseSelection(val)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            variant="filled"
                                                            {...params}
                                                            size="small"
                                                            placeholder="Please select inv_loc_code"
                                                        />
                                                    )}
                                                />
                                            )
                                            : <></>}
                                    </div>

                                </div>
                                {errors.inv_loc_code?.type ? (
                                    <p className="error-text-wrap">
                                        *
                                        {" "}
                                        {errors.inv_loc_code ? errors.inv_loc_code.message : ""}
                                    </p>
                                ) : ""}
                            </div>
                            <div className="input-with-dropdown">
                                <div className="label-filed-wrap">
                                    <label className="txt-label">Product Name  :</label>
                                    <div className="autocomplete-list">
                                        <Autocomplete
                                            id="product-name"
                                            options={products || []}
                                            getOptionLabel={(option: any) => option.name || ""}
                                            freeSolo
                                            defaultValue={inventoryData?.product_details ? JSON.parse(inventoryData?.product_details) : ""}
                                            {...register("product_name")}
                                            onChange={(e, val) => productSelection(val)}
                                            renderInput={(params) => (
                                                <TextField
                                                    variant="filled"
                                                    onChange={(e) => getProductSearch(e.target.value)}
                                                    {...params}
                                                    size="small"
                                                    placeholder="Product Name"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <InputFieldWithLabels
                                register={register("pim_prod_id")}
                                text="Pim Product Id"
                                defaultValue={inventoryData?.pim_prod_id}
                            />

                            <InputFieldWithLabels
                                register={register("psn")}
                                text="Poorvika Serial Number"
                                defaultValue={inventoryData?.psn}
                            />
                            <InputFieldWithLabels
                                register={register("ext_prod_id")}
                                text="External Product Id"
                                defaultValue={inventoryData?.ext_prod_id}
                            />
                        </div>
                        <div className="right-content">
                            <InputFieldWithLabels
                                register={inventoryData?.erp_prod_id ? register("erp_prod_id") : register("erp_prod_id", InventoryFormValidation.erp_prod_id)}
                                error={errors.erp_prod_id?.type !== undefined}
                                errorText={errors.erp_prod_id ? errors.erp_prod_id.message : ""}
                                text="ERP Product ID"
                                defaultValue={inventoryData?.erp_prod_id}
                                disabled
                            />
                            <InputFieldWithLabels
                                register={register("sku")}
                                text="SKU code"
                                defaultValue={inventoryData?.sku}
                                disabled
                            />
                            <div className="stock-container">
                                <InputFieldWithLabels
                                    type="number"
                                    register={register("profile_stock_inhand", InventoryFormValidation.profile_stock_inhand)}
                                    error={errors.profile_stock_inhand?.type !== undefined}
                                    errorText={errors.profile_stock_inhand ? errors.profile_stock_inhand.message : ""}
                                    text="Profile Stock In Hand"
                                    defaultValue={inventoryData?.profile_stock_inhand}
                                    min={0}
                                />
                                {/* <span className="icon"><IconButton onClick={() => handleOnProductStockNumber()}><MoreHorizIcon /></IconButton></span> */}
                            </div>

                            <div className="checkbox-wrapper" style={{ marginBottom: "6rem" }}>
                                <label className="checkbox-label">Profile Fullfilment Method :</label>
                                <span className="checkbox">
                                    {fullfillmentMethod?.map((channel, index) => (
                                        <div style={{ paddingRight: "5rem" }} key={index}>
                                            <FormControlLabel
                                                control={(
                                                    <Checkbox
                                                        key={channel}
                                                        sx={{ color: "#a9a099", "&.Mui-checked": { color: "#FF8E33" } }}
                                                        onChange={(event) => fullfillmentMethodSelection(event.target.checked, channel)}
                                                        defaultChecked={inventoryData?.profile_fullfillment_method ? JSON.parse(inventoryData?.profile_fullfillment_method).some((val: any) => val === channel) : url[Object.keys(url)[0]] === "inventory/shop/add" ? APXchannels.some((val: any) => val === channel) : false}
                                                    />
                                                )}
                                                label={channel}
                                            />
                                        </div>
                                    ))}
                                </span>
                            </div>
                        </div>
                    </div>
                    <p className="title-content">Channels</p>
                    <table className="channels-table">
                        <thead>
                            <tr>
                                <th>Channels</th>
                                <th>Channel Stock limit</th>
                                <th>Channel Priority</th>
                                <th>Channel Fullfilment Method</th>
                                {getValues(`fullfillment-${1}`)?.length > 0 && <th>Price</th>}
                                <th>Action</th>
                            </tr>
                        </thead>
                        {channels?.length ? (
                            <tbody>
                                {channels?.map((channel, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="input-with-dropdown">
                                                <input autoComplete="off" {...register(`allowed_channels-${channel}`)} list={`allowed-channels${channel}`} className="channel-dropdown" />
                                                {allowedChannels?.length ? (
                                                    <datalist id={`allowed-channels${channel}`}>

                                                        {allowedChannels.filter((x: any) => !channels?.map((y) => watch(`allowed_channels-${y}`)).includes(x)).map((element: any) => <option id={`allowed-channels${channel}`} key={element} value={element} />)}
                                                    </datalist>
                                                ) : <datalist id={`allowed-channels${channel}`} />}
                                            </div>
                                        </td>
                                        <td>
                                            <InputFieldWithLabels
                                                className="channel-Stock"
                                                key={channel}
                                                type="number"
                                                defaultValue={0}
                                                register={register(`channel_stock_inhand-${channel}`, {
                                                    required: { value: true, message: "Channel Stock Reserveration field is empty" },
                                                    validate: (v) => v <= Number(watch("profile_stock_inhand"))
                                                        || "Channel Inhand should be less than Profile Inhand stock",
                                                })}
                                                onKeyUp={() => handleOnChannelStock()}
                                                error={errors[`channel_stock_inhand-${channel}`]?.type !== undefined}
                                                errorText={(errors[`channel_stock_inhand-${channel}`] ? errors[`channel_stock_inhand-${channel}`].message : "")}
                                                text=""
                                                min={0}
                                            />
                                        </td>
                                        <td>
                                            <div className="input-with-dropdown">
                                                <input autoComplete="off" {...register(`channel_priority-${channel}`)} list={`channel_priority${channel}`} className="channel-dropdown" />
                                                {channels.length ? (
                                                    <datalist id={`channel_priority${channel}`}>

                                                        {channels.filter((x) => !channels?.map((y) => Number(watch(`channel_priority-${y}`))).includes(x)).map((element: number) => <option id={`channel_priority${channel}`} key={element} value={element} />)}
                                                    </datalist>
                                                ) : <datalist id={`channel_priority${channel}`} />}
                                            </div>
                                        </td>
                                        <td style={{ paddingTop: "unset" }}>
                                            <div className="autoComplete-dropdown">
                                                <Autocomplete
                                                    multiple
                                                    options={fullfillmentMethod}
                                                    {...register(
                                                        `fullfillment-${channel}`,
                                                        {
                                                            required: { value: true, message: "Channel Fullfilment Method cant't be empty" },
                                                        }
                                                    )}
                                                    limitTags={3}
                                                    id={`fullfillment-${channel}`}
                                                    defaultValue={inventoryData?.channels ? JSON.parse(inventoryData?.channels)[watch(`allowed_channels-${channel}`)]?.fullfillment?.allowed : url[Object.keys(url)[0]] === "inventory/shop/add" ? APXchannels : []}
                                                    getOptionLabel={(option) => option}
                                                    onChange={(event, value: any) => setValue(`fullfillment-${channel}`, value)}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li id={`fullfillment-${channel}`} {...props}>
                                                            <Checkbox
                                                                icon={icon}
                                                                checkedIcon={checkedIcon}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                                id={`fullfillment-${channel}`}
                                                            />
                                                            <p id={`fullfillment-${channel}`}>
                                                                {" "}
                                                                {option}
                                                            </p>
                                                        </li>
                                                    )}
                                                    style={{ width: 300 }}
                                                    renderInput={(params) => (
                                                        <TextField className="txt-field-wrap" {...params} />
                                                    )}
                                                />
                                                {errors[`fullfillment-${channel}`]?.type !== undefined && <p className="error-text-wrap">* Channel Fullfilment Method cannot be empty</p>}
                                            </div>
                                        </td>
                                        {getValues(`fullfillment-${channel}`) && getValues(`fullfillment-${channel}`).length > 0 ? (
                                            <td>
                                                {getValues(`fullfillment-${channel}`).map((channels: any) => (
                                                    <InputFieldWithLabels
                                                        className="shipment-Price"
                                                        key={channels}
                                                        type="number"
                                                        defaultValue={0}
                                                        register={register(`shipment_price-${channel}-${channels}`, {
                                                            required: { value: true, message: "Channel Shipment Price field is empty" },
                                                        })}
                                                        error={errors[`shipment_price-${channel}-${channels}`]?.type !== undefined}
                                                        errorText={(errors[`shipment_price-${channel}-${channels}`] ? errors[`shipment_price-${channel}-${channels}`].message : "")}
                                                        text={channels}
                                                        min={0}
                                                    />
                                                ))}
                                            </td>
                                        ) : <></>}
                                        <td className="action-wrapper " style={{ padding: "10px 20px" }}>
                                            <IconButton>
                                                <CancelIcon onClick={() => setChannels(channels.filter((x) => x !== channel))} />
                                            </IconButton>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )
                            : (
                                <tbody>
                                    <tr>
                                        <td className="empty-data" colSpan={12}>
                                            Add Channels
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        <tbody>
                            <tr>
                                <td>
                                    {channels?.length < 4
                                        ? <p style={{ float: "left" }} className="add-channels-text-wrap" onClick={() => setChannels([...channels, (channels?.length + 1)])}>+ Add Channels</p>
                                        : <></>}
                                </td>
                                <td colSpan={5}>
                                    <p style={{ float: "right" }} className="add-channels-text-wrap">
                                        No of Stocks left:
                                        {" "}
                                        {Number(watch("profile_stock_inhand")) - channelStock}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="form-btn-wrap">
                        <ActionButton label={inventoryData?.erp_prod_id ? "Update" : "Add"} type="submit" />
                        <ActionButton color="outlined" label="Cancel" type="button" onClick={() => handleBack()} />
                    </div>
                </form>
            </div>
            {/* {showProgress && <ProgressBar open={showProgress} progress={progress} />}; */}
            {/* {addToAllDialog && <AddToAll open={addToAllDialog} onClose={handleDialogBoxClose} title="Summary" result={apiResponse} slide="down" />} */}
            {openPsn && <ProductSerialAddEditForm show={openPsn} response={(e: any) => setPsnData(e)} handleOnClose={() => { setOpenPsn(false); }} isAdd productData={psnData} inventoryData={allFields} />}
            {alert && <AlertBox open={alert} onClick={() => setAlert(false)} message={errMsg} title="Error" btnName="OK" />}
            {openLoader && <Loader open={openLoader} />}
            <Dialog
                fullWidth
                maxWidth="md"
                open={detailsDialogVisibility} className="details-dialog">

                <div className="close-button-container" onClick={() => setDetailsDialogVisibility(false)}>
                    <CloseRoundedIcon className="close-button" />
                </div>


                <HistoryPage inv_loc_code={inventoryData?.inv_loc_code} erp_prod_id={inventoryData?.erp_prod_id} module="INVENTORY" />
            </Dialog>
        </div>
    );
};

export default InventoryAddEditForm;
