import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

type Tprops = {
    open: boolean,
    onClose: (open: boolean) => void
    title: string
    result: any
}

const BulkDialogBox: React.FC<Tprops> = ({ open, onClose, title, result }) => {


    const JSONToCSVConvertor = (JSONData: any, ReportTitle: string, CsvHeader: string) => {
        console.log(result);
        const arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
        let CSV = "";
        let heading: any;

        if (CsvHeader === "Inventory") {
            heading = ["ERP Product ID", "Branch Code", "Stock In hand", "Profile", "EXT Product ID", "PSN", "Allowed channels", "Profile fullfillment Method", "Reason"];
        }
        else if (CsvHeader === "Warehouse") {
            heading = ["Warehouse name", "Supplier Name", "Ext Warehouse code", "Courier fullfilment method", "Return Order handling", "Fulfilment_type", "Location type", "Inventory profile", "Regional zone", "Description", "Pincode", "Address 1", "Address 2", "Area", "City", "State", "Country", "Working days", "Working_hours", "Primary contact name", "Primary contact email", "Primary contact mobile", "Escalations contact name", "Escalations Contact Email", "Escalations Contact Mobile", "GST number", "TAN number", "PAN number", "CST number", "VAT number", "Plus code", "Location link", "Reason"];
        }

        else if (CsvHeader === "Add to all") {
            heading = ["inv_loc_code", "product_name", "reason"];
        }
        else if (CsvHeader === "Bulk Add to all") {
            heading = ["ERP Product ID","Branch Code", "ERP Product ID","Profile", "Reason"];
        }

        let row = "";

        for (const index in heading) {
            row += heading[index] + ",";
        }
        row = row.slice(0, -1);
        CSV += row + "\r\n";


        for (let i = 0; i < arrData.length; i++) {
            let row = "";

            for (const index in heading) {
                row += arrData[i][heading[index]] ? ("\"" + arrData[i][heading[index]].replace(/["]/g, "\"\"") + "\",") : ",";
            }
            row.slice(0, row.length - 1);
            CSV += row + "\r\n";
        }

        if (CSV === "") {
            alert("Invalid data");

            return;
        }

        let fileName = "Failed_";
        fileName += ReportTitle.replace(/ /g, "_");
        const uri = "data:text/csv;charset=utf-8," + escape(CSV);
        const link = document.createElement("a");
        link.href = uri;
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={() => onClose(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{ color: "#ff8e33" }} >{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={{ color: "#090a0c" }}>
                        Success : {result?.data.success}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-slide-description" style={{ color: "#090a0c" }}>
                        Failed : {result?.data.failure}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {result.data?.failure > 0 && <Button onClick={() => JSONToCSVConvertor(result.data?.failureReport, "Report", result?.CsvHeader ? result?.CsvHeader : result?.data?.CsvHeader)} >View Report</Button>}
                    <Button onClick={() => onClose(false)}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default BulkDialogBox;