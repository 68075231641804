import { STATUS } from "common/constant";
import ActionButton from "components/ActionButton";
import React, { useEffect, useState } from "react";
import { AuthService } from "services/auth.service";
import { AddSupplier } from "../SupplierModel";
import "./styles.scss";

export const SupplierList: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [userList, setUserList] = useState<any>([]);
    const [formState, setFormState] = useState<any>("");
    const authService = new AuthService();

    const fetchUsers = async () => {
        try {
            const suppliers = await authService.allSuppliers();

            if (suppliers?.status === STATUS.SUCCESS) {
                setUserList(suppliers.data);
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchUsers();
    }, []);

    const handleOnAdd = () => {
        setOpen(true);
        setFormState("");
    };

    const handleOnUpdate = (userData: any) => {
        setOpen(true);
        setFormState(userData);
    };


    return (
        <div className="suppliers-list-page">
            <div className='inventory-table'>
                <div className='table-wrapper'>
                    <div className='btn-wrapper'>
                        <ActionButton label="Add Suppliers" onClick={() => handleOnAdd()} />
                    </div>
                    <div className='table-container'>
                        <table className="inventory-list-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>User ID</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            {userList?.length ? (
                                <tbody>
                                    {userList.map((userData: any, index: number) => {
                                        return (
                                            <tr className="inventory-list-tbody" key={index}>
                                                <td>{index + 1}</td>
                                                <td>{userData.name}</td>
                                                <td>{userData.email}</td>
                                                <td>{userData.role}</td>
                                                <td>{userData.code}</td>
                                                <td>
                                                    <ActionButton label="Update Role" onClick={() => handleOnUpdate(userData)} />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td className="empty-data" colSpan={12}>
                                            No data to display
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
            {open && <AddSupplier formState={formState} open={open} setOpen={setOpen} fetchUsers={() => fetchUsers()} />}
        </div>
    );

};