import { AxiosError } from "axios";
import { parseAxiosError } from "common/constant";
import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interfaces/ApiResponse.interface";

export class ApiKeyService extends BaseService {
    public async addApiKey(reqData: any): Promise<ISuccessResponse | IErrorResponse> {
        try {

            const { data } = await this.httpClient.post("/apikey/", reqData);

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async updateApiKey(reqData: any): Promise<ISuccessResponse | IErrorResponse> {
        try {

            const { data } = await this.httpClient.put("/apikey/", reqData);

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async getApiKey(): Promise<ISuccessResponse | IErrorResponse> {
        try {

            const { data } = await this.httpClient.get("/apikey/");

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

}