import { Routes, Route, Navigate, NavLink } from "react-router-dom";
import { profile } from "common/constant";

import { InventoryTable } from "./InventoryTable";
import "./styles.scss";



export const InventoryManagement: React.FC= () => {


    return (
        <div className="location-inventory-page">
            <div className="inventory-management-page">
                <div className="Inventory-table-layout">
                    <div className="Inventory-table-header">
                        {profile.map((profile_name) => (
                            <div className="listed-products-selector" key={profile_name}>
                                <NavLink key={profile_name} className="inventorys-selector" to={profile_name}>
                                    <p style={{textTransform:"capitalize"}}>{profile_name ==="shop"?"APX/Shop":profile_name.replaceAll("_","")}</p>
                                </NavLink>
                                <span>|</span>
                            </div>
                        ))}
                    </div>
                    <div className="pd-container">
                        <Routes>
                            <Route path="/" element={<Navigate replace to="/central-inventory/inventory-location/inventory-management/warehouse" />} />
                            <Route element={<InventoryTable  />} path="/:profile" />
                        </Routes>
                    </div>
                </div>
            </div>

        </div>
    );
};

