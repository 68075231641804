import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./styles.scss";
import { Pagination } from "components/Pagination";
import { InventoryService } from "services/inventory.service";



export const InventoryTable: React.FC = () => {
    const url = useParams();
    const params = url.profile;


    const inventoryService: InventoryService = new InventoryService();

    const [inventoryList, setInventoryList] = useState<any>();
    const [pageNo, setPageNo] = useState(1);
    const [totalPage, setTotalPage] = useState<any>();


    const getInventoryList = async () => {
        try {
            const sortingData = {
                profile: params,
                field: "product_name",
                order: "ASC",
                productSearch: "",
                limit: 10,
                page: pageNo,
            };
            const inventoryResponse: any = await inventoryService.getInventory(sortingData);

            if (inventoryResponse.status === "success") {
                setInventoryList(inventoryResponse.data.inventoryData);
                setTotalPage(inventoryResponse?.data.totalRecords);
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setPageNo(1);
        getInventoryList();
    }, [params]);


    useEffect(() => {
        getInventoryList();
    }, [pageNo]);


    return (
        <div className="Inventory-table-body">
            <table className="inventory-manaagement-table">
                <thead>
                    <tr>

                        <th>
                            Product
                            {/* <FontAwesomeIcon
                                className="sort-icon"
                                icon={sortColumn !== 'name' ? faSort : sortingType === 'DESC' ? faSortDown : faSortUp}
                                onClick={() => sortingFunctionalityHandler('name', sortingType)}
                            /> */}
                        </th>
                        <th>
                            SKU Code
                            {/* <FontAwesomeIcon
                                className="sort-icon"
                                icon={sortColumn !== 'sku' ? faSort : sortingType === 'DESC' ? faSortDown : faSortUp}
                                onClick={() => sortingFunctionalityHandler('sku', sortingType)}
                            /> */}
                        </th>
                        <th>
                            ERP Product ID
                            {/* <FontAwesomeIcon
                                className="sort-icon"
                                icon={sortColumn !== 'erp_item_code' ? faSort : sortingType === 'DESC' ? faSortDown : faSortUp}
                                onClick={() => sortingFunctionalityHandler('erp_item_code', sortingType)}
                            /> */}
                        </th>
                        <th>
                            External Product Id
                            {/* <FontAwesomeIcon
                                className="sort-icon"
                                icon={sortColumn !== 'ext_prod_id' ? faSort : sortingType === 'DESC' ? faSortDown : faSortUp}
                                onClick={() => sortingFunctionalityHandler('ext_prod_id', sortingType)}
                            /> */}
                        </th>
                        <th>
                            PSN
                            {/* <FontAwesomeIcon
                                className="sort-icon"
                                icon={sortColumn !== 'psn' ? faSort : sortingType === 'DESC' ? faSortDown : faSortUp}
                                onClick={() => sortingFunctionalityHandler('psn', sortingType)}
                            /> */}
                        </th>
                        <th >Stock in Hand
                            {/* <FontAwesomeIcon
                                className="sort-icon"
                                icon={sortColumn !== 'qty_on_hand' ? faSort : sortingType === 'DESC' ? faSortDown : faSortUp}
                                onClick={() => sortingFunctionalityHandler('qty_on_hand', sortingType)}
                            /> */}
                        </th>

                        <th >Warehouse Code
                            {/* <FontAwesomeIcon
                                className="sort-icon"
                                icon={sortColumn !== 'warehouse_name' ? faSort : sortingType === 'DESC' ? faSortDown : faSortUp}
                                onClick={() => sortingFunctionalityHandler('warehouse_name', sortingType)}
                            /> */}
                        </th>
                    </tr>

                </thead>
                {inventoryList ?
                    <tbody>
                        {inventoryList.map((item: any) => {
                            return (

                                <tr key={item.erp_item_code}>
                                    <td>
                                        <div className="product-col">
                                            <div className="pimg-wrap">
                                                {item.img_url ? <img className="product-img" alt='product-img' src={item.img_url} />
                                                    : <p>Img not found</p>}
                                            </div>
                                            <div className="pcontent-wrap">
                                                {item.product_name ? item.product_name : "-"}
                                            </div>

                                        </div>
                                    </td>
                                    <td>
                                        {item.sku ? item.sku : "-"}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {item.erp_prod_id ? item.erp_prod_id : "-"}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {item.ext_prod_id ? item.ext_prod_id : "-"}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {item.psn ? item.psn : "-"}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {item.profile_stock_inhand ? item.profile_stock_inhand : "-"}
                                    </td>

                                    <td>
                                        {item.inv_loc_code ? item.inv_loc_code : "-"}
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                    : <tbody >
                        <tr>
                            <td className="empty-data" colSpan={12}>
                                No data to display
                            </td>
                        </tr>
                    </tbody>}

            </table>
            <div className="all-products-pagination">
                <Pagination
                    count={Math.ceil(totalPage / 10)}
                    page={pageNo}
                    pageChange={(e, page: number) => setPageNo(page)}
                />
            </div>
        </div>
    );
};