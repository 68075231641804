import * as React from "react";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { BajajService } from "services/bajaj.service";
import { showNotification, STATUS } from "common/constant";
import "./styles.scss";
import { PIMService } from "services/pimapi.service";

type Tprops = {
    slide: "up" | "down" | "left" | "right",
    open: boolean,
    onClose: () => void
    title: string
    profile: string
    element: any
}

const BajajSellerIDDialogBox: React.FC<Tprops> = ({ open, onClose, title, profile, element }) => {

    const [products, setProducts] = useState<any>();
    const [detail, setDetail] = useState<any>();
    const bajajService = new BajajService();
    const pimService = new PIMService();


    const getSearch = async (search: any) => {
        try {
            if (profile === "SKU") {

                if (search.length>1) {
                     const productSearchResponse = await pimService.getPim(search);

                    if (productSearchResponse) {
                        const duplicateArr: any = [];
                        const arr = productSearchResponse.data?.filter((element: any) => {
                            const find = duplicateArr.find((x: any) => x.name === element.name);

                            if (!find) {
                                duplicateArr.push(element);

                                return element;
                            }

                        });
                        setProducts(arr);
                    }
                }
                else {
                    setProducts([]);
                }
            }

            else if (profile === "Seller_ID") {
                const warehouseCodeResponse = await bajajService.getWarehouse();

                if (warehouseCodeResponse) {
                    setProducts(warehouseCodeResponse?.data);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateDetail = async () => {
        if (profile === "SKU") {
            const updatePimSku = await bajajService.updatePimSku({ data: detail, sku_id: element.sku });

            if (updatePimSku.status === STATUS.SUCCESS) {
                showNotification(STATUS.SUCCESS, "Updated Successfully");
                onClose();
            }
            else {
                showNotification(STATUS.FAILURE, "Something went Wrong");
            }
        }
        else if (profile === "Seller_ID") {
            const updatePimSku = await bajajService.updateInvLocCode({ data: detail, supplier_id: element.supplier_id });

            if (updatePimSku.status === STATUS.SUCCESS) {
                showNotification(STATUS.SUCCESS, "Updated Successfully");
                onClose();
            }
            else {
                showNotification(STATUS.FAILURE, "Something went Wrong");
            }
        }

    };


    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={() => onClose()}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="dialog-title" >{title.replace("_", " ")}</DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-slide-description" className="dialog-box" >
                        <div className="container">
                            <p>{profile === "SKU" ? element.sku : profile === "Seller_ID" ? element.supplier_desc : ""}</p>
                            <Autocomplete
                                style={{ width: "100%" }}
                                id="product-name"
                                options={products || []}
                                getOptionLabel={(option: any) => profile === "SKU" ? option.name : profile === "Seller_ID" ? `${option.name} (${option.code})` : "" || ""}
                                freeSolo
                                onChange={(e, val) => {
                                    setDetail(val);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        variant="filled"
                                        onChange={(e) => getSearch(e.target.value)}
                                        {...params}
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => detail && updateDetail()}>Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default BajajSellerIDDialogBox;