import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { BASE_URL, PIM_API, PINCODE_API, GST_API, STOCK_INFO, showNotification } from "common/constant";

export class BaseService {
    httpClient: AxiosInstance = axios.create({ baseURL: BASE_URL });
    pimApi: AxiosInstance = axios.create({ baseURL: PIM_API });
    pincodeApi: AxiosInstance = axios.create({ baseURL: PINCODE_API });
    gstApi: AxiosInstance = axios.create({ baseURL: GST_API });
    getStockInfo: AxiosInstance = axios.create({ baseURL: STOCK_INFO });

    constructor() {

        // This interceptor is used for sending token in headers for Authorization.

        this.httpClient.interceptors.request.use((request: AxiosRequestConfig) => {
            if (!request.headers?.authorization) {
                request.headers = { Authorization: String(localStorage.getItem("accessToken")), ...request.headers };
            }
            
            return request;
        });

        this.httpClient.interceptors.response.use((response: AxiosResponse) => {
            return response;
        }, (error: any) => {

            const err = Promise.reject(error);

            if (error.response.status === 401) {
                this.autoLogout();
            }

            return err;

        });
    }

    async autoLogout(): Promise<void> {
        localStorage.clear();
        showNotification("failure", "Session Expired, Kindly login again");
        window.open(window.location.origin, "_self");
    }
}
