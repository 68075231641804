import ActionButton from "components/ActionButton";
import "./styles.scss";

type Tprops = {
    imgUrl: string,
    onClick: () => void,
    productName: string
}

export const ProductCard: React.FC<Tprops> = ({ imgUrl, onClick, productName }) => {

    return (
        <div className='productDetails-cards'>
            <div>
                <div className='banner' >
                    <img src={imgUrl} alt={productName ? productName : "Image not found"} draggable="false" />
                </div>
                <div className='card-description'>
                    <div className="product-name">
                        {productName}
                    </div>
                    <div className="view-btn">
                        <ActionButton label="View" onClick={() => onClick()} />
                    </div>
                </div>
            </div>
        </div>

    );
};