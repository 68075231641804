import { referenceData2, showNotification, STATUS } from "common/constant";
import ActionButton from "components/ActionButton";
import { SearchBox } from "components/SearchBox";
import { useState } from "react";
import { Routes, Route, NavLink, useParams } from "react-router-dom";
import { BajajService } from "services/bajaj.service";
import { BajajInfoPage } from "./ReferenceTable/BajajInfoPage";
import { BajajSellerIDPage } from "./ReferenceTable/BajajSellerIDPage";
import { BajajSkuPage } from "./ReferenceTable/BajajSkuPage";
import "./styles.scss";
import { Loader } from "../../../../components/Loader/index";




export const ReferenceChannel: React.FC = () => {
    const uri = useParams();
    const profile = uri["referenceData"] ? uri["referenceData"]?.toString() : "Bajaj";
    const [searchData, setSearchData] = useState<string>("");
    const bajajService: BajajService = new BajajService();
    const [loader, setLoader] = useState<boolean>(false);

    const handleSearch = (search: string) => {
        setSearchData(search);
    };

    const handleSync = async () => {
        setLoader(true);
        const response = await bajajService.Sync();

        if (response.status === STATUS.SUCCESS) {
            setLoader(false);
            showNotification(response.status, "Sync Successfull");
        }
        else {
            setLoader(false);
            showNotification(response.status, "Sync Failed");
        }

    };

    const handleUpdate = async () => {
        console.log(uri["*"]);

        if (uri["*"]) {
            setLoader(true);
            const response = await bajajService.update(`bajaj/${uri["*"].toLowerCase()}`);

            if (response.status === STATUS.SUCCESS) {
                setLoader(false);
                showNotification(response.status, "Update Successfull");
            }
            else {
                setLoader(false);
                showNotification(response.status, "Update Failed");
            }
        }
    };

    return (
        <div className="Sub-Reference-management-page">
            <Loader open={loader} />
            <div className="Sub-Reference-table-layout">
                <div className="sub-Reference-table-header">
                    <div className="sub-Reference-table-header-options">
                        <div className="search-bar">
                            <SearchBox
                                value={searchData}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
                                placeholder="Search"
                            />

                        </div>
                        {uri["*"] && uri["*"].split("/")[0] === "SKU" && <ActionButton label="Update Stock" onClick={() => handleSync()} />}
                    </div>
                    <div className="sub-Reference-table-header-options">
                        <div className="sub-Reference-table-options">
                            {referenceData2[profile]?.map((profile_name: any) => (
                                <div className="listed-products-selector" key={profile_name} onClick={() => setSearchData("")}>
                                    <NavLink key={profile_name} className="inventorys-selector" to={profile_name}>
                                        <div className="header-btn">
                                            <div className="header-label">{profile_name.replaceAll("_", "")}</div>
                                        </div>
                                    </NavLink>
                                </div>
                            ))}
                        </div>
                        {uri["*"] && uri["*"].split("/")[0] !== "History" && <div className="action-btn">
                            <ActionButton label={`Sync ${uri["*"] ? uri["*"].split("/")[0].replaceAll("_", " ") : ""}`} onClick={() => handleUpdate()} />
                        </div>}
                    </div>
                </div>
                <div className="pd-container">
                    <Routes>
                        <Route element={<BajajSellerIDPage search={searchData} />} path="/Seller_ID" />
                        <Route element={<BajajSkuPage search={searchData} />} path="/SKU" />
                        <Route element={<BajajInfoPage search={searchData} />} path="/History" />
                    </Routes>
                </div>
            </div>
        </div>
    );
};