import { createContext, useContext } from "react";
import io from "socket.io-client";
import { SOCKET_BASE_URL } from "../common/constant";

// const socket = io(SOCKET_BASE_URL, {
//     transports: ["websocket"],
//     path:"/api/socket.io"
// });
const socket = io(SOCKET_BASE_URL, {
    transports: ["websocket"],
    path:"/socket.io"
});

const SocketContext = createContext({ socket });

function SocketProvider(props: any) {

    return (
        <SocketContext.Provider value={{ socket }} {...props} />
    );
}

export const useSocket = () => useContext(SocketContext);

export default SocketProvider;