import { Backdrop } from "@mui/material";

export const IntraTransferReturnPage: React.FC = () => {

    return (
        <div>
            <Backdrop open>
                <div style={{ color: "#fff" }}>
                    Coming Soon. . .
                </div>
            </Backdrop>
        </div>
    );
};