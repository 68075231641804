import ActionButton from "components/ActionButton";




export const ConfigSettingsPage: React.FC = () => {


    const JSONToCSVConvertor = (CsvHeader: string) => {


        let CSV = "";
        let heading: any;

        if (CsvHeader === "Inventory") {
            heading = ["ERP Product ID", "Branch Code", "Stock In hand", "Profile", "EXT Product ID", "PSN", "Allowed channels", "Profile fullfillment Method"];
        }
        else if (CsvHeader === "Warehouse") {
            heading = ["Warehouse name", "Supplier Name", "Ext Warehouse code", "Courier fullfilment method", "Return Order handling", "Fulfilment_type", "Location type", "Inventory profile", "Regional zone", "Description", "Pincode", "Address 1", "Address 2", "Area", "City", "State", "Country", "Working days", "Working_hours", "Primary contact name", "Primary contact email", "Primary contact mobile", "Escalations contact name", "Escalations Contact Email", "Escalations Contact Mobile", "GST number", "TAN number", "PAN number", "CST number", "VAT number","Lat","Lon"];
        }
        else if (CsvHeader === "WarehouseSample") {
            heading = [["Warehouse name", "Supplier Name", "Ext Warehouse code", "Courier fullfilment method", "Return Order handling", "Fulfilment_type", "Location type", "Inventory profile", "Regional zone", "Description", "Pincode", "Address 1", "Address 2", "Area", "City", "State", "Country", "Working days", "Working_hours", "Primary contact name", "Primary contact email", "Primary contact mobile", "Escalations contact name", "Escalations Contact Email", "Escalations Contact Mobile", "GST number", "TAN number", "PAN number", "CST number", "VAT number","Lat","Lon"],
            ["MH-PANVEL","Poorvika","PANV",["Next Day","Same Day","Regular","2 HRS","Pickup at Store"],"1",["Fulfilled by Poorvika"],"City","Store","A","Sentence","629165","No.6-25/2/1, Survey no.D12/8, Nallur Village Ward No.21", "Vilavancode Taluk, Kuzhithurai Municipality","Kanyakumari (DT), Marthandam Main Road, Marthandam - 629165","Kanyakumari","Tamil Nadu","India",["Monday","Tuesday","Wednesday","Thursday","Friday"],{"Friday":{"end":"18:00:00","start":"10:00:00"},"Monday":{"end":"18:00:00","start":"10:00:00"},"Thursday":{"end":"18:00:00","start":"10:00:00"},"Tuesday":{"end":"18:00:00","start":"10:00:00"},"Wednesday":{"end":"18:00:00","start":"10:00:00"}},"test","tester@gmail.com","1234567890","test2","tester2@gmail.com","1234567891","33AIXPH4990F1ZU","PDES03028F","FPFPP0606L","29750311018","FR02432610426","8.17455766","77.42924099"]];
        }

        if(CsvHeader === "Inventory" || CsvHeader === "Warehouse"){    
        let row = "";

        for (const index in heading) {
            row += heading[index] + ",";
        }
        row = row.slice(0, -1);
        CSV += row + "\r\n";
        }

        else if(CsvHeader === "WarehouseSample"){
            let row = "";

            for (const index in heading[0]) {
                row += heading[0][index].toString() + ",";
            }
            row = row.slice(0, -1);
            CSV += row + "\r\n"; 
            row = "";

            for (const index in heading[1]) {
                //row += "\"" +heading[1][index].toString().replace("@m@", "[").replace("@w@","]")+ " \""+ ",";
                row +="\"" + JSON.stringify(heading[1][index]).replace(/"/g,"")+ " \""+ ",";
                console.log("row",row,JSON.stringify(heading[1][index]));
            }
            row = row.slice(0, -1);
            CSV += row + "\r\n"; 
            console.log("csv",CSV);
        }

        const fileName = `${CsvHeader}-templete`;
        const uri = "data:text/csv;charset=utf-8," + escape(CSV);
        const link = document.createElement("a");
        link.href = uri;
        link.download = fileName + ".csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



    return (
        <div className="suppliers-list-page">
            <div className='inventory-table'>
                <div className='table-wrapper'>
                    <div className='table-container'>
                        <table className="inventory-list-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Templetes</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Warehouse</td>
                                    <td>
                                        <ActionButton label="Download" onClick={() => JSONToCSVConvertor("Warehouse")} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td> Inventory</td>
                                    <td>
                                        <ActionButton label="Download" onClick={() => JSONToCSVConvertor("Inventory")} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );

};