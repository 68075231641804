/* eslint-disable no-param-reassign */
import { AxiosError } from "axios";
import { parseAxiosError } from "common/constant";
import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interfaces/ApiResponse.interface";
import { InventoryData } from "./interfaces/inventory.interface";
import { API_ENDPOINTS } from "common/constant";
export class InventoryService extends BaseService {

    public async addInventory(inventoryInformation: InventoryData): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("inventory/addInventory", inventoryInformation);

            return data;
        } catch (error: any) {
            //const err = parseAxiosError(error as AxiosError);

            return error.response.data;
        }
    }

    public async updateInventory(inventoryInformation: InventoryData): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.put("inventory/updateInventory", inventoryInformation);

            return data;
        } catch (error: any) {
            return error.error.response.data;
        }
    }


    public async getInventory(values: any): Promise<ISuccessResponse | IErrorResponse> {

        try {
            const { data } = await this.httpClient.get(`inventory/inventory-list/${values?.profile}/${values?.field}/${values?.order}/${values?.limit ? values?.limit : 10}/${values?.page}?productSearch=${encodeURIComponent(values?.productSearch)}`);

            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async deletewarehouse(deleteData: any) {

        try {
            const { data } = await this.httpClient.delete(`inventory/deleteInventory/${deleteData.profile}`,{data:{id:deleteData.id}});

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async getWarehouseCode() {

        try {
            const { data } = await this.httpClient.get("inventory/getWarehousecode");

            return data;
        }
        catch (error) {
            return parseAxiosError(error as AxiosError);

        }
    }

    public async multipleSearchProduct(status: string, pageNo: number, searchInput: any, orderbyValues?: string, sortType?: string): Promise<ISuccessResponse | IErrorResponse> {
        const Seller_code = localStorage.getItem("seller_code");
        sortType = sortType ? sortType : "";
        orderbyValues = orderbyValues ? orderbyValues : "";

        try {
            if (orderbyValues) {
                const { data } = await this.httpClient.get(API_ENDPOINTS.WAREHOUSE + "inventory/list/" + `${Seller_code}/${status}/${pageNo}?erp_item_code=&ext_prod_id=&sku=&qty_on_hand=&warehouse_name=&orderby=${orderbyValues}&sort=${sortType}`);

                return data;
            }

            const { data } = await this.httpClient.get(API_ENDPOINTS.WAREHOUSE + "inventory/list/" + `${Seller_code}/${status}/${pageNo}?erp_item_code=${searchInput?.erp_item_code}&ext_prod_id=${searchInput?.ext_prod_id}&sku=${searchInput?.sku}&psn=${searchInput?.psn}&warehouse_name=${searchInput?.warehouse_name}&min_stock=${searchInput?.minStock}&max_stock=${searchInput?.maxStock}&orderby=${orderbyValues}&sort=`);

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async getInventorylist(listingStatus: string, pageNo: number) {

        const seller_code = localStorage.getItem("seller_code");

        try {
            const { data } = await this.httpClient.get(API_ENDPOINTS.WAREHOUSE + "inventory/list/" + seller_code + "/" + listingStatus + "/" + pageNo);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async bulkUpload(file: any) {
        try {
            const { data } = await this.httpClient.post("inventory/bulkupload", file);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async getSearchInventory(search: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get(`inventory/commonSearch?search=${search}`);

            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async getInventoryColumn(userEmail: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get(`inventory/inventorycolumn?userEmail=${userEmail}`);

            return data;
        }
        catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async setInventoryColumn(value: { email: string, data: Array<any> }): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.put("inventory/inventorycolumn", value);

            return data;
        }
        catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async updateDropship(expCode: string): Promise<any | any> {

        try {
            const { data } = await this.httpClient.get(`https://e2eapi-4jfuxn4p5q-el.a.run.app/inventory/${expCode}/updatedQty`);

            return data;
        }
        catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async updateStocks(table: string): Promise<any | any> {

        try {
            const { data } = await this.httpClient.get(`inventory/${table}StockUpdate`);

            return data;
        }
        catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
}


