import { AxiosError } from "axios";
import { parseAxiosError } from "common/constant";
import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interfaces/ApiResponse.interface";

export class BajajService extends BaseService {
    public async getSellerIdDetails(uri:string,page:number,search:string,sort:string,sortBy:string): Promise<ISuccessResponse | IErrorResponse> {
        try {

            const { data } = await this.httpClient.post(`/bajaj/details/${uri}`,{pageNo:page,searchData:search,Sort:sort,SortBy:sortBy});

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async getSkuDetails(uri:string,page:number,search:string,sort:string,sortBy:string): Promise<ISuccessResponse | IErrorResponse> {
        try {

            const { data } = await this.httpClient.post(`/bajaj/details/${uri}`,{pageNo:page,searchData:search,Sort:sort,SortBy:sortBy});

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async update(url:string): Promise<ISuccessResponse | IErrorResponse> {
        try {

            const { data } = await this.httpClient.get(`/${url}/sync`);

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async updatePimSku(product:any): Promise<ISuccessResponse | IErrorResponse> {
        try {

            const { data } = await this.httpClient.post("/bajaj/update_pim_sku",product);

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async updateInvLocCode(product:any): Promise<ISuccessResponse | IErrorResponse> {
        try {

            const { data } = await this.httpClient.post("/bajaj/update_seller_id",product);

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async updateInvLocCodeFromWarehouse(product:any): Promise<ISuccessResponse | IErrorResponse> {
        try {

            const { data } = await this.httpClient.post("/bajaj/update_seller_id_from_warehouse",product);

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async deletePimSku(product:any): Promise<ISuccessResponse | IErrorResponse> {
        try {

            const { data } = await this.httpClient.post("/bajaj/delete_pim_sku",product);

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async deleteSellerID(product:any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("/bajaj/delete_seller_id",product);

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async Sync(): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get("/bajaj/sync_to_bajaj");

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async getHistory(): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get("/bajaj/history");

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async getProdHistory(details:any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("/bajaj/prodHistory",details);

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async getWarehouse(): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get("/bajaj/getWarehouseCode");

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async getPimSku(): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get("/bajaj/getPimSku");

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }


}