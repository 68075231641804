import { Pagination } from "components/Pagination";
import { STATUS } from "common/constant";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BajajService } from "services/bajaj.service";
import { SortingIcons } from "components/SortingArrows";
import "./styles.scss";
import moment from "moment";
import BajajSkuUpdateHistoryDialogBox from "components/BajajSkuUpdateHistoryDialogBox";
import ActionButton from "components/ActionButton";

type TProps = {
    search: string,
}

export const BajajInfoPage: React.FC<TProps> = ({ search }) => {

    const [page, setPageNo] = useState<number>(1);
    const [itemList, setItemList] = useState<Array<any>>([]);
    const [displayItemList, setDisplayItemList] = useState<Array<any>>([]);
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [prodDetails, setProdDetails] = useState<any>({});

    const [totalPage, setTotalPage] = useState<any>();
    const [searchString, setSearchString] = useState<string>(search);
    const bajajService = new BajajService();
    const uri = useParams();
    let sort = "ASC";

    const handlePageData = (data?: any) => {

        const arrayList = searchString.length ? data ? data : itemList : itemList || [];
        setTotalPage(Math.ceil(arrayList?.length / 10));
        const count = page === 1 ? 0 : (page - 1) * 10;
        setDisplayItemList([...arrayList.slice(count, count + 10)]);

    };

    const SortObject = (data: any, by: string, type: string) => {

        if (type === "ASC") {
            const sortArr = data.sort((a: any, b: any) => {
                let fa;
                let fb;

                if (by === "row_updated_dttm") {
                    fa = a[by].replaceAll("-", "").replaceAll("T", "").replaceAll(":", "").replaceAll("Z", "");
                    fb = b[by].replaceAll("-", "").replaceAll("T", "").replaceAll(":", "").replaceAll("Z", "");

                    return parseFloat(fa) - parseFloat(fb);
                }

                else if (by === "mrp" || by === "erp_prod" || by === "stock" || by === "supplier_id") {

                    return parseInt(a[by]) - parseInt(b[by]);
                }
                else {
                    fa = a[by]?.toLowerCase();
                    fb = b[by]?.toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }

                    else if (fa > fb) {
                        return 1;
                    }
                }

                return 0;
            });
            handlePageData(sortArr);
            setItemList(sortArr);
        }
        else {
            const sortArr = data.sort((a: any, b: any) => {
                let fa;
                let fb;

                if (by === "row_updated_dttm") {
                    fa = a[by].replaceAll("-", "").replaceAll("T", "").replaceAll(":", "").replaceAll("Z", "");
                    fb = b[by].replaceAll("-", "").replaceAll("T", "").replaceAll(":", "").replaceAll("Z", "");

                    return parseFloat(fb) - parseFloat(fa);
                }

                else if (by === "mrp" || by === "erp_prod" || by === "stock" || by === "supplier_id") {

                    return b[by] - a[by];
                }
                else {
                    fa = a[by]?.toLowerCase();
                    fb = b[by]?.toLowerCase();
                }

                if (fa > fb) {
                    return -1;
                }

                else if (fa < fb) {
                    return 1;
                }

                return 0;
            });
            setItemList(sortArr);
            handlePageData(sortArr);
        }
    };

    const getDetails = async () => {

        const response = await bajajService.getHistory();

        if (response.status === STATUS.SUCCESS) {
            setItemList(response.data);
        }
    };


    const handleSearch = (search: string) => {
        setSearchString(search);
        const lowerCase = search?.toLowerCase();
        const filteredData = itemList?.filter((e: any) => {
            if (lowerCase === "") {
                return e;
            }
            else {
                return e?.name?.toLowerCase()?.includes(lowerCase)
                    || e?.bajaj_sku.toString()?.toLowerCase()?.includes(lowerCase)
                    || e?.supplier_id?.toLowerCase()?.includes(lowerCase)
                    || e?.erp_prod_id.toString()?.toLowerCase()?.includes(lowerCase)
                    || e?.status.toString()?.toLowerCase()?.includes(lowerCase);
            }
        });
        handlePageData(filteredData);

    };


    useEffect(() => {
        handleSearch(search);
        document.querySelector(".Sub-inventory-manaagement-table-body")?.scrollTo(0, 0);
    }, [itemList, search, page]);

    useEffect(() => {
        setItemList([]);
        getDetails();
        setPageNo(1);
        handlePageData();
    }, [uri]);

    return (
        <div className="bajaj-info-table">
            <table className="bajaj-info-manaagement-table">
                <thead className="bajaj-info-manaagement-table-head">
                    <tr>
                        <th className="bajaj-info-table-header-name" onClick={() => { SortObject(itemList, "name", sort); }}>
                            <p className="bajaj-info-header">Name
                                <SortingIcons
                                    setSort={(e) => sort = e}
                                    fieldName="name"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        {/* <th className="bajaj-info-table-header-bajaj-sku" onClick={() => { SortObject(itemList, "bajaj_sku", sort); }}>
                            <p className="bajaj-info-header">Bajaj Sku
                                <SortingIcons
                                    setSort={(e) => sort = e}
                                    fieldName="bajaj_sku"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th> */}
                        <th className="bajaj-info-table-header-supplier-id" onClick={() => { SortObject(itemList, "supplier_id", sort); }}>
                            <p className="bajaj-info-header">Supplier ID
                                <SortingIcons
                                    setSort={(e) => sort = e}
                                    fieldName="supplier_id"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-info-table-header-erp-prod-id" onClick={() => { SortObject(itemList, "erp_prod_id", sort); }}>
                            <p className="bajaj-info-header">Pim ID
                                <SortingIcons
                                    setSort={(e) => sort = e}
                                    fieldName="erp_prod_id"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-info-table-header-stock" onClick={() => { SortObject(itemList, "stock", sort); }}>
                            <p className="bajaj-info-header">Stock
                                <SortingIcons
                                    setSort={(e) => sort = e}
                                    fieldName="stock"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-info-table-header-status" onClick={() => { SortObject(itemList, "status", sort); }}>
                            <p className="bajaj-info-header">Status
                                <SortingIcons
                                    setSort={(e) => sort = e}
                                    fieldName="status"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-info-table-header-mrp" onClick={() => { SortObject(itemList, "mrp", sort); }}>
                            <p className="bajaj-info-header">Mrp
                                <SortingIcons
                                    setSort={(e) => sort = e}
                                    fieldName="mrp"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-info-table-header-updated-on" onClick={() => { SortObject(itemList, "row_updated_dttm", sort); }}>
                            <p className="bajaj-info-header">Updated on
                                <SortingIcons
                                    setSort={(e) => sort = e}
                                    fieldName="row_updated_dttm"
                                    setFieldName={(e) => { console.log(e); }}
                                />
                            </p>
                        </th>
                        <th className="bajaj-info-table-header-action">
                            <p className="bajaj-info-header">Action
                            </p>
                        </th>
                    </tr>

                </thead>
                {displayItemList ?
                    <tbody className="bajaj-info-manaagement-table-body">
                        {displayItemList.map((element: any, index: number) => {
                            return (
                                <tr key={index} className="bajaj-info-manaagement-table-body-row">
                                    <td className="bajaj-info-body-name">
                                        <p className="table-data-item">
                                            {element["name"]}
                                        </p>
                                    </td>
                                    {/* <td className="bajaj-info-body-bajaj-sku">
                                        <p className="table-data-item">
                                            {element["bajaj_sku"]}
                                        </p>
                                    </td> */}
                                    <td className="bajaj-info-body-supplier-id">
                                        <p className="table-data-item">
                                            {element["supplier_id"]}
                                        </p>
                                    </td>
                                    <td className="bajaj-info-body-erp-prod-id">
                                        <p className="table-data-item">
                                            {element["erp_prod_id"]}
                                        </p>
                                    </td>
                                    <td className="bajaj-info-body-stock">
                                        <p className="table-data-item">
                                            {element["stock"]}
                                        </p>
                                    </td>
                                    <td className="bajaj-info-body-status">
                                        <p className="table-data-item">
                                            {element["status"]}
                                        </p>
                                    </td>
                                    <td className="bajaj-info-body-mrp">
                                        <p className="table-data-item">
                                            {element["mrp"]}
                                        </p>
                                    </td>
                                    <td className="bajaj-info-body-updated-on">
                                        <p className="table-data-item">
                                            {moment(new Date(element["row_updated_dttm"])).format("DD-MMM-YYYY hh:mm A")}
                                        </p>
                                    </td>
                                    <td className="bajaj-info-body-action">
                                        <div className="action-btn">
                                            <ActionButton label="View" onClick={() => {
                                                setProdDetails(element);
                                                setShowHistory(true);
                                            }} />
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                    : <tbody >
                        <tr>
                            <td className="empty-data" colSpan={12}>
                                No data to display
                            </td>
                        </tr>
                    </tbody>}

            </table>
            <div className="all-products-pagination">
                <Pagination
                    count={totalPage}
                    page={page}
                    pageChange={(e: any, page: number) => setPageNo(page)}
                />
            </div>
            {showHistory && <BajajSkuUpdateHistoryDialogBox open={showHistory} onClose={() => setShowHistory(false)} title={prodDetails.name} element={prodDetails} />}
        </div>

    );

};