import { TabHeader } from "layouts/TabHeader";
import { Navigate, Route, Routes } from "react-router-dom";
import { InventoryTable } from "./inventoryTable";
import { profile } from "../../common/constant";

export const InventoryPage: React.FC = () => {

    return (
        <div className="inventoryPage">
            <TabHeader profile={profile} />
            <Routes>
                <Route path="/" element={<Navigate replace to="warehouse" />} />
                <Route path="/:profile" element={<InventoryTable />} />
            </Routes>
        </div>
    );
};