import React from "react";
import { NavLink } from "react-router-dom";

type CardContentProps = {
    onSelected: (label: string) => void;
    label: string
    to: string
    state?: any
}

const NavlinkCardcontent: React.FC<CardContentProps> = ({ to, label, onSelected, state }) => {
    return (
        <div>
            <NavLink to={to} state={state} onClick={() => onSelected(label)} className="select-content" >
                <div className="select-indicator" />
                <p>{label}</p>
            </NavLink>
        </div>
    );
};
export default NavlinkCardcontent;