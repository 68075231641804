import { STATUS } from "common/constant";
import ActionButton from "components/ActionButton";
import { useState, useEffect } from "react";
import { AddApiKey } from "./AddApiKey/index";
import { ApiKeyService } from "../../../services/apikey.service";
import "./styles.scss";




export const ApiKeysPage: React.FC = () => {

    const [open, setOpen] = useState(false);
    const [keyList, setKeyList] = useState<Array<any>>([]);
    const [formState, setFormState] = useState<any>("");
    const apiKeyService: ApiKeyService = new ApiKeyService();


    const fetchKeys = async () => {
        try {
            const suppliers = await apiKeyService.getApiKey();

            if (suppliers?.status === STATUS.SUCCESS) {
                setKeyList(suppliers.data);
            }
        }
        catch (error) {
            console.log(error);
        }
    };


    const handleOnAdd = () => {
        setOpen(true);
        setFormState("");
    };


    const handleOnUpdate = (keyData: any) => {
        setOpen(true);
        setFormState(keyData);
    };

    useEffect(() => {
        if (!open) {
            fetchKeys();
        }
    }, [open]);

    return (
        <div className="suppliers-list-page">
            <div className='inventory-table'>
                <div className='table-wrapper'>
                    <div className='btn-wrapper'>
                        <ActionButton label="Add API Key" onClick={() => handleOnAdd()} />
                    </div>
                    <div className='table-container'>
                        <table className="inventory-list-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>API Key</th>
                                    <th>Added By</th>
                                    <th>Updated By</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody >
                                {keyList?.map((element: any, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{element?.name}</td>
                                            <td>{element?.api_key}</td>
                                            <td>{element?.row_added_by}</td>
                                            <td>{element?.row_updated_by}</td>
                                            <td style={{display:"flex",justifyContent: "space-around"}}>
                                            <ActionButton label="Update Role" onClick={() => handleOnUpdate(element)} />
                                        </td>
                                        </tr>
                            );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
            { open && <AddApiKey formState={formState} open={open} setOpen={setOpen} /> }
        </div >
    );

};