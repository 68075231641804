export const AddressValidation = {
    address1: {
        required: { value: true, message: "Address1 can't be Empty" }
    },
    address3: {
        required: { value: true, message: "Area can't be Empty" }
    },
    city: {
        required: { value: true, message: "City field can't be Empty" },
    },
    state: {
        required: { value: true, message: "State field can't be Empty" },
    },
    pincode: {
        required: { value: true, message: "Pincode can't be Empty" },
        minLength: { value: 6, message: "pincode must be 6 characters long" },
        maxLength: { value: 6, message: "pincode must be 6 characters long" }
    },
    country: {
        required: { value: true, message: "Pincode can't be Empty" },
    }
};
