import { showNotification, STATUS } from "common/constant";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProductSerialNumberService } from "services/productserial.service";

export const ProductInventory: React.FC = () => {
    const productSerialService = new ProductSerialNumberService();
    const navigate = useNavigate();
    const params = useParams();
    const profile = params.profile;
    const [inventoryList, setInventoryList] = useState<any>([]);
    
    const getinventoryList = async (): Promise<void> => {
        try {
            const inventoryDetails: any = await productSerialService.getInventoryListByProfile(profile as string);
            setInventoryList(inventoryDetails?.inventoryList);
        }
        catch (error) {
            console.log(error);
            showNotification(STATUS.FAILURE, "Something went wrong");
        }
    };

    useEffect(() => {
        getinventoryList();
    }, [profile]);
    
    return (
        <div className='product-number-page'>
            <div className='inventory-lists'>
                {inventoryList?.map((data: any) => <p onClick={()=>navigate(`${data?.inv_loc_code}`)}>{data?.inv_loc_code}</p>)}
            </div>
            {!inventoryList.length && <div className="no-products">
                <div className="no-products-card-wrapper">
                    No Products Found
                </div>
            </div>}
        </div>
    );
};