import * as React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { BajajService } from "services/bajaj.service";
import CloseIcon from "@mui/icons-material/Close";
import "./styles.scss";
import moment from "moment";

type Tprops = {
    open: boolean,
    onClose: () => void
    title: string
    element: any
}

const BajajSkuUpdateHistoryDialogBox: React.FC<Tprops> = ({ open, onClose, title, element }) => {

    const [products, setProducts] = useState<any>();
    const bajajService = new BajajService();


    const getHistory = async () => {
        try {
            const productHistoryResponse = await bajajService.getProdHistory(element);
            console.log(productHistoryResponse.data);

            if (productHistoryResponse) {
                setProducts(productHistoryResponse.data);

            }
            else {
                setProducts([]);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getHistory();
    }, [element]);


    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={() => onClose()}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth="sm"
            >
                <CloseIcon className="close-icon" onClick={()=>onClose()} />
                <DialogTitle className="dialog-title" >{title.replace("_", " ")}</DialogTitle>
                <DialogContent >
                    <div className="cont">
                        <table>
                            <thead>
                                <tr>
                                    <th className="history-head-supplier-id">
                                        Supplier Id
                                    </th>
                                    <th className="history-head-stock">
                                        Stock
                                    </th>
                                    <th className="history-head-status">
                                        Status
                                    </th>
                                    <th className="history-head-mrp">
                                        Mrp
                                    </th>
                                    <th className="history-head-updated-on">
                                        Updated On
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {products?.map((e: any, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td className="history-body-supplier-id">
                                                {e.supplier_id}
                                            </td>
                                            <td className="history-body-stock">
                                                {e.stock}
                                            </td>
                                            <td className="history-body-status">
                                                {e.status}
                                            </td>
                                            <td className="history-body-mrp">
                                                {e.mrp}
                                            </td>
                                            <td key={index} className="history-body-updated-on">
                                                {moment(new Date(e["row_updated_dttm"])).add(5, "hours").add(30, "minutes").format("DD-MMM-YYYY hh:mm A")}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};
export default BajajSkuUpdateHistoryDialogBox;