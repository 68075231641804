import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle } from "@mui/material";
import ActionButton from "components/ActionButton";
import InputFieldWithoutLabel from "components/InputFieldWithoutLabel";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { ChannelService } from "../../../services/channel.service";
import { showNotification } from "common/constant";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";


type Props = {
    open: boolean
    setOpen: (value: boolean) => void
    formState: any
}
type formFields = {
    [key: string]: string
    name: string,
}
export const AddProfile: React.FC<Props> = ({ open, setOpen, formState }) => {
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm<formFields>({ mode: "onChange" });
    const channelservice = new ChannelService();
    const [count, setCount] = useState<number>(0);



    const onSubmit = async (data: formFields) => {
        try {
            const arr: Array<string> = [];

            [...Array(count)].filter((e: number, key: number) => {

                if (data[`attributes-${key}`]) {
                    console.log(data[`attributes-${key}`]);
                    arr.push(data[`attributes-${key}`]);
                }
            });
            console.log(arr,Array(count));

            if (formState) {
                const response = await channelservice.editChannels(data.name, formState.name, arr);

                if (response.status === "success") {
                    setOpen(false);
                    showNotification("success", response.message);
                }
            }
            else {
                const response = await channelservice.addChannels(data.name, arr);

                if (response.status === "success") {
                    setOpen(false);
                    showNotification("success", response.message);
                }
            }
        }
        catch (error) {
            console.log(error);

        }
    };



    useEffect(() => {
        if (formState) {
            setValue("name", formState.name);
            setCount(JSON.parse(formState?.channel_attributes)?.length || 0);
            console.log(formState?.channel_attributes);
        }
    }, []);

    const userFormValidation = {
        name: {

            required: { value: true, message: "Profile can't be Empty" },
        },
    };

    const handleDelete = (key: number) => {
        console.log(key);

        for (let i = key; i < count; i++) {
            console.log(i, watch(`attributes-${i + 1}`), i + 1);
            setValue(`attributes-${i}`, watch(`attributes-${i + 1}`));
        }
        setValue(`attributes-${count - 1}`, "");
        setCount(count - 1);
    };

    return (
        <Dialog open={open} >
            <div className="adduser-form-wrap">
                <div className="header-wrap">
                    <DialogTitle className="title-content">{formState ? "Update Profile" : "Add Profile"}</DialogTitle>
                    <CloseIcon onClick={() => setOpen(false)} style={{ cursor: "pointer" }} />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-body">
                        <InputFieldWithoutLabel
                            register={register("name", userFormValidation.name)}
                            text="Name"
                            error={errors.name?.type !== undefined}
                            errorText={errors.name ? errors.name.message : ""}
                        />
                        {[...Array(count)]?.length ? [...Array(count)].map((e, key) => {
                            return (
                                <div className="field">
                                    <InputFieldWithoutLabel
                                        register={register(`attributes-${key}`, { value: formState?.channel_attributes ? JSON.parse(formState?.channel_attributes) ? JSON.parse(formState?.channel_attributes)[key] : "" : "" })}
                                        text={`Attributes ${key + 1}`}
                                    />
                                    <IconButton>
                                        <CancelIcon onClick={() => handleDelete(key)} />
                                    </IconButton>
                                </div>
                            );
                        }) : <></>}
                        <ActionButton type="button" label="Add" onClick={() => setCount(count + 1)} />
                    </div>
                    <div className="form-btn-wrap">
                        <ActionButton type="submit" label="Submit" />
                    </div>
                </form>
            </div>
        </Dialog>
    );
};