import { AxiosError } from "axios";
import { parseAxiosError } from "common/constant";
import { IErrorResponse, ISuccessResponse } from "./interfaces/ApiResponse.interface";
import { BaseService } from "./base.service";
import { stock_info } from "./interfaces/pimApi.interface";
import moment from "moment";

export class PIMService extends BaseService {

    public async getPim(searchText: string): Promise<ISuccessResponse | IErrorResponse> {

        try {

            const { data } = await this.pimApi("product/1", {
                headers: {
                    "product": searchText
                }
            });

            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async getPinCodeDetails(pincode: string): Promise<ISuccessResponse | IErrorResponse> {

        try {

            const { data } = await this.pincodeApi(`pincodeapilist.php?pincode=${pincode}`, {
                headers: {
                    "authorization": "14b5016c-0d9c-4e3f-ad13-71ac2b0b65df"
                }
            });

            return data;


        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async gstStatus(gstNumber: string): Promise<ISuccessResponse | IErrorResponse> {

        try {
            const { data } = await this.gstApi(`getGSTInformations?gstNumber=${gstNumber}`);

            return data;
        }
        catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async getStock(value: stock_info): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const date: string = moment(new Date()).format("YYYYMMDD");
            const { data } = await this.getStockInfo.get(`GetStockInfo?CompanyCode=PMPL&AsonDate=${date}&BranchCode=${value?.ext_code}&ItemCode=${value?.erp_prod_id}`, {headers: {securitycode: "5382-1294-8763-3537", userid: "poorvika"}});

            return data;
        }
        catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }



}