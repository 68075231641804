import React, { useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { showNotification, STATUS } from "common/constant";
import { useNavigate } from "react-router-dom";
import { AuthService } from "services/auth.service";



const SSOPage: React.FC = () => {
    const { keycloak } = useKeycloak();
    const authService: AuthService = new AuthService();
    const navigate = useNavigate();
    const getLoginData = async (email: string) => {

        const loginResponse = await authService.ssoLogin({ email });

        if (loginResponse.status === STATUS.SUCCESS) {
            localStorage.setItem("accessToken", loginResponse?.data.userData[0].token);
            setTimeout(() => {
                navigate("/central-inventory/inventory-location/active-locations");
            }, 5000);
        } else {
            navigate("/login");
            showNotification(STATUS.FAILURE, loginResponse.message);
        }
    };

    useEffect(() => {
        if (keycloak?.authenticated) {
            const data: any = keycloak?.tokenParsed;

            try {
                getLoginData(data.email);
            } catch (error) {
                showNotification(STATUS.FAILURE, (error as Error).message);
            }
        }
    }, [keycloak?.authenticated]);

    return <div className="login-page-wrapper"> Loading...</div>;
};

export default SSOPage;
