import React, { useEffect } from "react";
import { Dialog, DialogTitle } from "@mui/material";
import ActionButton from "components/ActionButton";
import InputFieldWithoutLabel from "components/InputFieldWithoutLabel";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { showNotification } from "common/constant";
import { ApiKeyService } from "../../../../services/apikey.service";
import { useSelector } from "react-redux";
import { RootState } from "redux/Reducer";
import { v4 as uuid } from "uuid";



type Props = {
    open: boolean
    setOpen: (value: boolean) => void
    formState: any
}
type formFields = {
    [key: string]: string
    name: string,
}
export const AddApiKey: React.FC<Props> = ({ open, setOpen, formState }) => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<formFields>({ mode: "onChange" });
    const apiKeyService = new ApiKeyService();
    const user: any = useSelector<RootState>((state) => state?.authData?.authData);



    const onSubmit = async (data: formFields) => {
        try {

            if (formState) {
                const req = {
                    name: data.name,
                    key: data.apikey,
                    row_updated_by: user.name,
                    oldName: formState.name
                };
                const response = await apiKeyService.updateApiKey(req);

                if (response.status === "success") {
                    setOpen(false);
                    showNotification("success", response.message);
                }
            }
            else {
                const req = {
                    name: data.name,
                    key: data.apikey,
                    row_added_by: user.name,
                    row_updated_by: user.name
                };
                const response = await apiKeyService.addApiKey(req);

                if (response.status === "success") {
                    setOpen(false);
                    showNotification("success", response.message);
                }
            }
        }
        catch (error) {
            console.log(error);

        }
    };

    const handleKeyGen = () => {
        const unique_id = uuid();
        setValue("apikey", unique_id);
        console.log(unique_id);
    };



    useEffect(() => {
        if (formState) {
            setValue("name", formState.name);
            setValue("apikey", formState.api_key);
        }
    }, []);

    const userFormValidation = {
        name: {

            required: { value: true, message: "Profile can't be Empty" },
        },
    };


    return (
        <Dialog open={open} >
            <div className="adduser-form-wrap">
                <div className="header-wrap">
                    <DialogTitle className="title-content">{formState ? "Update Profile" : "Add Profile"}</DialogTitle>
                    <CloseIcon onClick={() => setOpen(false)} style={{ cursor: "pointer" }} />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-body">
                        <InputFieldWithoutLabel
                            register={register("name", userFormValidation.name)}
                            text="Name"
                            error={errors.name?.type !== undefined}
                            errorText={errors.name ? errors.name.message : ""}
                        />
                        <InputFieldWithoutLabel
                            register={register("apikey", userFormValidation.name)}
                            text="API Key"
                            error={errors.name?.type !== undefined}
                            errorText={errors.name ? errors.name.message : ""}
                        />
                        {!formState&&<div className="generator" onClick={handleKeyGen}>Generate Random Key</div>}
                    </div>
                    <div className="form-btn-wrap">
                        <ActionButton type="submit" label="Submit" />
                    </div>
                </form>
            </div>
        </Dialog>
    );
};