
import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "layouts";

export const PrivateRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/*" element={<Navigate replace to="/central-inventory/dashboard" />} />
                <Route path='central-inventory/*' element={<Layout />} />
            </Routes>
        </>
    );
};