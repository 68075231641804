import { combineReducers } from "redux";
import { InventoryReducer } from "./inventoryReducer";
import { WarehouseReducer } from "./warehouseReducer";
import { ProductSerialReducer } from "./productSerialReducer";
import { AuthenticationReducer } from "./authenticationReducer";
import { SearchReducer } from "./searchReducer";

export const RootReducer = combineReducers({
    inventoryData: InventoryReducer,
    warehouseData: WarehouseReducer,
    productSerialData: ProductSerialReducer,
    authData: AuthenticationReducer,
    searchDate: SearchReducer
});

export type RootState = ReturnType<typeof RootReducer>