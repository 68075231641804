import * as React from "react";
import { default as MuiPagination } from "@mui/material/Pagination";
import "./styles.scss";

type Pprops = {
    count: number
    page?:number
    onClickPage?: () => void
    pageChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
    // limit?: number
    // setLimit?: any
    // pageLimit?: any[]
}

export const Pagination: React.FC<Pprops> = ({ count, onClickPage, pageChange,page }) => {

    // const pageNumber = [5, 10, 25, 50, 100];
    // const handleOnLimit = (lts: any) => {
    //     setLimit(Number(lts));
    // };


    return (
        <div className='pagination-container'>
            <div className='pagination'>
                {/* <DropDown dropdown={pageLimit ? pageLimit : pageNumber} defaultValue={limit ? limit : 10} onChange={(e) => handleOnLimit(e.target.value)} /> */}
                <MuiPagination count={count} page={page} color="primary" onClick={onClickPage} onChange={pageChange} />
            </div>
        </div>
    );
};

