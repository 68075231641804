import React from "react";
import "./styles.scss";

type InputLabelInputProps = {
    allowedDatas: any
    name1?: string
    register1?: any
    placeholder1?: string
    onClick1?: () => void;
    onChange1?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    name2?: string
    register2?: any
    placeholder2?: string
    onClick2?: () => void;
    onChange2?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & React.InputHTMLAttributes<HTMLInputElement>

const DynamicInputLabelWithDownInput: React.FC<InputLabelInputProps> = ({ allowedDatas, placeholder1, register1, name1, placeholder2, register2, name2, onChange1, onClick1,onChange2, onClick2, ...props }) => {

    return (
        <>
            <div className="input-label-input-warehouse">
                <div className="input-with-verified-icon">
                    <input autoComplete="off" ref={register1} list="allowed-channels" className="text-label-content" onChange={onChange1} placeholder={placeholder1}  {...register1}  {...props} />
                    {allowedDatas?.length ? (
                        <datalist id="allowed-channels">
                            {allowedDatas.map((element: any) => <option id="allowed-channels" key={element} value={element} />)}
                        </datalist>
                    ) : <datalist id="allowed-channels" />}
                </div>
            </div>
            <p>:</p>
            <div className="input-label-input-warehouse">
                <div className="input-with-verified-icon">
                    <input autoComplete="off" ref={register2} className="text-label-content" onChange={onChange2} placeholder={placeholder2} {...register2}  {...props} />
                </div>
            </div>
        </>
    );
};

export default DynamicInputLabelWithDownInput;
