import { Backdrop } from "@mui/material";


export const IntraTransferOrderPage: React.FC = () => {

    return (
        <div>
            <Backdrop open>
                <div style={{ color: "#fff" }}>
          Coming Soon. . .
                </div>
            </Backdrop>
        </div>
    );
};