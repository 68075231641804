
import PoorvikaLogo from "assets/images/poorvika-white-bg-log.png";
import LoginForm from "containers/forms/LoginForm";
import ForgotPasswordForm from "containers/forms/ForgotPassword";
import RegisterForm from "containers/forms/RegisterForm";
import { useCallback, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";
import "./styles.scss";
import ResetPasswordForm from "containers/forms/ResetPasswordForm";


type Tprops = {
    page: string
}

export const LoginPage: React.FC<Tprops> = ({ page }) => {

    const { keycloak, initialized } = useKeycloak();

    const ssologin = async () => {
        const token = keycloak?.token ?? "";
        console.log("token", token);
    };


    const keycloaklogin = useCallback(() => {
        keycloak?.login({ redirectUri: `${window.location.origin}/sso-login` });
    }, [keycloak]);

    useEffect(() => {
        if (keycloak && keycloak?.authenticated) {
            keycloak.logout();
            ssologin();
        }
    }, [keycloak?.authenticated]);

    return (
        <div className="login-page">
            <div className="content-wrapper">
                <div className="left-content">
                    <img alt='' src={PoorvikaLogo} draggable="false" />
                </div>
                <div className="right-content">
                    {page === "login" && <LoginForm ssoinitialized={initialized} keycloaklogin={keycloaklogin} />}
                    {page === "signup" && <RegisterForm />}
                    {page === "forgot-password" && <ForgotPasswordForm />}
                    {page === "reset-password" && <ResetPasswordForm />}
                </div>
            </div>
        </div>

    );

};

