import React from "react";
import "./styles.scss";
import { NavLink } from "react-router-dom";

type LayoutHeaderProps = {
    title: string;
    to?: string
    edit?: boolean
    remove?: boolean
    onClick?: () => void;
    state?: any
    editName?: string
    deleteName?: string
}
const LayoutHeader: React.FC<LayoutHeaderProps> = ({ title, to, edit, remove, onClick, state, editName, deleteName }) => {
    return (

        <div className="legal-details-Header">
            <p>{title}</p>
            <div className="edit-delete-btn-wrapper">

                {edit ?
                    <NavLink to={to || ""} state={state} className="edit-btn-wrapper">
                        {editName ? editName : "Edit"}
                    </NavLink>
                    : <> </>
                }
                {remove ?
                    <div className="edit-btn-wrapper" onClick={onClick}>
                        {deleteName ? deleteName : "Delete"}
                    </div>
                    : <> </>
                }
            </div>
        </div>
    );
};

export default LayoutHeader;