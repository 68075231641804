export const ResetPasswordValidations = {
    password: {
        required: { value: true, message: "Password field is empty" },
        minLength: { value: 8, message: "Password must be at least 8 characters long" },
        pattern: { value: /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16})$/, message: "Password must contain atleast one uppercase, lowercase and numbers" }
    },
    verification_code: {
        required: { value: true, message: "Please enter verification code" }
    },
    email: {
        required: {value: true, message:"Link expired"}
    }
};