import React, { useEffect, useState } from "react";
import LayoutHeader from "components/LayoutHeader";
import { WarehouseService } from "services/warehouse.service";
import DialogBox from "components/DialogBox";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { setWarehouseActions } from "redux/Actions/actions";
import { Pagination } from "components/Pagination";
import { useParams } from "react-router-dom";
import { SearchBox } from "components/SearchBox";
import { showNotification, STATUS } from "../../../common/constant";
import ActionButton from "components/ActionButton";
import { RootState } from "redux/Reducer";

export const Locations: React.FC = () => {
    const [warehouselist, setWarehouselist] = useState<any>();
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const [deleteData, setDeleteData] = useState<{ code: number, isActive: string, ext_warehouse_code: string,name:string }>();
    const [totalPage, setTotalPage] = useState<any>();
    const [pageNo, setPageNo] = useState(1);
    const warehouseService = new WarehouseService();
    const dispatch = useDispatch();
    const [inputText, setInputText] = useState<string>("");
    const params = useParams();
    const locationStatus = params.location === "active-locations" ? 1 : 0;
    const [invProfile, setInvProfile] = useState<string>("All");
    const user: any = useSelector<RootState>(
        (state) => state?.authData?.authData
      );
    

    const getWarehouseList = async () => {
        try {
            const warehouseResponse = await warehouseService.getWarehouse(locationStatus, 10, pageNo);
            setWarehouselist(warehouseResponse.data.warehouselist);
            setTotalPage(warehouseResponse?.data.totalRecords);
        } catch (error) {
            showNotification(STATUS.FAILURE, "Something went wrong");
            console.log(error);
        }
    };

    const deleteWarehouse = async (data: any) => {
        try {
            const deleteResponse = await warehouseService.deletewarehouse(data);

            if (deleteResponse) {
                showNotification(STATUS.SUCCESS, deleteResponse.message);
                getWarehouseList();
                setOpenPopup(false);
            }
        }
        catch (error) {
            showNotification(STATUS.FAILURE, "Something went wrong");
            console.log(error);
        }
    };

    const handleOnSearch = (searchText: string) => {
        const lowerCase = searchText.toLowerCase();
        setInputText(lowerCase);
    };

    const getSearchInventoryList = async (profile?: string, search?: string) => {
        try {
            const warehouseResponse: any = await warehouseService.getSearchWarehouse(search === undefined ? "" : search, locationStatus, 10, profile ? profile : invProfile);

            setWarehouselist(warehouseResponse.data.warehouselist);
            setTotalPage(warehouseResponse?.data.totalRecords);
        } catch (error) {
            showNotification(STATUS.FAILURE, "Something went wrong");
            console.log(error);
        }
    };

    const handleOnDelete = async (id: number, status: string,branch_code:string) => {
        try {
            setOpenPopup(true);
            setDeleteData({ code: id, isActive: status, ext_warehouse_code: branch_code,name:user.name});
        }
        catch (error) {
            showNotification(STATUS.FAILURE, "Something went wrong");
            console.log(error);
        }
    };
    
    useEffect(() => {
        setPageNo(1);
    }, [params]);

    useEffect(() => {
        getWarehouseList();
        dispatch(setWarehouseActions(null));
    }, [pageNo, locationStatus]);
    useEffect(() => {
        if (inputText.length < 3) {
            getWarehouseList();

            return;
        }
        getSearchInventoryList(invProfile, inputText);
    }, [inputText]);


    return (
        <div className="Activelocations-page">
            {warehouselist?.length ?
                (<div className='warehouse-list-wrap'>
                    {warehouselist.map((warehouseData: any) => <div className="location-layout-wrapper" >
                        <LayoutHeader title={warehouseData.name + " ( " + warehouseData.code + ")"} deleteName={locationStatus ? "De-Activate" : "Activate"} edit={true} remove={true} to="/central-inventory/inventory-location/edit-location" state={warehouseData} onClick={() => handleOnDelete(warehouseData.code, warehouseData.isActive, warehouseData.ext_warehouse_code)} />
                        <div className="activelocation-details-card">
                            <div className="activelocation-details-content">
                                <div className="registered-address-content">
                                    <p className="warehouse-details-label">Registered Address</p>
                                    <p className="warehouse-details-content">{(warehouseData.address1 ? warehouseData.address1 + "," : "") + (warehouseData.address2 ? warehouseData.address2 + "," : "")
                                        + (warehouseData.city ? warehouseData.city + "," : "") + warehouseData.state + "," + warehouseData.pincode}</p>
                                </div>
                                <div className="primary-contact-content">
                                    <p className="warehouse-details-label">Primary Contact :</p>
                                    <p className="warehouse-details-content">{warehouseData.primary_contact_name}</p>
                                    <p className="warehouse-details-content">{warehouseData.primary_contact_email}</p>
                                    <p className="warehouse-details-content">{warehouseData.primary_contact_mobile}</p>
                                </div>
                                <div className="primary-contact-content">
                                    <p className="warehouse-details-label">Escalations Contact :</p>
                                    <p className="warehouse-details-content">{warehouseData.escalations_contact_name}</p>
                                    <p className="warehouse-details-content">{warehouseData.escalations_contact_email}</p>
                                    <p className="warehouse-details-content">{warehouseData.escalations_contact_mobile}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>) : (
                    <div className="no-warehouse">
                        <div className="No-Active-card-wrapper">
                            No {locationStatus ? "Active" : "Inactive"} Locations Found
                        </div>
                    </div>)}
            {/* {warehouselist?.length ? */}
            <>
                <div className="warehouse-search">
                    {(Object.values(params)[0] === "active-locations" || Object.values(params)[0] === "inactive-locations") ? <>
                        <SearchBox
                            onChange={(e: any) => (e.target.value.length >=3 || e.target.value.length === 0)&& handleOnSearch(e.target.value)}
                            placeholder="Search by Warehouse Name"
                        /><div className="warehouse-search-btn">
                            <ActionButton label="All" color={invProfile === "All" ? undefined : "outlined"} onClick={() => {
                                setInvProfile("All");
                                setPageNo(1);
                                inputText.length < 3 ? getSearchInventoryList("All") : getSearchInventoryList("All", inputText);
                            }} />
                            <ActionButton color={invProfile === "Store" ? undefined : "outlined"} label="Store" onClick={() => {
                                setInvProfile("Store");
                                setPageNo(1);
                                inputText.length < 3 ? getSearchInventoryList("Store") : getSearchInventoryList("Store", inputText);
                            }} />
                            <ActionButton color={invProfile === "Warehouse" ? undefined : "outlined"} label="Warehouse" onClick={() => {
                                setInvProfile("Warehouse");
                                setPageNo(1);
                                inputText.length < 3 ? getSearchInventoryList("Warehouse") : getSearchInventoryList("Warehouse", inputText);
                            }} />
                        </div>
                    </> : <></>}
                </div>
                <Pagination count={Math.ceil(totalPage / 10)} page={pageNo} pageChange={(e, page: number) => setPageNo(page)} />
            </>

            {/* : <></>} */}
            {openPopup && <DialogBox open={openPopup} onClose={setOpenPopup} message={`Are you sure want to ${locationStatus ? "De-activate" : "Activate"} this Warehouse ?`} title={locationStatus ? "De-activate" : "Activate"} slide="down" onClick={() => deleteWarehouse(deleteData)} />}
        </div>);
};
