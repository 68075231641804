import React from "react";
import { HeaderLayout } from "./Header";
import { SideBarLayout } from "./SideBar";
import { InventoryPage } from "pages/InventoryPage";
import { Routes, Route, Navigate } from "react-router-dom";
import { InventoryLocationPage } from "pages/InventoryLocationPage";
import { ProductSerialPage } from "pages/ProductSerialPage";
import { IntraTransferOrderPage } from "pages/IntraTransferOrderPage";
import { IntraTransferReturnPage } from "pages/IntraTransferReturnPage";
import { ProductTable } from "pages/ProductSerialPage/ProductTable";
import { ProductList } from "pages/ProductSerialPage/productList";
import InventoryAddEditForm from "pages/InventoryPage/InventoryAddEditForm";
import { useSelector } from "react-redux";
import { RootState } from "redux/Reducer";
import "./styles.scss";
import { DashboardPage } from "pages/DashboardPage";
import { ProfileSettingsPage } from "pages/RootAdmin/ProfileSettingsPage";
import { SupplierList } from "pages/RootAdmin/UserSettingPage";
import { ConfigSettingsPage } from "pages/RootAdmin/ConfigSettingPage";
import { InventoryTable } from "pages/InventoryPage/inventoryTable";
import { ReferenceDataPage } from "pages/RootAdmin/ReferenceDataPage";
import { ApiKeysPage } from "pages/RootAdmin/ApiKeys";
import {HistoryPage} from "pages/RootAdmin/HistoryPage";

export const Layout: React.FC = () => {
    const userRole = useSelector<RootState>((state) => state.authData.authData.role);

    return (
        <div className="layout">
            <SideBarLayout />
            <div className="layout-header-wrapper">
                <HeaderLayout />
                <div>
                    <Routes>
                        <Route element={<DashboardPage />} path='/dashboard' />
                        <Route element={<ProfileSettingsPage />} path='/admin/channels-settings' />
                        <Route element={<ConfigSettingsPage />} path='/admin/config' />
                        <Route element={<ReferenceDataPage />} path='/admin/reference/*' />
                        <Route element={<InventoryPage />} path='/inventory/*' />
                        <Route element={<InventoryTable />} path='/APX' />
                        <Route element={<InventoryAddEditForm />} path='/:profile/:erp_prod_id/*' />
                        <Route element={<InventoryAddEditForm />} path='/:profile/add' />
                        <Route element={<InventoryAddEditForm />} path='/inventory/:profile/:erp_prod_id/*' />
                        <Route element={<InventoryAddEditForm />} path='/inventory/:profile/add' />
                        <Route element={<InventoryLocationPage />} path="/inventory-location/*" />
                        <Route element={<ProductSerialPage />} path="/product-serial-number/*" />
                        <Route path="/product-serial-number/:profile/:inv_loc_code" element={<ProductList />} />
                        <Route path="/product-serial-number/:profile/:inv_loc_code/:erp_prod_id" element={<ProductTable />} />
                        <Route element={<IntraTransferOrderPage />} path="/transfer-order" />
                        <Route element={<IntraTransferReturnPage />} path="/transfer-return-order" />
                        {userRole === "super_admin" && <Route element={<SupplierList />} path='/admin/user-settings' />}
                        <Route path="/*" element={<Navigate replace to="/central-inventory/inventory" />} />
                        <Route path="/admin" element={<Navigate replace to="/central-inventory/admin/user-settings" />} />
                        <Route path="/admin/reference/Bajaj" element={<Navigate replace to="/central-inventory/admin/reference/Bajaj/Seller_ID" />} />
                        <Route path="/admin/apikeys" element={<ApiKeysPage />} />
                        <Route path="/admin/history" element={<HistoryPage />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};