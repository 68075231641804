import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ActionButton from "components/ActionButton";
import { useState, useEffect } from "react";
import { AddProfile } from "../ProfileModel/index";
import { ChannelService } from "services/channel.service";
import { showNotification } from "common/constant";



export const ProfileSettingsPage: React.FC = () => {
    const [profiles, setProfiles] = useState<any>([]);
    const [open, setOpen] = useState(false);
    const [formState, setFormState] = useState<string>("");
    const channelservice= new ChannelService();
  
  

    const handleAddProfile = async()=>{
        setOpen(true);
        setFormState("");
    };

    const fetchProfiles = async()=>{
        const {data}=await channelservice.getChannelDetails();
        setProfiles(data?.channels);
        console.log(data.channels);
    };

    const handleRemoveItem = async (name:any) => {
        const response=await channelservice.deleteChannels(name);
        
        if (response.status === "success") {
            setOpen(false);
            showNotification("success", response.message);
            fetchProfiles();
        }
    };

    const handleOnUpdate = (profile: string) => {
        setOpen(true);
        setFormState(profile);
    };
    useEffect(() => {
        fetchProfiles();
    }, []);
    useEffect(() => {
        fetchProfiles();
    }, [open]);

    return (
        <div className="suppliers-list-page">
            <div className='inventory-table'>
                <div className='table-wrapper'>
                    <div className='btn-wrapper'>
                        <ActionButton label="Add Profile" onClick={handleAddProfile} />
                    </div>
                    <div className='table-container'>
                        <table className="inventory-list-table">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Name</th>
                                    <th>Channel Attributes</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            {profiles?.length ? (
                                <tbody>
                                    {profiles?.map((element: any, index: number) => {
                                        return (
                                            <tr className="inventory-list-tbody" key={index}>
                                                <td>{index + 1}</td>
                                                <td>{element?.name}</td>
                                                <td>{element?.channel_attributes ? element?.channel_attributes:""}</td>
                                                <td><ActionButton label="Edit Profile" onClick={() => handleOnUpdate(element)} />
                                                    <IconButton onClick={()=>handleRemoveItem(element.name)}>
                                                        <CancelIcon  />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td className="empty-data" colSpan={12}>
                                        No data to display
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
            {open && <AddProfile formState={formState} open={open} setOpen={setOpen}/>}
        </div>
    );

};