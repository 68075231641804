import { AxiosError } from "axios";
import { parseAxiosError } from "common/constant";
import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interfaces/ApiResponse.interface";


export class ChannelService extends BaseService {
    public async getChannels(): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get("/channels");

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async getChannelDetails(): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get("/channels/details");

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async getChannelAttributes(): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get("/channels/attributes");

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async addChannels(Channel: string,channelAttributes:Array<string>): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("/channels", {name:Channel,channel_attributes:channelAttributes});

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async deleteChannels(name: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.delete(`/channels/${name}`);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async editChannels(newChannel: any,oldChannel:any,channelAttributes:any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.put("/channels", {new_channel:newChannel,old_channel:oldChannel,channel_attributes:channelAttributes});

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
}