import { AxiosError } from "axios";
import { parseAxiosError } from "common/constant";
import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interfaces/ApiResponse.interface";

export class HistoryService extends BaseService {
    public async getHistory(page?: number, pageLimit?: number, field?: string, orderBy?: string, search?: string, inv_loc_code?: string, module?: string, erp_prod_id?: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const params = { page, pageLimit, erp_prod_id, inv_loc_code, module, search, field, orderBy };
            const { data } = await this.httpClient.get("/history", { params });

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
}