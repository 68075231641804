import { useSelector } from "react-redux";
import { RootState } from "redux/Reducer";
import { PrivateRoutes } from "./privateRoutes";
import { PublicRoutes } from "./publicRoutes";

export const RootRoutes = () => {
    const token = useSelector<RootState>((state) => state?.authData?.authData.token);

    return (
        <>
            {token ? <PrivateRoutes /> : <PublicRoutes />}
        </>
    );
};
