import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Checkbox, Dialog, FormControlLabel, TextField } from "@mui/material";
import LayoutHeader from "components/LayoutHeader";
import ActionButton from "components/ActionButton";
import { DesktopTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ALL_DAYS } from "common/constant";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import "./styles.scss";

type WorkingDaysFormprops = {
  title?: string
  open: boolean
  cancelClose: () => void
  onTimeSelected: (data: any) => void
  editData: any
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export const WorkingDaysForm: React.FC<WorkingDaysFormprops> = ({ open, cancelClose, onTimeSelected, editData }) => {
    const { handleSubmit, control, watch, formState: { errors } } = useForm<any>({ mode: "onChange" });

    const [customDays, setCustomDays] = useState<string[]>([]);
    useEffect(() => {
        if (editData) {
            setCustomDays(Object.keys(editData));
        }
    }, []);
    const customdaysSelectionHander = async (isSelected: boolean, selectedDay: string) => {
        if (isSelected) {
            setCustomDays([...customDays, selectedDay]);
        } else {
            const selectedItemIndex = customDays.indexOf(selectedDay);

            if (selectedItemIndex >= 0) {
                setCustomDays(customDays?.filter((item) => item !== selectedDay));
            }
        }
    };
    
    const onSubmit = async (data: any) => {
        const workinghrs: any = {};
        customDays.forEach((day) => {
            Object.assign(workinghrs,
                {
                    [day]: {
                        start: data?.[`${day}-start`] ? data?.[`${day}-start`].toString().split(" ")[4] : "08:00:00",
                        end: data?.[`${day}-end`] ? data?.[`${day}-end`].toString().split(" ")[4] : "20:00:00"
                    }
                }
            );
        });
        onTimeSelected(workinghrs);
        cancelClose();
        setCustomDays([]);
    };

    const handleOnAllSelect = async (selected: boolean) => {
        if (selected) {
            setCustomDays(ALL_DAYS);
        }
        else {
            setCustomDays([]);
        }
    };

    return (
        <Dialog open={open} TransitionComponent={Transition} className="addware-popup">
            <LayoutHeader title="Working hours & Days" />

            <form className="workingDays-form" onSubmit={handleSubmit(onSubmit)}>
                <p className="working-days-text">Working Days*</p>
                <FormControlLabel
                    control={
                        <Checkbox
                            sx={{ color: "#a9a099", "&.Mui-checked": { color: "#FF8E33" } }}
                            checked={customDays?.length === 7}
                            onChange={(e) => handleOnAllSelect(e.target.checked)}
                        />}
                    label="All Days"
                />

                <div className="checkbox-layout">
                    {ALL_DAYS.map((day) => (
                        <div className="round" key={day}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        key={day}
                                        sx={{ color: "#a9a099", "&.Mui-checked": { color: "#FF8E33" } }}
                                        defaultChecked={customDays.includes(day) ? true : false}
                                        onChange={(event) => customdaysSelectionHander(event.target.checked, day)}
                                        checked={customDays.includes(day) ? true : false}
                                    />}
                                label={day}
                            />
                            <div className="working-hours-wrapper">
                                <Controller
                                    control={control}
                                    name={day + "-start"}
                                    defaultValue={(editData && editData[day]) ? `Mon Aug 18 2014 ${editData[day].start} GMT+0530 (India Standard Time)` : "Mon Aug 18 2014 10:00:00 GMT+0530 (India Standard Time)"}
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopTimePicker
                                                className="timepicker"
                                                label="Open Time"
                                                {...field}
                                                renderInput={(props) => <TextField {...props} />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                                <Controller
                                    control={control}
                                    defaultValue={(editData && editData[day]) ? `Mon Aug 18 2014 ${editData[day].end} GMT+0530 (India Standard Time)` : "Mon Aug 18 2014 18:00:00 GMT+0530 (India Standard Time)"}
                                    name={day + "-end"}
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopTimePicker
                                                className="timepicker"
                                                label="Close Time"
                                                {...field}
                                                renderInput={(props) => <TextField {...props} />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                    rules={{
                                        validate: {
                                            timeDiff: (value) =>
                                                new Date(value) >
                        new Date(watch(day + "-start")),
                                        },
                                    }}
                                />
                            </div>
                            {errors[day + "-end"]?.type === "timeDiff" && (
                                <span className="text-danger" >
                  *End time must be greater than start time
                                </span>
                            )}
                        </div>
                    ))}
                </div>

                <div className="workingdays-form-btn-wrapper">
                    <ActionButton label="Cancel" color='outlined' onClick={cancelClose} />
                    <ActionButton label="Submit" />
                </div>
            </form>
        </Dialog>
    );
};