import { LoginPage } from "pages/loginPage";
import SSOPage from "pages/loginPage/ssopage";
import { Navigate, Route, Routes } from "react-router-dom";

export const PublicRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/*" element={<Navigate replace to="/login" />} />
                <Route path="/login" element={<LoginPage page="login" />} />
                <Route path='/signup' element={<LoginPage page="signup" />} />
                <Route path='/forgot-password' element={<LoginPage page="forgot-password" />} />
                <Route path='/reset-password' element={<LoginPage page="reset-password" />} />
                <Route path='/sso-login' element={<SSOPage />} />
            </Routes>
        </>
    );
};                         
