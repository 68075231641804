import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import InputFieldWithLabels from "components/InputFieldWithLabels";
import ActionButton from "components/ActionButton";
import { IProductSerialNumber } from "services/interfaces/productserial.interface";
import DropDown from "components/DropDown";
import { ProductSerialNumberService } from "services/productserial.service";
import { ProductSerialNumberValidations } from "./validator";
import "./styles.scss";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { TextField } from "@mui/material";
import { showNotification, STATUS } from "common/constant";

type Tprops = {
    show: boolean
    productData?: any
    handleOnClose: () => void;
    response?: any;
    isAdd?: boolean;
    inventoryData?: any
}


const ProductSerialAddEditForm: React.FC<Tprops> = ({ handleOnClose, show, productData, response, isAdd, inventoryData }) => {
    const { register, handleSubmit, setValue, formState: { errors }, control } = useForm<IProductSerialNumber>({ mode: "onChange" });
    const productSerialNumberService = new ProductSerialNumberService();
    const statusDropDown = ["SOLD", "NEW", "DEFECT", "IN_TRANSIT"];
    const [invoiceDate, setInvoiceDate] = React.useState<any>(productData?.invoice_date ? productData?.invoice_date : new Date());
    const [salesInvoiceDate, setsalesInvoiceDate] = React.useState<any>(productData?.sales_invoice_date ? productData?.sales_invoice_date : new Date());




    useEffect(() => {
        if (!isAdd) {
            setValue("erp_prod_id", productData?.erp_prod_id);
            setValue("sku", productData?.sku);
            setValue("inv_loc_code", productData?.inv_loc_code);
            setValue("ext_prod_id", productData?.ext_prod_id);
            setValue("status", productData?.status);
        }

    }, [productData]);



    const onSubmit = async (data: any) => {
        try {
            const productSerialValues: any = {
                erp_prod_id: data?.erp_prod_id ? data?.erp_prod_id : "",
                inv_loc_code: data?.inv_loc_code ? data?.inv_loc_code : "",
                ext_prod_id: data?.ext_prod_id ? data?.ext_prod_id : "",
                sku: data?.sku ? data?.sku : "",
                prod_id: data?.prod_id ? data?.prod_id : "",
                status: data?.status ? data?.status : "",
                invoice_no: data?.invoice_no ? data?.invoice_no : "",
                unique_id: productData?.unique_id,
                sale_invoice_no: data?.sale_invoice_no ? data?.sale_invoice_no : "",
                invoice_date: invoiceDate ? invoiceDate : "",
                sales_invoice_date: salesInvoiceDate ? salesInvoiceDate : "",
                row_added_by: "developer",
                row_updated_by: "developer"
            };

            if (isAdd) {
                response(data);
                handleOnClose();
            }

            if (productData?.erp_prod_id && !isAdd) {
                const updateProductSerialNumber = await productSerialNumberService.updateProductSerialNumber(productSerialValues);

                if (updateProductSerialNumber.status === "success") {
                    setTimeout(() => {
                        handleOnClose();
                    }, 2000);
                }
                else if (updateProductSerialNumber.status === "error") {
                    showNotification(STATUS.FAILURE, "Something went wron");
                }
            }
            // else {
            //     const addProductSerialNumber = await productSerialNumberService.addProductSerialNumber(productSerialValues)
            //     if (addProductSerialNumber.status === "success") {
            //         handleOnSnackBar(addProductSerialNumber.status, addProductSerialNumber.message)
            //         setTimeout(() => {
            //             handleOnClose()
            //         }, 2000)
            //     }
            //     else if (addProductSerialNumber.status === "error") {
            //         handleOnSnackBar(addProductSerialNumber.status, addProductSerialNumber.message)
            //     }
            //     response(addProductSerialNumber)
            // }
        }
        catch (error) {
            console.log(error);
        }
    };

    const handleOnInvoice = (newValue: Date | null) => {
        setInvoiceDate(newValue);
    };
    
    const handleOnSalesInvoice = (newValue: Date | null) => {
        setsalesInvoiceDate(newValue);
    };


    return (
        <div className="product-serial-add-form">
            <div className={`Modal ${show ? "Show" : ""}`} style={isAdd ? { width: "30%" } : {}}>
                <div className="handle-close-ic" onClick={handleOnClose} >
                    <CloseIcon className="close-ic" />
                </div>
                <p className="title-content">Product Serial Number</p>
                <form onSubmit={handleSubmit(onSubmit)} className="inventory-form">
                    <div className="form-top-content">
                        <div className="form-body">
                            <InputFieldWithLabels
                                register={register("erp_prod_id", ProductSerialNumberValidations.erp_prod_id)}
                                error={errors.erp_prod_id?.type !== undefined}
                                errorText={errors.erp_prod_id ? errors.erp_prod_id.message : ""}
                                text="ERP Product ID"
                                defaultValue={productData?.erp_prod_id ? productData?.erp_prod_id : inventoryData?.erp_prod_id}
                                disabled={true}
                            />
                            <InputFieldWithLabels
                                register={register("inv_loc_code", ProductSerialNumberValidations.inv_loc_code)}
                                error={errors.inv_loc_code?.type !== undefined}
                                errorText={errors.inv_loc_code ? errors.inv_loc_code.message : ""}
                                text="Inventory Local Code"
                                defaultValue={productData?.inv_loc_code ? productData?.inv_loc_code : inventoryData?.inv_loc_code}
                                disabled={true}
                            />
                            <InputFieldWithLabels
                                register={register("sku", ProductSerialNumberValidations.sku)}
                                error={errors.sku?.type !== undefined}
                                errorText={errors.sku ? errors.sku.message : ""}
                                text="SKU"
                                defaultValue={productData?.sku ? productData?.sku : inventoryData?.sku}
                                disabled={true}
                            />
                            <InputFieldWithLabels
                                register={register("ext_prod_id", ProductSerialNumberValidations.ext_prod_id)}
                                error={errors.ext_prod_id?.type !== undefined}
                                errorText={errors.ext_prod_id ? errors.ext_prod_id.message : ""}
                                text="External Product Id"
                                defaultValue={productData?.ext_prod_id ? productData?.ext_prod_id : inventoryData?.ext_prod_id}
                                disabled={true}
                            />
                            <InputFieldWithLabels
                                maxLength={15}
                                pattern="\d*"
                                title="IMEI should be Number"
                                register={register("prod_id", ProductSerialNumberValidations.prod_id)}
                                error={errors.prod_id?.type !== undefined}
                                errorText={errors.prod_id ? errors.prod_id.message : ""}
                                text="IMEI Number"
                                disabled={productData?.prod_id ? true : false}
                                defaultValue={productData?.prod_id}
                            />
                            <DropDown
                                dropdown={statusDropDown}
                                register={register("status", ProductSerialNumberValidations.status)}
                                error={errors.status?.type !== undefined}
                                errorText={errors.status ? errors.status.message : ""}
                                text="Product Status"
                                defaultValue={productData?.status}
                                autoComplete="off"
                            />
                            <InputFieldWithLabels
                                register={register("invoice_no", ProductSerialNumberValidations.invoice_no)}
                                // error={errors.invoice_no?.type !== undefined}
                                // errorText={errors.invoice_no ? errors.invoice_no.message : ''}
                                text="Purchase Invoice Number"
                                defaultValue={productData?.invoice_no}
                                disabled={(productData?.invoice_no || productData?.status === "SOLD" )? true : false}
                            />
                            <InputFieldWithLabels
                                register={register("sale_invoice_no", ProductSerialNumberValidations.sale_invoice_no)}
                                // error={errors.sale_invoice_no?.type !== undefined}
                                // errorText={errors.sale_invoice_no ? errors.sale_invoice_no.message : ''}
                                text="Sales Invoice Number"
                                defaultValue={productData?.sale_invoice_no}
                                disabled={(productData?.sale_invoice_no || productData?.status === "SOLD") ? true : false}
                            />
                            <div className="datePicker">
                                <label className="label">Purchase Invoice Date:</label>
                                <Controller
                                    control={control}
                                    name="invoice_date"
                                    defaultValue={productData?.invoice_date ? productData.invoice_date : "Mon Aug 18 2014 GMT+0530 (India Standard Time)"}
                                    render={() => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                disabled={productData?.status === "SOLD" ? true : false}
                                                value={invoiceDate}
                                                inputFormat="MM/dd/yyyy"
                                                onChange={handleOnInvoice}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </div>
                            <div className="datePicker">
                                <label className="label">Sales Invoice Date:</label>
                                <Controller
                                    control={control}
                                    name="sales_invoice_date"
                                    defaultValue={productData?.sales_invoice_date ? productData.sales_invoice_date : "Mon Aug 18 2014 GMT+0530 (India Standard Time)"}
                                    render={() => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                disabled={productData?.status === "SOLD" ? true : false}
                                                value={salesInvoiceDate}
                                                inputFormat="MM/dd/yyyy"
                                                onChange={handleOnSalesInvoice}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-btn-wrap">
                        <ActionButton label={productData?.unique_id ? "Update" : "Add"} type="submit" />
                        <ActionButton color="outlined" label="Cancel" type="button" onClick={handleOnClose} />
                    </div>
                </form>
            </div>
            <div className={`Overlay ${show ? "Show" : ""}`} />
        </div >
    );
};

export default ProductSerialAddEditForm;
