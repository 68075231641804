export const AddWarehouseValidation = {

    fulfilment_type: {
        required: { value: true, message: "Fullfilment Type  can't be Empty" }
    },
    inv_profile: {
        required: { value: true, message: "Inventory Profile can't be Empty" }
    },
    primary_contact_name: {
        required: { value: true, message: "Name field can't be Empty" }
    },
    primary_contact_email: {
        required: { value: true, message: "Email field can't be Empty" },
        pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "* Email format is invalid" }
    },
    primary_contact_mobile: {
        required: { value: true, message: "Mobile field can't be Empty" },
    },
    escalations_contact_name: {
        required: { value: true, message: "Name field can't be Empty" }
    },
    escalations_contact_email: {
        required: { value: true, message: "Email field can't be Empty" },
        pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "* Email format is invalid" }
    },
    courier_fullfillment_method: {
        required: { value: true, message: "Courier fullfillment can't be Empty" }
    },
    regional_zone: {
        required: { value: true, message: "Regional zone can't be Empty" }
    },
    location_type: {
        required: { value: true, message: "Location type can't be empty" }
    },
    escalations_contact_mobile: {
        required: { value: true, message: "Mobile field can't be Empty" }
    },
    gst_number: {
        required: { value: true, message: "GST field can't be Empty" },
        minLength: { value: 15, message: "GST must be 15 characters long" },
        maxLength: { value: 15, message: "GST must be 15 characters long" },
        pattern: { value: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/, message: "GST Number Format is invalid" },
    },
    pan_number: {
        required: { value: true, message: "PAN number can't be Empty" },
        pattern: { value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}/, message: "PAN Number Format is invalid" },
        maxLength: { value: 10, message: "PAN must be 10 characters long" },
        minLength: { value: 10, message: "PAN must be 10 characters long" },
    },
    tan_number: {
        required: { value: true, message: "TAN number can't be Empty" },
        pattern: { value: /^[A-Z]{4}[0-9]{5}[A-Z]{1}/, message: "TAN Number Format is invalid" },
        maxLength: { value: 10, message: "TAN must be 10 characters long" },
        minLength: { value: 10, message: "TAN must be 10 characters long" },
    },
    cst_number: {
        required: { value: true, message: "CST number can't be Empty" },
        pattern: { value: /^[0-9]{11}/, message: "CST Number Format is invalid" },
        maxLength: { value: 11, message: "CST must be 11 characters long" },
        minLength: { value: 11, message: "CST must be 11 characters long" },
    },
    vat_number: {
        required: { value: true, message: "VAT number can't be Empty" },
        pattern: { value: /^[0-9]{11}/, message: "VAT Number Format is invalid" },
        maxLength: { value: 11, message: "VAT must be 11 characters long" },
        minLength: { value: 11, message: "VAT must be 11 characters long" },
    }
};