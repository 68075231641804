import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Checkbox, Dialog, FormControlLabel, TextField } from "@mui/material";
import LayoutHeader from "components/LayoutHeader";
import ActionButton from "components/ActionButton";
import { DesktopTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ALL_COURIER_DAYS } from "common/constant";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import "./styles.scss";

type CourierDaysFormprops = {
    title?: string
    open: boolean
    cancelClose: () => void
    onTimeSelected: (data: any) => void
    editData: any
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export const CourierDaysForm: React.FC<CourierDaysFormprops> = ({ open, cancelClose, onTimeSelected, editData }) => {
    const { handleSubmit, control, watch, formState: { errors } } = useForm({ mode: "onChange" });

    const [customCourierDays, setCustomCourierDays] = useState<string[]>([]);
    useEffect(() => {
        if (editData) {
            setCustomCourierDays(Object.keys(editData));
        }
    }, []);
    const CustomCourierDaysSelectionHander = async (isSelected: boolean, selectedDay: string) => {
        if (isSelected) {
            setCustomCourierDays([...customCourierDays, selectedDay]);
        } else {
            const selectedItemIndex = customCourierDays.indexOf(selectedDay);

            if (selectedItemIndex >= 0) {
                setCustomCourierDays(customCourierDays?.filter((item) => item !== selectedDay));
            }
        }
    };

    const onSubmit = async (data: any) => {
        const workinghrs: any = {};
        customCourierDays.forEach((day) => {
            Object.assign(workinghrs,
                {
                    [day]: {
                        start: data?.[`${day}-start`] ? data?.[`${day}-start`].toString().split(" ")[4] : "08:00:00",
                        end: data?.[`${day}-end`] ? data?.[`${day}-end`].toString().split(" ")[4] : "20:00:00"
                    }
                }
            );
        });
        onTimeSelected(workinghrs);
        cancelClose();
        setCustomCourierDays([]);
    };

    // const handleOnAllSelect = async (selected: boolean) => {
    //   if (selected) {
    //     setCustomCourierDays(ALL_COURIER_DAYS)
    //   }
    //   else {
    //     setCustomCourierDays([])
    //   }
    // }

    return (
        <Dialog open={open} TransitionComponent={Transition} className="addware-popup">
            <LayoutHeader title="Courier Fullfilment Menthod" />

            <form className="workingDays-form" onSubmit={handleSubmit(onSubmit)}>

                {/* <FormControlLabel
          control={
            <Checkbox
              sx={{ color: "#a9a099", '&.Mui-checked': { color: "#FF8E33" } }}
              checked={customCourierDays?.length === 7}
              onChange={(e) => handleOnAllSelect(e.target.checked)}
            />}
          label={"All Days"}
        /> */}

                <div className="checkbox-layout">
                    {ALL_COURIER_DAYS.map(({ name, defaultStartTime, defaultEndTime }) => (
                        <div className="round" key={name}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        key={name}
                                        sx={{ color: "#a9a099", "&.Mui-checked": { color: "#FF8E33" } }}
                                        defaultChecked={customCourierDays.includes(name) ? true : false}
                                        onChange={(event) => CustomCourierDaysSelectionHander(event.target.checked, name)}
                                        checked={customCourierDays.includes(name) ? true : false}
                                    />}
                                label={name}
                            />
                            <div className="working-hours-wrapper">
                                <Controller
                                    control={control}
                                    name={name + "-start"}
                                    defaultValue={(editData && editData[name]) ? `Mon Aug 18 2014 ${editData[name].start} GMT+0530 (India Standard Time)` : `Mon Aug 18 2014 ${defaultStartTime} GMT+0530 (India Standard Time)`}
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopTimePicker
                                                className="timepicker"
                                                label="Open Time"
                                                {...field}
                                                renderInput={(props) => <TextField {...props} />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                />
                                <Controller
                                    control={control}
                                    defaultValue={(editData && editData[name]) ? `Mon Aug 18 2014 ${editData[name].end} GMT+0530 (India Standard Time)` : `Mon Aug 18 2014 ${defaultEndTime} GMT+0530 (India Standard Time)`}
                                    name={name + "-end"}
                                    render={({ field }) => (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopTimePicker
                                                className="timepicker"
                                                label="Close Time"
                                                {...field}
                                                renderInput={(props) => <TextField {...props} />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                    rules={{
                                        validate: {
                                            timeDiff: (value) =>
                                                new Date(value) >
                                                new Date(watch(name + "-start")),
                                        },
                                    }}
                                />
                            </div>
                            {errors[name + "-end"]?.type === "timeDiff" && (
                                <span className="text-danger" >
                                    *End time must be greater than start time
                                </span>
                            )}
                        </div>
                    ))}
                </div>

                <div className="workingdays-form-btn-wrapper">
                    <ActionButton label="Cancel" color='outlined' onClick={cancelClose} />
                    <ActionButton label="Submit" />
                </div>
            </form>
        </Dialog>
    );
};