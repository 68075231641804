import { AxiosError } from "axios";
import { parseAxiosError } from "common/constant";
import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interfaces/ApiResponse.interface";
import { IProductList, IProductSerialNumber } from "./interfaces/productserial.interface";

export class ProductSerialNumberService extends BaseService {

    public async updateProductSerialNumber(productSerialNumber: IProductSerialNumber): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.put("/product-serial-number/updateProductSerialNumber", productSerialNumber);

            return data;
        } catch (error: any) {
            return error.response.data;
        }
    }


    public async getInventoryListByProfile(profile: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get(`/product-serial-number/getInventory/${profile}`);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async getProductsByInventory(values: IProductList): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get(`/product-serial-number/getProductsByInventory/${values?.profile}/${values?.inv_loc_code}`);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async getProductById(values: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get(`/product-serial-number/getProductsById/${values?.erp_prod_id}/${values?.profile}/${values?.limit}/${values.page}`);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async getProductSerialNumberById(erp_prod_id: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.get(`product-serial-number/getProductSerialNumberbyId/${erp_prod_id}`);

            return data;
        }
        catch (error: any) {
            return error.response.data;
        }
    }

    public async deleteProductSerialNumber(uniqueID: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.delete(`/product-serial-number/deleteProductSerialNumber/${uniqueID}`);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async bulkUpload(file: any, user: string): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post(`/product-serial-number/bulkUpload?user=${user}`, file);

            return data;
        } catch (error: any) {
            return error.response.data;
        }
    }
}