import ActionButton from "components/ActionButton";
import { Loader } from "components/Loader";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IProductSerialNumber } from "services/interfaces/productserial.interface";
import { ProductSerialNumberService } from "services/productserial.service";
import ProductSerialAddEditForm from "containers/forms/ProductSerialAddEditForm";
import "./styles.scss";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/Reducer";
import { setWarehouseActions } from "redux/Actions/actions";
import { Pagination } from "components/Pagination";
import { showNotification } from "common/constant";

export const ProductTable: React.FC = () => {
    const productSerialService = new ProductSerialNumberService();
    const dispatch = useDispatch();
    const [productList, setProductList] = useState<any>([]);
    const params: any = useParams();
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [editData, setIsData] = useState<any>();
    const [loader, setLoader] = useState<boolean>(false);

    const [pageNo, setPageNo] = useState(1);
    const [totalPage, setTotalPage] = useState<any>();

    const product_details: any = useSelector<RootState>(
        (state) => state.productSerialData.productSerialData
    );
    const user: any = useSelector<RootState>(
        (state) => state?.authData?.authData
    );

    console.log("userDetails", user);

    const handleOnProducts = async (page: number) => {
        try {
            const paginationData = {
                erp_prod_id: params?.erp_prod_id,
                profile: params?.profile,
                limit: 10,
                page,
            };
            const productSerialListResponse: any =
                await productSerialService.getProductById(paginationData);
            setProductList(productSerialListResponse.data.productListById);
            setTotalPage(productSerialListResponse?.totalRecord);
            setLoader(false);
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        handleOnProducts(pageNo);
    }, [pageNo]);

    // useEffect(() => {
    //     setPageNo(1);
    //     handleOnProducts(pageNo);
    //     dispatch(setWarehouseActions(null));
    // }, [limit]);

    const handleOnEdit = (productData: IProductSerialNumber) => {
        setIsData(productData);
        setIsEdit(true);
    };

    const handleOnBack = () => {
        navigate("/central-inventory/product-serial-number");
        dispatch(setWarehouseActions([]));
    };

    const onFileChange = async (event: any) => {
        const files = event.target.files[0];

        if (files) {
            const uploadfile = new FormData();
            uploadfile.append("file", files, files.name);

            try {
                const uploadResult: any = await productSerialService.bulkUpload(uploadfile, String(user?.name));
                
                if (uploadResult.status === "success") {
                    showNotification("success", uploadResult.message);
                } else {
                    showNotification("error", "Unable to upload the document");
                }
            } catch (error) {
                showNotification("error", "Unable to upload the document");
            }
        }
    };


    return (
        <div className="product-list-page">
            <div className="header">
                <div className="product-Details">
                    <div className="fields">
                        <span className="name">ERP Product ID:</span>
                        {productList && (
                            <span className="value">{productList[0]?.erp_prod_id}</span>
                        )}
                    </div>
                    <div className="fields">
                        <span className="name">SKU:</span>
                        {productList && (
                            <span className="value">{productList[0]?.sku}</span>
                        )}
                    </div>
                    <div className="fields">
                        <span className="name">Product Name:</span>
                        {productList && <span className="value">{product_details}</span>}
                    </div>
                    <div className="fields">
                        <span className="name">Inventory Local Code Name:</span>
                        {productList && (
                            <span className="value">{productList[0]?.inv_loc_code}</span>
                        )}
                    </div>
                </div>
                <label className="custom-file-upload">
                    <input
                        type="file"
                        multiple={false}
                        onChange={onFileChange}
                        onClick={(event: any) => {
                            event.target.value = null;
                        }}
                    />
                    <div className="upload-btn">
                        <CloudUploadIcon className="upload-icon" />
                        <span className="txt">Upload Products</span>
                    </div>
                </label>
                <div className="btn-container">
                    <ActionButton onClick={() => handleOnBack()} label="Back" />
                </div>
            </div>
            <div className="table-wrapper">
                <div className="table-container">
                    <table className="product-list-table">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Product Number / IMEI</th>
                                <th>External Product Id</th>
                                <th>Status</th>
                                <th>Sales Invoice Number</th>
                                <th>Purchase Invoice Number</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        {productList.length ? (
                            <tbody>
                                {productList.map(
                                    (productData: IProductSerialNumber, index: number) => {
                                        return (
                                            <tr key={productData.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {productData.prod_id ? productData.prod_id : "-"}
                                                </td>
                                                <td>{productData.ext_prod_id}</td>
                                                <td>
                                                    <span className={`status ${productData.status}`}>
                                                        {productData.status}
                                                    </span>
                                                </td>
                                                <td>
                                                    {productData.sale_invoice_no
                                                        ? productData.sale_invoice_no
                                                        : "-"}
                                                </td>
                                                <td>
                                                    {productData.invoice_no
                                                        ? productData.invoice_no
                                                        : "-"}
                                                </td>
                                                <td>
                                                    <div>
                                                        <ActionButton
                                                            label={productData?.prod_id ? "Edit" : "Add"}
                                                            onClick={() => handleOnEdit(productData)}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        ) : (
                            <tbody>
                                <tr>
                                    <td className="empty-data" colSpan={12}>
                                        No data to display
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
            <Pagination
                count={Math.ceil(totalPage / 10)}
                pageChange={(e, page: number) => setPageNo(page)}
            />
            {loader && <Loader open={loader} />}
            {isEdit && (
                <ProductSerialAddEditForm
                    show={isEdit}
                    handleOnClose={() => {
                        setIsEdit(false);
                        handleOnProducts( pageNo);
                    }}
                    productData={editData}
                />
            )}
        </div>
    );
};
