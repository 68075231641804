import React, { useState } from "react";
import SideMenuItem from "components/SideMenuItem";
import PoorvikaLogo from "assets/images/poorvika-sidebar-logo.svg";
import PoorvikaSmLogo from "assets/images/poorvika-sm-icon.svg";
import CentralInventory from "assets/icons/central-inventory.svg";
import InvoiceLocation from "assets/icons/invoice-location.svg";
import ProductSerial from "assets/icons/product-serial-number.svg";
import IntraTransfer from "assets/icons/intra-transfer-order.svg";
import IntraTransferReturn from "assets/icons/intra-transfer-return.svg";
import CentralInventoryColured from "assets/icons/central-inventory-coloured.svg";
import InvoiceLocationColoured from "assets/icons/invoice-location-coloured.svg";
import ProductSerialColured from "assets/icons/product-serial-number-coloured.svg";
import IntraTransferColured from "assets/icons/intra-transfer-order-coloured.svg";
import IntraTransferReturnColured from "assets/icons/intra-transfer-return-coloured.svg";
import User from "assets/icons/userSvg.svg";
import ColuredUser from "assets/icons/userSvg-coloured.svg";
import DocumentIcon from "../../assets/icons/document-icon.svg";
import ColoredDocumentIcon from "../../assets/icons/document-icon-focus.svg";
import "./styles.scss";
import { useSelector } from "react-redux";
import { RootState } from "redux/Reducer";


const SideBarLayout: React.FC = () => {
    const userRole = useSelector<RootState>((state) => state.authData.authData.role);

    const [showLabel, setshowLabel] = useState(false);

    let timer: any = 0;
    const TIMEOUT = 180;

    function mouseEnter() {
        timer = setTimeout(() => {
            setshowLabel(true);
        }, TIMEOUT);
    }

    function mouseLeave() {
        setshowLabel(false);
        clearTimeout(timer);
    }



    return (
        <section className="app-sidebar" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            <div className="menu-header">
                <img className="menu-logo" alt="logo" draggable="false" src={PoorvikaSmLogo} />
                <img className="menu-color-logo" alt="menu-logo" draggable="false" src={PoorvikaLogo} />
            </div>
            <div className="menu-body">
                <div className="menu-list">
                    <>
                        <SideMenuItem
                            coloricon={InvoiceLocationColoured}
                            icon={InvoiceLocation}
                            label="Dashboard"
                            displayLabel={showLabel}
                            to="/central-inventory/dashboard"
                        />
                        <SideMenuItem
                            coloricon={InvoiceLocationColoured}
                            icon={InvoiceLocation}
                            label="Location"
                            displayLabel={showLabel}
                            to="/central-inventory/inventory-location"
                        />
                        <SideMenuItem
                            coloricon={CentralInventoryColured}
                            icon={CentralInventory}
                            label="Inventory"
                            displayLabel={showLabel}
                            to="/central-inventory/inventory"
                        />


                        {userRole === "super_admin1" && <SideMenuItem
                            coloricon={ProductSerialColured}
                            icon={ProductSerial}
                            label="Product Serial Number"
                            displayLabel={showLabel}
                            to="/central-inventory/product-serial-number"
                        />}
                        {userRole === "super_admin1" && <SideMenuItem
                            coloricon={IntraTransferColured}
                            icon={IntraTransfer}
                            label="Transfer Order"
                            displayLabel={showLabel}
                            to="/central-inventory/transfer-order"
                        />}
                        {userRole === "super_admin1" && <SideMenuItem
                            coloricon={IntraTransferReturnColured}
                            icon={IntraTransferReturn}
                            label="Transfer Return Order"
                            displayLabel={showLabel}
                            to="/central-inventory/transfer-return-order"
                        />}
                        <div className="admin-menu">
                            {userRole === "super_admin" &&
                                <SideMenuItem
                                    coloricon={ColuredUser}
                                    icon={User}
                                    label="Admin Management"
                                    admin={true}
                                    displayLabel={showLabel}
                                    to="/central-inventory/admin"
                                />}

                            <div className="admin-sub-menu">
                                {userRole === "super_admin" &&
                                    <SideMenuItem
                                        coloricon={ColuredUser}
                                        icon={User}
                                        label="User Settings"
                                        displayLabel={showLabel}
                                        to="/central-inventory/admin/user-settings"
                                    />}
                                {userRole === "super_admin" &&
                                    <SideMenuItem
                                        coloricon={ColuredUser}
                                        icon={User}
                                        label="Channels Settings"
                                        displayLabel={showLabel}
                                        to="/central-inventory/admin/channels-settings"
                                    />}
                                {userRole === "super_admin" &&
                                    <SideMenuItem
                                        coloricon={ColuredUser}
                                        icon={User}
                                        label="Configuration"
                                        displayLabel={showLabel}
                                        to="/central-inventory/admin/config"
                                    />}
                                {userRole === "super_admin" &&
                                    <SideMenuItem
                                        coloricon={ColoredDocumentIcon}
                                        icon={DocumentIcon}
                                        label="Reference Data"
                                        displayLabel={showLabel}
                                        to="/central-inventory/admin/reference"
                                    />}
                                {userRole === "super_admin" &&
                                    <SideMenuItem
                                        coloricon={ColuredUser}
                                        icon={User}
                                        label="API Keys"
                                        displayLabel={showLabel}
                                        to="/central-inventory/admin/apikeys"
                                    />}
                                {userRole === "super_admin" &&
                                    <SideMenuItem
                                        coloricon={ColuredUser}
                                        icon={User}
                                        label="History"
                                        displayLabel={showLabel}
                                        to="/central-inventory/admin/history"
                                    />}
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </section>
    );
};

export { SideBarLayout };