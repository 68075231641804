import { AxiosError } from "axios";
import { parseAxiosError } from "common/constant";
import { BaseService } from "./base.service";
import { IErrorResponse, ISuccessResponse } from "./interfaces/ApiResponse.interface";
import { IWarehouse } from "./interfaces/warehouse.interface";
import { API_ENDPOINTS } from "common/constant";

export class WarehouseService extends BaseService {

    public async addWarehouse(warehouseInformation: IWarehouse): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("/warehouse", warehouseInformation);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async bulkUpload(uploadfile: any): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.post("/warehouse/bulkUpload", uploadfile);

            return data;
        } catch (error: any) {
            return error.response.data;
        }
    }

    public async getWarehouse(id: number, limit: number, page: number): Promise<ISuccessResponse | IErrorResponse> {

        try {

            const { data } = await this.httpClient.get(`/warehouse/${id}/${limit}/${page}`);

            return data;

        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async getSearchWarehouse(search: string, id: number, limit: number, invProfile: string): Promise<ISuccessResponse | IErrorResponse> {

        try {

            if (search.length>0) {
                const { data } = await this.httpClient.get(`/warehouse/commonSearch/${id}/${limit}/${invProfile}/${encodeURIComponent(search)}`);

                return data;
            }
            else {
                const { data } = await this.httpClient.get(`/warehouse/commonSearch2/${id}/${limit}/${invProfile}`);

                return data;
            }



        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }
    public async updateWarehouse(inventoryInformation: IWarehouse): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const { data } = await this.httpClient.put("/warehouse", inventoryInformation);

            return data;
        } catch (error) {
            const err = parseAxiosError(error as AxiosError);

            return err.error.response.data;
        }
    }


    public async deletewarehouse(code: string) {

        try {
            const { data } = await this.httpClient.delete("/warehouse", {
                data: code,
            });

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async downloadDocument(): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const seller_code = localStorage.getItem("seller_code");
            const url = `${API_ENDPOINTS.WAREHOUSE}${API_ENDPOINTS.DOWNLOAD_INVENTORY}${seller_code}/all`;
            const { data } = await this.httpClient.get(url);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async uploadDocument(uploadfile: FormData): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const seller_code = localStorage.getItem("seller_code");
            const url = `${API_ENDPOINTS.WAREHOUSE}${API_ENDPOINTS.BULK_UPLOAD_INVENTORY}?SellerCode=${seller_code}`;

            const { data } = await this.httpClient.post(url, uploadfile);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }

    public async getUploadHistory(): Promise<ISuccessResponse | IErrorResponse> {
        try {
            const seller_code = localStorage.getItem("seller_code");
            const url = `${API_ENDPOINTS.WAREHOUSE}${API_ENDPOINTS.UPLOAD_INENTORY_HISTORY}?SellerCode=${seller_code}`;
            const { data } = await this.httpClient.get(url);

            return data;
        } catch (error) {
            return parseAxiosError(error as AxiosError);
        }
    }


}


