import ActionButton from "components/ActionButton";
import InputFieldWithoutLabel from "components/InputFieldWithoutLabel";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "services/auth.service";
import { ResetPasswordValidations } from "./validators";
// import './styles.scss'
import AlertBox from "components/AlertBox";
import { Loader } from "components/Loader";

type formFields = {
    password: string
    confirmPassword: string
    email: string,
    verification_code: string
}

const ResetPasswordForm: React.FC = () => {

    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm<formFields>({ mode: "onChange" });
    const navigate = useNavigate();
    const location = useLocation();
    const Authentication = new AuthService();
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [alertMsg, setAlertMsg] = useState<string>("");
    const [loader, setLoader] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("");
    const email = location.search.replace("?", "");
    console.log("location", email);


    const handleOnAlert = (message: string, statusMsg: string) => {
        setOpenAlert(true);
        setAlertMsg(message);
        setStatus(statusMsg);
    };

    const onSubmit = async (data: formFields) => {
        setLoader(true);

        if(!email) {
            handleOnAlert("error","Link expired");
            setLoader(false);
        }
        
        const apiData: formFields = {
            email,
            password: data.password,
            confirmPassword: data.confirmPassword,
            verification_code: data.verification_code
        };
        const resetPassword = await Authentication.ResetPassword(apiData);        
        
        if (resetPassword) {
            setLoader(false);

            if (resetPassword?.status === "success") {
                handleOnAlert(resetPassword.message, resetPassword.status);
                setValue("email", "");
            }

            if (resetPassword?.status === "error") {
                handleOnAlert(resetPassword?.message, resetPassword.status);
                setLoader(false);
            }
        }
    };


    return (
        <div className="register-form-wrap">
            <p className="sign-in-content">Reset Password</p>
            <p className="access-acc-content">Reset your password here.</p>
            <form className="login-form-wrap" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-body">

                    <InputFieldWithoutLabel
                        register={register("password", ResetPasswordValidations.password)}
                        error={errors.password?.type !== undefined}
                        errorText={errors.password ? errors.password.message : ""}
                        text="New Password"
                        type="password"
                    />
                    <InputFieldWithoutLabel
                        register={register("confirmPassword", {
                            required: { value: true, message: "Confirm Password field is empty" },
                            validate: (v) => v === (watch("password")) ||
                                "Must match with password"
                        })}
                        error={errors.confirmPassword?.type !== undefined}
                        errorText={errors.confirmPassword ? errors.confirmPassword.message : ""}
                        text="Confirm Password"
                        type="password"
                    />
                    <InputFieldWithoutLabel
                        register={register("verification_code", ResetPasswordValidations.verification_code)}
                        error={errors.verification_code?.type !== undefined}
                        errorText={errors.verification_code ? errors.verification_code.message : ""}
                        text="Verification code"
                        type="text"
                    />
                </div>
                <p className="conditions-content">* Please enter the<span className="blue-content"> Verification code</span> send through Email</p>
                <div className="form-btn-wrap">
                    <ActionButton type="submit" label="Reset" />
                </div>
            </form>
            {openAlert && <AlertBox severity={status} open={openAlert} message={alertMsg} btnName="OK" onClick={status === "success" ? () => navigate("/login") : () => setOpenAlert(false)} title={status === "success" ? "Success" : "Error"} />}
            {loader && <Loader open={loader} />}
            <p>Back to <span className="blue-content" onClick={() => navigate("/login")}> Login</span></p>
        </div>
    );
};

export default ResetPasswordForm;