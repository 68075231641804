import { WorkingDaysForm } from "containers/forms/WorkingDaysForm";
import { WarehouseService } from "services/warehouse.service";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LayoutHeader from "components/LayoutHeader";
import InputLabelinputWarehouse from "components/InputLabelWithDownInput";
import { useForm } from "react-hook-form";
import InputLabelDownWithDropDown from "components/InputLabelWithDownDropdown";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ActionButton from "components/ActionButton";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import MapsContainer from "components/MapContainer";
import { ToggleButton } from "components/ToggleButton";
import InputLabelwithContent from "components/InputLabelwithContent";
import { AddressForm } from "containers/forms/AddressEditForm";
import { IWarehouse, IGeoLocation, IWarehouseAddress } from "../../../services/interfaces/warehouse.interface";
import { setWarehouseActions } from "redux/Actions/actions";
import { useDispatch } from "react-redux";
import { Autocomplete, Checkbox, Dialog, IconButton, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AddWarehouseValidation } from "./validators";
import { PIMService } from "services/pimapi.service";
import "./styles.scss";
import { showNotification, STATUS } from "../../../common/constant";
import { CourierDaysForm } from "containers/forms/CourierDaysForm";
import { ChannelService } from "services/channel.service";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/AddBox";
import DynamicInputLabelWithDownInput from "components/DynamicInputLabelWithDownInput";
import { BajajService } from "services/bajaj.service";
import { RootState } from "redux/Reducer";
import { useSelector } from "react-redux";
import HistoryIcon from "../../../assets/icons/history.png";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { HistoryPage } from "pages/RootAdmin/HistoryPage";

type TProps = {
    isEdit?: boolean
}

const locationLink = "https://www.google.com/maps/search/?api=1&query=";
const fullfillmentDropDown = ["Fulfilled by Poorvika", "Fulfilled by Seller"];
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export const AddLocationPage: React.FC<TProps> = ({ isEdit }) => {

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /* Form */
    const { handleSubmit, register, formState: { errors }, setValue, setError, watch, reset } = useForm<any>({ mode: "all" });
    const warehouseName = watch("name");
    const supplierName = watch("supplier_name");

    /* State Variable*/
    let editWarehouseData: any = isEdit ? location.state : null;
    const warehouseService = new WarehouseService();
    const [warehouseCode, setWarehouseCode] = useState<string>(editWarehouseData ? editWarehouseData.code : "");
    const [supplierCode, setsupplierCode] = useState<string>(editWarehouseData ? editWarehouseData.supplier_code : "");
    const [lat, setLat] = useState<number>();
    const [lng, setLng] = useState<number>();
    const [plusCode, setPlusCode] = useState<string>(editWarehouseData?.plus_code ? editWarehouseData?.plus_code : "");
    const [latlong, setlatlong] = useState<string>(editWarehouseData?.latlng ? editWarehouseData.latlng : "");
    const [addform, setaddform] = useState<boolean>(false);
    const [workingdaysDialog, setWorkingdaysDialog] = useState<boolean>(false);
    const [courierdaysDialog, setcourierdaysDialog] = useState<boolean>(false);
    const [registerdAddress, setRegesiteredAddress] = useState<IWarehouseAddress | null>();
    const [workingdays, setWorkingdays] = useState<any>();
    const [courierdays, setcourierdays] = useState<any>([]);
    const [returnOrdered, setReturnOrdered] = useState<boolean>(editWarehouseData ? editWarehouseData.is_ret_order_handled : true);
    const [locality, setLocality] = useState(null);
    const [geoLoc, setGeoLoc] = useState<any>();
    const [channel, setChannel] = useState<Array<number>>([]);
    const [allowedChannels, setAllowedChannels] = useState<any>();
    const [update, setUpdate] = useState<boolean>(false);
    const [channelDatas, setchannelDatas] = useState<any>({});
    const [allowedDatas, setAllowedDatas] = useState<any>([]);
    const [detailsDialogVisibility, setDetailsDialogVisibility] = useState<boolean>(false);
    const user: any = useSelector<RootState>((state) => state?.authData?.authData);


    /* Service Defination */
    const pimService = new PIMService();
    const channelservice = new ChannelService();
    const bajajService = new BajajService();

    const locationSelectionHandler = async (loactionAddress: IGeoLocation) => {
        if (loactionAddress.latlng !== "20.5937,78.9629") {
            const pluscode: any = loactionAddress.plusCode;
            setPlusCode(pluscode.compound_code);
            setlatlong(loactionAddress.latlng);
            const cordinates = loactionAddress.latlng.split(",");
            setGeoLoc(`POINT(${Number(cordinates[0])} ${Number(cordinates[1])})`);
        }
    };

    const setAddressLocation = (val: any) => {
        setLocality(val.label);
        setPlusCode(val.label);
        geocodeByAddress(val?.label)
            .then((results) => getLatLng(results[0]))
            .then(({ lat, lng }) => {
                const latlng = `${lat}, ${lng}`;
                setlatlong(latlng);
                setLat(lat);
                setLng(lng);
                setGeoLoc(`POINT(${lat} ${lng} )`);
            });
    };

    useEffect(() => {
        if (!isEdit) {
            setRegesiteredAddress(null);
            setWorkingdays(null);
            editWarehouseData = null;
            setPlusCode("");
            setlatlong("");
        }

        const str: any = editWarehouseData?.latlng;

        if (str !== undefined) {
            setLat(Number(str?.x));
            setLng(Number(str?.y));
            setlatlong(`${str?.x}, ${str?.y}`);
        }

        if (editWarehouseData) {
            setRegesiteredAddress(Object.assign({},
                { address1: editWarehouseData?.address1 },
                { address2: editWarehouseData?.address2 },
                { address3: editWarehouseData?.address3 },
                { pincode: editWarehouseData?.pincode },
                { city: editWarehouseData?.city },
                { country: editWarehouseData?.country },
                { state: editWarehouseData?.state }
            ));
            setWorkingdays(editWarehouseData?.active_hours_and_days ? JSON.parse(editWarehouseData?.active_hours_and_days) : "");
            setcourierdays(editWarehouseData?.attributes ? JSON.parse(editWarehouseData?.attributes) : "");
        }
    }, [editWarehouseData]);


    const verifyGst = async () => {
        const gstNumber = watch("gst_number");

        if (gstNumber.length === 15) {
            try {
                const gstStatus = await pimService.gstStatus(gstNumber);

                if (!gstStatus?.data.data.lgnm) {
                    setError("gst_number", { type: "invalid", message: "Inactive GST Number" });
                }
            }
            catch (error) {
                console.log(error);
            }
        }
    };


    const addToBajajSupplier = async (data: any) => {
        const updatePimSku = await bajajService.updateInvLocCodeFromWarehouse({ data: { code: warehouseCode }, supplier_id: data });

        if (updatePimSku.status === STATUS.SUCCESS) {
            showNotification(STATUS.SUCCESS, updatePimSku.message);

            return true;
        }
        else {
            showNotification(STATUS.FAILURE, updatePimSku.message);

            return false;
        }
    };


    const onSubmit = async (data: any) => {
        if (!latlong) {
            showNotification(STATUS.FAILURE, "Please Mark A Location on Map");

            return;
        }

        try {
            const channelsDetails: any = {};
            channel.map((element) => {
                const channelValues: any = {};
                channelDatas[data[`channel_name_${element}`]].map((x: number) => {
                    if (data[`${data[`channel_name_${element}`]}-${x}`] && data[`${data[`channel_name_${element}`]}-value-${x}`]) {
                        channelValues[data[`${data[`channel_name_${element}`]}-${x}`]] = data[`${data[`channel_name_${element}`]}-value-${x}`];
                    }
                });

                if (Object.keys(channelValues).length > 0) {
                    channelsDetails[data[`channel_name_${element}`]] = channelValues;
                }
            });

            if (channelsDetails?.Bajaj?.supplier_id) {
                const isUpdated = await addToBajajSupplier(channelsDetails?.Bajaj?.supplier_id);
                console.log("i am here", isUpdated);

                if (!isUpdated) {
                    return;
                }
            }

            const warehouseData: IWarehouse = {
                name: editWarehouseData ? editWarehouseData?.name : data.name,
                plus_code: plusCode ? plusCode : "",
                supplier_code: editWarehouseData ? editWarehouseData?.supplier_code : supplierCode,
                supplier_name: editWarehouseData ? editWarehouseData?.supplier_name : data.supplier_name,
                primary_contact_name: data.primary_contact_name ? data.primary_contact_name : "",
                primary_contact_email: data.primary_contact_email ? data.primary_contact_email : "",
                primary_contact_mobile: data.primary_contact_mobile ? data.primary_contact_mobile : "",
                fulfillment_cut_off: data.fulfillment_cut_off ? data.fulfillment_cut_off : "",
                escalations_contact_name: data.escalations_contact_name ? data.escalations_contact_name : "",
                escalations_contact_email: data.escalations_contact_email ? data.escalations_contact_email : "",
                escalations_contact_mobile: data.escalations_contact_mobile ? data.escalations_contact_mobile : "",
                fulfilment_type: data.fulfilment_type ? JSON.stringify(data.fulfilment_type) : "",
                ext_warehouse_code: data.ext_warehouse_code,
                latlng: geoLoc as string,
                inv_profile: data.inv_profile,
                description: data.description ? data.description : "",
                address1: registerdAddress?.address1 ? registerdAddress.address1 : "",
                address2: registerdAddress?.address2 ? registerdAddress.address2 : "",
                address3: registerdAddress?.address3 ? registerdAddress.address3 : "",
                pincode: registerdAddress?.pincode ? registerdAddress.pincode : "",
                city: registerdAddress?.city ? registerdAddress.city : "",
                state: registerdAddress?.state ? registerdAddress.state : "",
                country: registerdAddress?.country ? registerdAddress.country : "",
                location_type: data.location_type,
                regional_zone: data.regional_zone,
                is_ret_order_handled: Boolean(returnOrdered),
                courier_fullfillment_method: JSON.stringify(Object.keys(courierdays)),
                attributes: JSON.stringify(courierdays),
                geofencing_coordinates: geoLoc as string,
                gst_number: data.gst_number,
                tan_number: data.tan_number,
                pan_number: data.pan_number,
                cst_number: data.cst_number,
                vat_number: data.vat_number,
                active_days: JSON.stringify(Object.keys(workingdays)),
                active_hours_and_days: JSON.stringify(workingdays),
                location_link: latlong ? (locationLink + latlong) : "",
                channels: JSON.stringify(channelsDetails),
                row_added_by: user?.name,
                row_updated_by: user?.name
            };

            if (editWarehouseData) {
                warehouseData.code = editWarehouseData.code;
                const editWareHouseResponse = await warehouseService.updateWarehouse(warehouseData);
                showNotification(editWareHouseResponse.status, editWareHouseResponse.message);

                if (editWareHouseResponse.status === "success") {
                    setTimeout(() => {
                        navigate("/central-inventory/inventory-location/active-locations");
                        dispatch(setWarehouseActions(null));
                    }, 1000);
                }
            }
            else {
                const addWarehouseResponse = await warehouseService.addWarehouse(warehouseData);
                showNotification(addWarehouseResponse.status, addWarehouseResponse.message);

                if (addWarehouseResponse.status === "success") {
                    setTimeout(() => {
                        navigate("/central-inventory/inventory-location/active-locations");
                        dispatch(setWarehouseActions(null));
                    }, 1000);
                }
            }
        }
        catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        if (warehouseName) {
            setWarehouseCode("WH" + "-" + warehouseName + "-" + supplierName + "-" + Math.random().toString(36).substring(2, 7));
        }

        if (supplierName) {
            setsupplierCode("SC" + "-" + supplierName + "-" + Math.random().toString(36).substring(2, 7));
        }
    }, [warehouseName, supplierName]);

    const fetchAllowedChannels = async () => {
        const { data } = await channelservice.getChannels();
        setAllowedChannels(data?.channels);
        data?.channels.map((e: string) => Object.keys(channelDatas[e]).length > 0 ? channelDatas[e] : channelDatas[e] = []);
        setchannelDatas(channelDatas);
        setUpdate(!update);
    };

    const fetchAllowedChannelsAttributes = async () => {
        const { data } = await channelservice.getChannelAttributes();
        setAllowedDatas(data.channels);
    };


    const handleAddChannel = () => {
        let arr = channel;

        if (arr.length === 0) {
            setValue(`channel_name_${0}`, "");
            arr.push(0);
            setChannel(arr);
            setUpdate(!update);
        }
        else {
            arr.push(-1);
            let added = false;

            arr = arr.map((e, i) => {
                if (added) {
                    return channel[i - 1];
                }
                else {
                    if (e === i) {

                        return e;
                    } else {
                        if (arr.includes(i)) {
                            added = true;

                            return i;
                        }
                        else {
                            setValue(`channel_name_${i}`, "");
                            added = true;

                            return i;
                        }

                    }
                }
            });
            setChannel(arr);
            setUpdate(!update);
        }
    };

    const handleAddKeyValuePair = (value: string) => {
        channelDatas[value] = channelDatas[value] === undefined ? [] : channelDatas[value];
        let arr: Array<number> = channelDatas[value];

        if (arr?.length === 0) {
            arr.push(0);
            setUpdate(!update);
        }
        else {
            arr.push(-1);
            let added = false;

            arr = arr.map((e, i) => {
                if (added) {
                    return channelDatas[`${value}`][i - 1];
                }
                else {
                    if (e === i) {

                        return e;
                    } else {
                        if (arr.includes(i)) {
                            added = true;

                            return i;
                        }
                        else {
                            added = true;

                            return i;
                        }

                    }
                }
            });
            channelDatas[value] = arr;
            setchannelDatas(channelDatas);
            setUpdate(!update);
        }
    };

    const handleRemoveChannel = (element: number) => {
        setValue(`channel_name_${element}`, "");
        const arr = channel;
        setChannel(arr.filter((e) => e !== element));
        setUpdate(!update);
    };


    const handleRemoveChanneldata = (value: string, x: number, y: number) => {
        setValue(`${watch(`channel_name_${y}`)}-${x}`, "");
        setValue(`${watch(`channel_name_${y}`)}-value-${x}`, "");
        const arr = channelDatas[value];
        channelDatas[value] = arr.filter((e: number) => e !== x);
        setchannelDatas(channelDatas);
        setUpdate(!update);
    };


    useEffect(() => {
        fetchAllowedChannels();
        fetchAllowedChannelsAttributes();

        if (isEdit) {
            dispatch(setWarehouseActions(editWarehouseData));
            setValue("courier_fullfillment_method", JSON.parse(editWarehouseData?.courier_fullfillment_method));
            setValue("fulfilment_type", JSON.parse(editWarehouseData?.fulfilment_type));

            if (editWarehouseData?.channels !== null && editWarehouseData?.channels !== undefined && editWarehouseData?.channels !== "{}") {
                const keys = Object.keys(JSON.parse(editWarehouseData?.channels));
                //const keys = Object.keys(resdata);
                keys.map((x: string, i: number) => {
                    channelDatas[x] = [];
                    const keys2 = Object.keys(JSON.parse(editWarehouseData?.channels)[x]);
                    const arr: Array<number> = channelDatas[x];
                    setValue(`channel_name_${i}`, keys[i]);
                    channel.push(i);
                    setChannel(channel);
                    keys2.map((y, j) => {
                        setValue(`${x}-${j}`, keys2[j]);
                        setValue(`${x}-value-${j}`, JSON.parse(editWarehouseData?.channels)[x][keys2[j]]);
                        arr.push(j);

                    });
                    channelDatas[x] = arr;
                    setchannelDatas(channelDatas);
                    setUpdate(!update);

                });
            }

        }

        if (!isEdit) {
            dispatch(setWarehouseActions(null));
            reset();
        }

    }, [isEdit]);


    return (
        <div className="Addlocations-page">
            <div className="handle-history-ic">
                {editWarehouseData && <img src={HistoryIcon} className="history-ic" onClick={() => setDetailsDialogVisibility(true)} />}
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="location-layout-wrapper">
                    <div className="location-core-details">
                        <LayoutHeader title="Location Core Details" />
                        <div className="legal-details-content">
                            <InputLabelinputWarehouse
                                label="Warehouse Name *"
                                name="name"
                                register={register("name", {
                                    required: { value: (editWarehouseData && editWarehouseData?.name) ? false : true, message: "Warehouse name can't be Empty" }
                                })}
                                error={errors.name?.type !== undefined}
                                errorText={errors.name ? errors.name.message : ""}
                                disabled={editWarehouseData?.name ? true : false}
                                defaultValue={editWarehouseData?.name ? editWarehouseData?.name : ""}
                                tooltip="Warehouse_name"
                            />
                            <InputLabelinputWarehouse
                                label="Supplier Name *"
                                name="supplier_name"
                                register={register("supplier_name", {
                                    required: { value: (editWarehouseData && editWarehouseData?.supplier_name) ? false : true, message: "supplier name can't be Empty" }
                                })}
                                error={errors.supplier_name?.type !== undefined}
                                errorText={errors.supplier_name ? errors.supplier_name.message : ""}
                                disabled={editWarehouseData?.supplier_name ? true : false}
                                defaultValue={editWarehouseData?.supplier_name ? editWarehouseData?.supplier_name : ""}
                                tooltip="supplier_name"
                            />
                            <InputLabelinputWarehouse
                                label="Ext_Warehouse_code *"
                                name="Ext_warehouse_code"
                                register={register("ext_warehouse_code", {
                                    required: { value: (editWarehouseData && editWarehouseData?.ext_warehouse_code) ? false : true, message: "Ext_Warehouse_code can't be Empty" }
                                })}
                                error={errors.ext_warehouse_code?.type !== undefined}
                                errorText={errors.ext_warehouse_code ? errors.ext_warehouse_code.message : ""}
                                defaultValue={editWarehouseData?.ext_warehouse_code ? editWarehouseData?.ext_warehouse_code : ""}
                            />
                            <div className="courier-fullfilment-content">
                                <p className="text-label">Courier Fullfilment method*</p>
                                {courierdays ? (
                                    <div className="input-with-verified-icon">
                                        {courierdays ? (
                                            <div className="days-wrapper">
                                                {(Object.keys(courierdays)).map(day => <p>{day}&nbsp;</p>)}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <EditOutlinedIcon className="verified-icon"
                                            onClick={() => setcourierdaysDialog(true)}
                                        />
                                    </div>
                                ) : (
                                    <div className="input-with-verified-icon">
                                        <EditOutlinedIcon className="verified-icon"
                                            onClick={() => setcourierdaysDialog(true)}
                                        />
                                    </div>
                                )}
                                {errors.courier_fullfillment_method?.type !== undefined && <p className="error-text">{errors.courier_fullfillment_method ? errors.courier_fullfillment_method.message : ""}</p>}
                            </div>
                            <ToggleButton
                                value={returnOrdered}
                                label1="No"
                                label2="Yes"
                                text="Return Order Handling"
                                onChange={(event) => setReturnOrdered(event.target.checked ? true : false)}
                                defaultChecked={editWarehouseData ? editWarehouseData?.is_ret_order_handled : returnOrdered}
                            />
                            <div className="working-days-content">
                                <label className="txt-label">Fulfilment Type<span className="label-star">*</span>  :</label>
                                <Autocomplete
                                    multiple
                                    limitTags={3}
                                    {...register("fulfilment_type", AddWarehouseValidation.fulfilment_type)}
                                    id="fulfilment_type"
                                    options={fullfillmentDropDown}
                                    getOptionLabel={(option) => option}
                                    defaultValue={editWarehouseData?.fulfilment_type ? JSON.parse(editWarehouseData?.fulfilment_type) : []}
                                    onChange={(event, value) => setValue("fulfilment_type", value)}
                                    renderOption={(props, option, { selected }) => (
                                        <li id="fulfilment_type" {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                                id="fulfilment_type"
                                            />
                                            <p id="fulfilment_type"> {option}</p>
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField className="txt-field-wrap" {...params} />
                                    )}
                                />
                                {errors.fulfilment_type?.type !== undefined && <p className="error-text">{errors.fulfilment_type ? errors.fulfilment_type.message : ""}</p>}
                            </div>

                            <InputLabelDownWithDropDown
                                text="Location Type"
                                dropdown={["Outskirts", "City", "Town"]}
                                name="location_type"
                                register={register("location_type", AddWarehouseValidation.location_type)}
                                error={errors.location_type?.type !== undefined}
                                errorText={errors.location_type ? errors.location_type.message : ""}
                                defaultValue={editWarehouseData?.location_type ? editWarehouseData?.location_type : ""}
                            />
                            {!isEdit && <InputLabelDownWithDropDown
                                text="Inventory Profile "
                                dropdown={["Warehouse", "Store"]}
                                name="inv_profile"
                                register={register("inv_profile", AddWarehouseValidation.inv_profile)}
                                error={errors.inv_profile?.type !== undefined}
                                errorText={errors.inv_profile ? errors.inv_profile.message : ""}
                                defaultValue={editWarehouseData?.inv_profile ? editWarehouseData?.inv_profile : ""}
                            />}
                            <InputLabelDownWithDropDown
                                text="Regional Zone"
                                dropdown={["A", "B", "C", "D"]}
                                name="regional_zone"
                                register={register("regional_zone", AddWarehouseValidation.regional_zone)}
                                error={errors.regional_zone?.type !== undefined}
                                errorText={errors.regional_zone ? errors.regional_zone.message : ""}
                                defaultValue={editWarehouseData?.regional_zone ? editWarehouseData?.regional_zone : ""}
                            />
                            <InputLabelinputWarehouse
                                label="Description"
                                name="description"
                                register={register("description")}
                                defaultValue={editWarehouseData?.description ? editWarehouseData?.description : ""}
                                tooltip="Warehouse_name"
                                textarea={true}
                            />
                            <div className="registed-address-content">
                                <label>Registered Address *</label>
                                {registerdAddress ? (
                                    <div className="address-field">
                                        <EditOutlinedIcon className="verified-icon" onClick={() => setaddform(true)}
                                        />
                                        <p>{registerdAddress.address1}</p>
                                        <p>{registerdAddress.address2}</p>
                                        <p>{registerdAddress.address3}</p>
                                        <p>{registerdAddress.city}</p>
                                        <p>{registerdAddress.pincode}</p>
                                        <p>{registerdAddress.state}</p>
                                        <p>{registerdAddress.country}</p>
                                    </div>
                                ) : (
                                    <div className="address-field">
                                        <EditOutlinedIcon className="verified-icon" onClick={() => setaddform(true)} />
                                        {editWarehouseData ? (
                                            <p>
                                                {editWarehouseData?.address1 +
                                                    "," +
                                                    editWarehouseData?.address2 +
                                                    "," +
                                                    editWarehouseData?.city +
                                                    "," +
                                                    editWarehouseData?.pincode +
                                                    "," +
                                                    editWarehouseData?.state}
                                            </p>
                                        ) : (
                                            <> </>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div className="working-days-content">
                                <p className="text-label">Working Hours & Days*</p>
                                {workingdays ? (
                                    <div className="input-with-verified-icon">
                                        {workingdays ? (
                                            <div className="days-wrapper">
                                                {(Object.keys(workingdays)).map(day => <p>{day}&nbsp;</p>)}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <EditOutlinedIcon className="verified-icon"
                                            onClick={() => setWorkingdaysDialog(true)}
                                        />
                                    </div>
                                ) : (
                                    <div className="input-with-verified-icon">
                                        <EditOutlinedIcon className="verified-icon"
                                            onClick={() => setWorkingdaysDialog(true)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="warehouse-contacts-wrapper">
                        <div>
                            <LayoutHeader title="Contacts" />
                            <div className="legal-details-content">
                                <p className="contacts-label">Primary Contact :</p>
                                <InputLabelinputWarehouse
                                    label="Name *"
                                    name="primary_contact_name"
                                    register={register("primary_contact_name", AddWarehouseValidation.primary_contact_name)}
                                    error={errors.primary_contact_name?.type !== undefined}
                                    errorText={errors.primary_contact_name ? errors.primary_contact_name.message : ""}
                                    defaultValue={editWarehouseData?.primary_contact_name ? editWarehouseData?.primary_contact_name : ""}
                                />
                                <InputLabelinputWarehouse
                                    label="Email *"
                                    type="email"
                                    name="primary_contact_email"
                                    register={register("primary_contact_email", AddWarehouseValidation.primary_contact_email)}
                                    error={errors.primary_contact_email?.type !== undefined}
                                    errorText={errors.primary_contact_email ? errors.primary_contact_email.message : ""}
                                    defaultValue={editWarehouseData?.primary_contact_email ? editWarehouseData?.primary_contact_email : ""}
                                />
                                <InputLabelinputWarehouse
                                    label="Mobile * ( ex: 98******** )"
                                    name="primary_contact_mobile"
                                    maxLength={10}
                                    onChange={(event) =>
                                        (event.target.value = event.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1"))
                                    }
                                    register={register("primary_contact_mobile", AddWarehouseValidation.primary_contact_mobile)}
                                    error={errors.primary_contact_mobile?.type !== undefined}
                                    errorText={errors.primary_contact_mobile ? errors.primary_contact_mobile.message : ""}
                                    defaultValue={editWarehouseData?.primary_contact_mobile ? editWarehouseData?.primary_contact_mobile : ""}

                                />
                                <p className="contacts-label">Escalations :</p>

                                <InputLabelinputWarehouse
                                    label="Name *"
                                    name="escalations_contact_name"
                                    register={register("escalations_contact_name", AddWarehouseValidation.escalations_contact_name)}
                                    error={errors.escalations_contact_name?.type !== undefined}
                                    errorText={errors.escalations_contact_name ? errors.escalations_contact_name.message : ""}
                                    defaultValue={editWarehouseData?.escalations_contact_name ? editWarehouseData?.escalations_contact_name : ""}
                                />
                                <InputLabelinputWarehouse
                                    label="Email *"
                                    type="email"
                                    name="escalations_contact_email"
                                    register={register("escalations_contact_email", AddWarehouseValidation.escalations_contact_email)}
                                    error={errors.escalations_contact_email?.type !== undefined}
                                    errorText={errors.escalations_contact_email ? errors.escalations_contact_email.message : ""}
                                    defaultValue={editWarehouseData?.escalations_contact_email ? editWarehouseData?.escalations_contact_email : ""}
                                />
                                <InputLabelinputWarehouse
                                    label="Mobile * ( ex: 98******** )"
                                    name="escalations_contact_mobile"
                                    onChange={(event) =>
                                        (event.target.value = event.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1"))
                                    }
                                    register={register("escalations_contact_mobile", AddWarehouseValidation.escalations_contact_mobile)}
                                    error={errors.escalations_contact_mobile?.type !== undefined}
                                    errorText={errors.escalations_contact_mobile ? errors.escalations_contact_mobile.message : ""}
                                    defaultValue={editWarehouseData?.escalations_contact_mobile ? editWarehouseData?.escalations_contact_mobile : ""}
                                    maxLength={10}
                                />
                            </div>
                        </div>
                        <LayoutHeader title="Tax & Financials" />
                        <div className="legal-details-content">
                            <InputLabelinputWarehouse
                                label="GST Number *"
                                name="gst_number"
                                maxLength={15}
                                register={register("gst_number", AddWarehouseValidation.gst_number)}
                                error={errors.gst_number?.type !== undefined}
                                errorText={errors.gst_number ? errors.gst_number.message : ""}
                                defaultValue={editWarehouseData?.gst_number ? editWarehouseData?.gst_number : ""}
                                onChange={() => verifyGst()}
                            />
                            <InputLabelinputWarehouse
                                label="TAN Number"
                                name="tan_number"
                                maxLength={10}
                                register={register("tan_number")}
                                error={errors.tan_number?.type !== undefined}
                                errorText={errors.tan_number ? errors.tan_number.message : ""}
                                defaultValue={editWarehouseData?.tan_number ? editWarehouseData?.tan_number : ""}
                            />
                            <InputLabelinputWarehouse
                                label="PAN Number"
                                name="pan_number"
                                maxLength={10}
                                register={register("pan_number")}
                                error={errors.pan_number?.type !== undefined}
                                errorText={errors.pan_number ? errors.pan_number.message : ""}
                                defaultValue={editWarehouseData?.pan_number ? editWarehouseData?.pan_number : ""}
                            />
                            <InputLabelinputWarehouse
                                label="CST Number"
                                name="cst_number"
                                maxLength={11}
                                register={register("cst_number")}
                                error={errors.cst_number?.type !== undefined}
                                errorText={errors.cst_number ? errors.cst_number.message : ""}
                                defaultValue={editWarehouseData?.cst_number ? editWarehouseData?.cst_number : ""}
                            />
                            <InputLabelinputWarehouse
                                label="VAT Number"
                                name="vat_number"
                                maxLength={11}
                                register={register("vat_number")}
                                error={errors.vat_number?.type !== undefined}
                                errorText={errors.vat_number ? errors.vat_number.message : ""}
                                defaultValue={editWarehouseData?.vat_number ? editWarehouseData?.vat_number : ""}
                            />
                        </div>
                    </div>
                    <div className="warehouse-contacts-wrapper">
                        <div style={{ marginBottom: "25px" }} >
                            <div>
                                <LayoutHeader title="Geo Location" />
                                <div className="legal-details-content">
                                    <div style={{ paddingTop: "10px" }}>
                                        <GooglePlacesAutocomplete
                                            apiKey="AIzaSyAAbzqszQe_UbUoQQ-50DF1o6g5PnL6pDY"
                                            apiOptions={{ region: "in" }}
                                            autocompletionRequest={{
                                                bounds: [
                                                    { lat: 50, lng: 50 },
                                                    { lat: 100, lng: 100 }
                                                ],
                                                componentRestrictions: {
                                                    country: ["in"]
                                                }
                                            }}
                                            selectProps={{
                                                placeholder: "Search Pincode or Places",
                                                locality,
                                                onChange: setAddressLocation
                                            }}
                                        />
                                    </div>

                                    <InputLabelwithContent
                                        label="Plus Code"
                                        content={plusCode ? plusCode : ""}
                                    />
                                    <InputLabelwithContent
                                        label="Location Link"
                                        content={latlong ? locationLink + latlong : ""}
                                    />
                                </div>
                            </div>
                            <div>
                                <MapsContainer
                                    lat={lat}
                                    lng={lng}
                                    onLocationSelected={(data: IGeoLocation) => locationSelectionHandler(data)}
                                />
                            </div>
                        </div>
                        <div className="warehouse-channels-wrapper">
                            <div className="warehouse-channels-header-wrapper">
                                <div style={{ width: "100%" }}>
                                    <LayoutHeader title="Channels" />
                                </div>
                                {channel?.length <= allowedChannels?.length - 1 && <AddIcon className="add-channel-ic" onClick={handleAddChannel} />}
                            </div>
                            {channel?.map((value: number, index: number) => {
                                return (
                                    <div className="channels-details-content">
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <InputLabelDownWithDropDown
                                                notrequired
                                                key={index}
                                                text="Channel Name"
                                                dropdown={watch(`channel_name_${value}`) ?
                                                    allowedChannels?.length > 0 ?
                                                        [watch(`channel_name_${value}`), ...allowedChannels.filter((element: string) =>
                                                            !channel.map((e, i) => {
                                                                if (watch(`channel_name_${i}`) === element) {
                                                                    return element;
                                                                }
                                                            }).includes(element))]
                                                        : []
                                                    : allowedChannels?.filter((element: string) =>
                                                        !channel.map((e, i) => {
                                                            if (watch(`channel_name_${i}`) === element) {
                                                                return element;
                                                            }
                                                        }).includes(element))}
                                                name={`channel_name_${value}`}
                                                register={register(`channel_name_${value}`)}
                                                // error={handleData()}
                                                defaultValue=""
                                            />
                                            <div className="channels-details-btn">
                                                {watch(`channel_name_${value}`) && <div style={{ display: "flex", justifyContent: "space-around" }}><ActionButton label="+" type="button" onClick={() => handleAddKeyValuePair(watch(`channel_name_${value}`))} /></div>}
                                                <IconButton>
                                                    <CancelIcon onClick={() => handleRemoveChannel(value)} />
                                                </IconButton>
                                            </div>
                                        </div>
                                        {channelDatas[watch(`channel_name_${value}`)]?.map((x: number) => {
                                            return (
                                                <div className="channels-details-content2">
                                                    <DynamicInputLabelWithDownInput
                                                        name1={`${watch(`channel_name_${value}`)}-${x + 1}`}
                                                        placeholder1={`${watch(`channel_name_${value}`)}-${x + 1}`}
                                                        register1={register(`${watch(`channel_name_${value}`)}-${x}`)}
                                                        allowedDatas={allowedDatas[watch(`channel_name_${value}`)] ? JSON.parse(allowedDatas[watch(`channel_name_${value}`)]).filter((xx: any) => !JSON.parse(allowedDatas[watch(`channel_name_${value}`)])?.map((y: any, yy: number) => watch(`${watch(`channel_name_${value}`)}-${yy}`)).includes(xx)).map((element: any) => { return element; }) : []}
                                                        placeholder2={`${watch(`channel_name_${value}`)}-value-${x + 1}`}
                                                        name2={`${watch(`channel_name_${value}`)}-value-${x + 1}`}
                                                        register2={register(`${watch(`channel_name_${value}`)}-value-${x}`)}
                                                    />
                                                    <IconButton>
                                                        <CancelIcon onClick={() => handleRemoveChanneldata(watch(`channel_name_${value}`), x, value)} />
                                                    </IconButton>
                                                </div>);
                                        })}

                                    </div>
                                );

                            })}

                        </div>
                        <div className="addwarehouse-submit-btn">
                            <ActionButton label="Submit" type="submit" />
                        </div>
                    </div>
                </div>
            </form>

            {addform && <AddressForm
                open={addform}
                title="Add Warehouse"
                handleClose={() => setaddform(false)}
                cancelClose={() => setaddform(false)}
                editData={registerdAddress}
                onSelected={(data: IWarehouseAddress) => setRegesiteredAddress(data)}
            />}

            {workingdaysDialog && <WorkingDaysForm
                open={workingdaysDialog}
                title="Add Warehouse"
                editData={workingdays}
                cancelClose={() => setWorkingdaysDialog(false)}
                onTimeSelected={(e) => setWorkingdays(e)}
            />}


            {courierdaysDialog && <CourierDaysForm
                open={courierdaysDialog}
                title="Add Warehouse"
                editData={courierdays}
                cancelClose={() => setcourierdaysDialog(false)}
                onTimeSelected={(e) => setcourierdays(e)}
            />}
            <Dialog
                fullWidth
                maxWidth="md"
                open={detailsDialogVisibility} className="details-dialog">

                <div className="close-button-container" onClick={() => setDetailsDialogVisibility(false)}>
                    <CloseRoundedIcon className="close-button" />
                </div>


                <HistoryPage inv_loc_code={editWarehouseData?.code} module="LOCATION" />
            </Dialog>
        </div>
    );
};